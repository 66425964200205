
import React, { useState, useEffect, useRef }  from "react"
import Papa from "papaparse"
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import axios from "axios"
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';


const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });


export default function ShortScript() {
    const [view, setView] = useState(0)
    const [query, setQuery] = useState("")

        const fileInputRef = useRef(null);


    const handleFileUpload = (e) => {
        let currentTime = new Date().toLocaleString()
        console.log(currentTime)
        const files = e.target.files;
        console.log(files);
        if (files) {
          console.log(files[0]);
          Papa.parse(files[0], {
            header: true,
            complete: function(results) {
              console.log("Finished:", results.data);
              let formArray = results.data.map(elem => {

      
           
      
                return (
                  {
                    oID: elem.oID,
                    exp: elem.exp
                
                  
                
                  }
                )
                
          
              })
              console.log(formArray)

              handleFileFilter(formArray)

         

              if (fileInputRef.current) {
                fileInputRef.current.value = '';
              }
    
           
            }}
          )
        }
      
      }



    const handleFileFilter = (data) => {
        const filteredData1 = data.filter((elem) => elem.exp !== "")

        const seen = new Set();
        const filteredData2 = filteredData1.filter((item) => {
          const val = item.oID;
          if (seen.has(val)) {
            return false;
          }
          seen.add(val);
          return true;
        });

        console.log(filteredData2)

        handleShopCalls(filteredData2)
    }


 const handleShopCalls = async (data) => {
    const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  
    const refundArray = [];
  
    for (const ellm of data) {
      try {
        const refund = await axios.post("/api/shopify/getdiscounts", {
          id: ellm.oID,
        });

        console.log(refund.data)
  
  
        let totalRefund = 0;
  
        if (refund.data.refunds.length > 0) {
          refund.data.refunds[0].transactions.forEach((elem) => {
            totalRefund += Number(elem.amount)
          });
        }
  
    
  
        refundArray.push({
          oID: ellm.oID,
          refund: totalRefund,
        });
      } catch (error) {
        console.error(`Error processing refund for oID ${ellm.oID}:`, error);
        refundArray.push({
          oID: ellm.oID,
          refund: 0,
    
        });
      }
  
      // ⏱ Wait 400ms before the next request
      await delay(150);
    }
  
    console.log(refundArray)

    const formattedArray = refundArray.map((elm) => {

        return (
            {
                oID: elm.oID,
                refunded: (elm.refund === 0) ? "FALSE" : "TRUE",
                refund: elm.refund
            }
        )
    })


    generateExcelFile(formattedArray)
  };



      const generateExcelFile = async (d1) => {
          const workbook = new ExcelJS.Workbook();
          const worksheet = workbook.addWorksheet('Sheet 1');
      
          const data = d1
      
        
          // Add column headers
          const headers = Object.keys(data[0]);
          worksheet.addRow(headers);
      
          // Add data rows
          data.forEach(item => {
            const values = Object.values(item);
            worksheet.addRow(values);
          });
  
  
      
      
          workbook.xlsx.writeBuffer().then((buffer) => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            saveAs(blob, 'ShortShips.xlsx');
          });
        };
  
  

    //data.refunds ?? array

    // [0].refund_line_items array [0] subtotal + total_tax



 
const test = async () => {

    const response = await axios.post("/api/shopify/getdiscounts",
        {
            id: 6767226421618
        }
    )

    console.log(response.data)
}
 






    return (
        <div style={{ marginLeft: "6vw" }}>

        <Button style={{height:"10vh", width:"10vw"}} component="label" variant="contained" >
        Upload
        <VisuallyHiddenInput ref={fileInputRef} onChange={handleFileUpload} type="file" accept=".csv" />
        </Button>

        <button onClick={test}>TEST</button>
  
        </div>

    )
}