import React, { useState, useEffect, useRef } from "react";

//MUI
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import Paper from "@mui/material/Paper";
import { IconButton } from "@mui/material";
import { ArrowBackIos, ArrowForwardIos } from "@mui/icons-material";

import axios from "axios";

//Charts
import {
  AnimatedAxis, // any of these can be non-animated equivalents
  AnimatedGrid,
  AnimatedLineSeries,
  XYChart,
  Tooltip,
} from "@visx/xychart";

import { Group } from "@visx/group";
import { BarGroup } from "@visx/shape";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";

export default function Dashboard() {
  const [data1, setData1] = useState([]);
  const [ordersCount, setOrdersCount] = useState("");
  const [sales, setSales] = useState("");
  const [aov, setAov] = useState("");
  const [topItem, setTopItem] = useState("");
  const [shopStatus, setShopStatus] = useState(false);
  const [mongoStatus, setMongoStatus] = useState(false);
  const [discounts, setDiscounts] = useState([]);

  const [activeVariants, setActiveVariants] = useState([]);

  const scrollRef = useRef(null);

  // Function to scroll left
  const scrollLeft = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: -750, behavior: "smooth" });
    }
  };

  // Function to scroll right
  const scrollRight = () => {
    if (scrollRef.current) {
      scrollRef.current.scrollBy({ left: 750, behavior: "smooth" });
    }
  };

  const width = 5500; // Chart width (matches "93vw")
  const height = 225; // Chart height (matches "20vh")
  const margin = { top: 20, right: 30, bottom: 40, left: 30 };

  const titles = [...new Set(activeVariants.map((d) => d.title))];
  const sizes = [...new Set(activeVariants.map((d) => d.size))];

  // Group data by title
  const groupedData = titles.map((title) => ({
    title,
    sizes: activeVariants.filter((d) => d.title === title),
  }));

  // Scales
  const x0Scale = scaleBand({
    domain: titles,
    padding: 0.2,
    range: [margin.left, width - margin.right],
  });

  const x1Scale = scaleBand({
    domain: sizes,
    padding: 0.05,
    range: [0, x0Scale.bandwidth()],
  });

  const yScale = scaleLinear({
    domain: [0, Math.max(...activeVariants.map((d) => d.count))],
    nice: true,
    range: [height - margin.bottom, margin.top],
  });

  const colorScale = scaleOrdinal({
    domain: sizes,
    range: ["#856ac4", "#4A90E2"], // Customize colors
  });

  const accessors = {
    xAccessor: (d) => d.x,
    yAccessor: (d) => d.y,
  };

  const getData = async () => {
    let response = await axios.post("/api/shopql/getnetsales");

    console.log(response.data.data.shopifyqlQuery.tableData.rowData);

    const formResponse = response.data.data.shopifyqlQuery.tableData.rowData;

    let data = formResponse.slice(0, formResponse.length - 1);

    let formattedData = data.map((elem) => {
      const cleanedString = elem[1].replace(/[$,]/g, "");

      let number = parseFloat(cleanedString);

      return {
        x: elem[0],
        y: number,
      };
    });

    console.log(formattedData);

    setData1(formattedData);
  };

  const getOrderData = async () => {
    let response = await axios.post("/api/shopify/getordersiso");

    setShopStatus(true);

    console.log(response.data);

    let lineItems = response.data
      .map((elem) => {
        return elem.line_items;
      })
      .flat();

    const discountCodes = response.data
      .map((elem) => {
        let codes = [];
        if (elem.discount_codes.length > 0) {
          elem.discount_codes.forEach((ellm) => codes.push(ellm.code));
        }
        return codes;
      })
      .filter((elm) => elm.length > 0)
      .flat();

    const discountCodeCountsArray = Object.entries(
      discountCodes.reduce((acc, code) => {
        acc[code] = (acc[code] || 0) + 1;
        return acc;
      }, {})
    ).map(([code, quantity]) => ({ code, quantity }));

    console.log(
      discountCodeCountsArray.sort((a, b) => b.quantity - a.quantity)
    );

    setDiscounts(
      discountCodeCountsArray.sort((a, b) => b.quantity - a.quantity)
    );

    console.log(discountCodes);

    let reducedLineItems = lineItems.reduce((prev, next) => {
      if (prev[next.title]) {
        prev[next.title]++;
      } else {
        prev[next.title] = 1;
      }
      return prev;
    }, {});

    console.log(reducedLineItems);

    const getKey = (obj) => {
      let highestKey = null;
      let highestValue = -Infinity;

      for (let key in obj) {
        if (obj[key] > highestValue) {
          highestValue = obj[key];
          highestKey = key;
        }
      }

      return highestKey;
    };

    setTopItem(getKey(reducedLineItems));

    let cTP = response.data.reduce((prev, next) => {
      return Number(prev) + Number(next.current_total_price);
    }, 0);

    console.log(cTP);

    const formattedCTP = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(cTP);

    console.log(formattedCTP);

    let unAOV = cTP / response.data.length;

    const formattedAOV = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(unAOV);

    console.log(formattedAOV);

    setOrdersCount(response.data.length.toString());
    setSales(formattedCTP);
    setAov(formattedAOV);
  };

  const checkMongo = async () => {
    let response = await axios.post("/api/report/getreport");

    setMongoStatus(true);
  };

  useEffect(() => {
    getOrderData();
    getData();
    checkMongo();
    getAvailVariants();
  }, []);

  const getAvailVariants = async () => {
    const response = await axios.post("/api/shopify/getvariantquantities");

    console.log(response.data);

    const exData = response.data.filter((elem) => elem !== null);

    const formData1 = exData
      .map((elem) => {
        const title = elem.productTitle.replace(/Pacas Socks/g, "");

        const formData2 = elem.variants.map((ellm) => {
          return {
            ...ellm,
            title: title,
          };
        });

        return formData2;
      })
      .flat()
      .filter((quant) => quant.inventoryQuantity > 0);

    console.log(formData1);

    const formData3 = formData1.map((elem) => {
      let checkVal;

      if (elem.sku[1] === "-") {
        checkVal = Number(elem.sku.substring(2, 6));
      } else {
        checkVal = Number(elem.sku.substring(0, 4));
      }

      return {
        ...elem,
        size: checkVal % 2 === 0 ? "Size One" : "Size Two",
      };
    });

    console.log(formData3);

    const allTitles = [...new Set(formData3.map((item) => item.title))];
    const allSizes = [...new Set(formData3.map((item) => item.size))];

    // Reduce while ensuring every title has all sizes
    const reducedData = Object.values(
      formData3.reduce((acc, item) => {
        const key = `${item.title}-${item.size}`;
        if (!acc[key]) {
          acc[key] = { title: item.title, size: item.size, count: 0 };
        }
        acc[key].count += 1;
        return acc;
      }, {})
    );

    // Ensure all titles have all sizes (even if not in original data)
    allTitles.forEach((title) => {
      allSizes.forEach((size) => {
        const key = `${title}-${size}`;
        if (
          !reducedData.some(
            (entry) => entry.title === title && entry.size === size
          )
        ) {
          reducedData.push({ title, size, count: 0 });
        }
      });
    });

    console.log(reducedData);

    setActiveVariants(reducedData);
  };

  return (
    <>
      <div style={{ display: "flex" }}>
        <Card
          style={{
            backgroundColor: "var(--content-color)",
            width: "17.5vw",
            height: "12vh",
            borderRadius: "15px",
            marginRight: "1vw",
            marginBottom: "1vh",
            marginTop: "2vh",
            boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
              Today's Orders
            </Typography>
            <Typography variant="h5" color="var(--text-color)" component="div">
              {ordersCount !== "" ? (
                ordersCount
              ) : (
                <CircularProgress color="inherit" />
              )}
            </Typography>
          </CardContent>
        </Card>

        <Card
          style={{
            backgroundColor: "var(--content-color)",
            width: "17vw",
            height: "12vh",
            borderRadius: "15px",
            marginRight: "1vw",
            marginBottom: "1vh",
            marginTop: "2vh",
            boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
              Today's Sales
            </Typography>
            <Typography variant="h5" color="var(--text-color)" component="div">
              {sales !== "" ? sales : <CircularProgress color="inherit" />}
            </Typography>
          </CardContent>
        </Card>

        <Card
          style={{
            backgroundColor: "var(--content-color)",
            width: "16vw",
            height: "12vh",
            borderRadius: "15px",
            marginRight: "1vw",
            marginBottom: "1vh",
            marginTop: "2vh",
            boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
              Today's AOV
            </Typography>
            <Typography variant="h5" color="var(--text-color)" component="div">
              {aov !== "" ? aov : <CircularProgress color="inherit" />}
            </Typography>
          </CardContent>
        </Card>

        <Card
          style={{
            backgroundColor: "var(--content-color)",
            width: "20vw",
            height: "12vh",
            borderRadius: "15px",
            marginRight: "1vw",
            marginBottom: "1vh",
            marginTop: "2vh",
            boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
              Today's Top Product
            </Typography>
            <Typography variant="9" color="var(--text-color)" component="div">
              {topItem !== "" ? topItem : <CircularProgress color="inherit" />}
            </Typography>
          </CardContent>
        </Card>

        <Card
          style={{
            backgroundColor: "var(--content-color)",
            width: "20vw",
            height: "12vh",
            borderRadius: "15px",
            marginRight: "1vw",
            marginBottom: "1vh",
            marginTop: "2vh",
            boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
              Today's Top Discount Code
            </Typography>
            <Typography variant="9" color="var(--text-color)" component="div">
              {discounts.length > 0 && discounts[0].code}
            </Typography>
          </CardContent>
        </Card>
      </div>
      <div style={{ display: "flex" }}>
        <Card
          style={{
            backgroundColor: "var(--content-color)",
            width: "19vw",
            height: "35vh",
            borderRadius: "15px",
            marginRight: "1vw",
            marginBottom: "1vh",
            marginTop: "1vh",
            boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
              Status
            </Typography>
            <Typography
              variant="h5"
              color="var(--text-color)"
              component="div"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Shopify
              {shopStatus ? (
                <CheckCircleOutlineIcon color="success" />
              ) : (
                <CircularProgress color="inherit" size={24} />
              )}
            </Typography>
            <Typography
              variant="h5"
              color="var(--text-color)"
              component="div"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              Firebase
              <CheckCircleOutlineIcon color="success" />
            </Typography>
            <Typography
              variant="h5"
              color="var(--text-color)"
              component="div"
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              MongoDB
              {mongoStatus ? (
                <CheckCircleOutlineIcon color="success" />
              ) : (
                <CircularProgress color="inherit" size={24} />
              )}
            </Typography>
          </CardContent>
        </Card>

        <Card
          style={{
            backgroundColor: "var(--content-color)",
            width: "82vw",
            height: "35vh",
            borderRadius: "15px",
            marginRight: "1vw",
            marginBottom: "1vh",
            marginTop: "1vh",
            boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
         <CardContent>
  <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
    Monthly Sales
  </Typography>
  <Typography variant="h5" color="var(--text-color)" component="div">
    {data1.length > 0 ? (
      <XYChart height={250} xScale={{ type: "band" }} yScale={{ type: "linear" }}>
        {/* X-axis (Bottom) */}
        <AnimatedAxis
          orientation="bottom"
          tickValues={data1.filter((d, i) => i % 7 === 0).map(accessors.xAccessor)}
          tickLabelProps={() => ({
            fill: "var(--text-color)",
            fontSize: 12,
            textAnchor: "middle",
          })}
        />
        
        {/* Y-axis (Left) */}
        <AnimatedAxis
          orientation="left"
          numTicks={4}
        // Y-Axis Key
          labelOffset={20} // Adjusts label positioning
          labelProps={{
            fill: "var(--text-color)",
            fontSize: 12,
            textAnchor: "middle",
          }}
          tickLabelProps={() => ({
            fill: "var(--text-color)",
            fontSize: 12,
            textAnchor: "end",
          })}
        />

        <AnimatedGrid columns={false} numTicks={4} />
        <AnimatedLineSeries dataKey="Sales" data={data1} {...accessors} />

        <Tooltip
          snapTooltipToDatumX
          snapTooltipToDatumY
          showVerticalCrosshair
          showSeriesGlyphs
          renderTooltip={({ tooltipData, colorScale }) => (
            <div>
              <div style={{ color: colorScale(tooltipData.nearestDatum.key) }}>
                {tooltipData.nearestDatum.key}
              </div>
              {accessors.xAccessor(tooltipData.nearestDatum.datum)},{" "}
              {accessors.yAccessor(tooltipData.nearestDatum.datum)}
            </div>
          )}
        />
      </XYChart>
    ) : (
      <CircularProgress color="inherit" />
    )}
  </Typography>
</CardContent>

        </Card>

        {/* <Card style={{ backgroundColor: 'var(--content-color)', width: "17vw", height:"45vh", borderRadius: "15px", marginRight: "2vw", marginBottom: "5vh", marginTop: "5vh", boxShadow: "0px 0px 10px -3px" }}>
                <CardContent>
                    <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
                   
                    </Typography>
                    <Typography variant="h5" color="#ededf2" component="div">
               
                    </Typography>
                </CardContent>
                </Card> */}
      </div>

      <div
        style={{ display: "flex", alignItems: "center", position: "relative" }}
      >
        {/* Left Arrow */}
        <IconButton
          onClick={scrollLeft}
          style={{
            position: "absolute",
            left: -5,
            zIndex: 2,
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: "50%",
            color: "var(--text-color)",
          }}
        >
          <ArrowBackIos fontSize="small" />
        </IconButton>

        <Card
          style={{
            backgroundColor: "var(--content-color)",
            width: "93vw",
            height: "35vh",
            borderRadius: "15px",
            marginRight: "1vw",
            marginBottom: "1vh",
            marginTop: "1vh",
            boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
          <CardContent>
            <Typography sx={{ fontSize: 14 }} color="#a0a0a3" gutterBottom>
              Active Variant Count
            </Typography>
            <Typography variant="h5" color="var(--text-color)" component="div">
              {activeVariants.length > 0 ? (
                <div
                  ref={scrollRef}
                  style={{
                    width: "93vw",
                    overflowX: "auto", // Enable horizontal scrolling
                    overflowY: "hidden",
                    whiteSpace: "nowrap",
                    scrollBehavior: "smooth",
                  }}
                >
                  <svg width={width} height={height}>
                    <Group>
                      {yScale.ticks(5).map((tickValue, i) => (
                        <Group key={`y-tick-${i}`}>
                          <line
                            x1={margin.left}
                            x2={width - margin.right}
                            y1={yScale(tickValue)}
                            y2={yScale(tickValue)}
                            stroke="#e0e0e0"
                            strokeDasharray="2,2"
                          />
                          <text
                            x={margin.left - 10}
                            y={yScale(tickValue) + 4}
                            fill="var(--text-color)"
                            fontSize={10}
                            textAnchor="end"
                          >
                            {tickValue}
                          </text>
                        </Group>
                      ))}

                      {/* Bar Groups */}
                      {groupedData.map((group, i) => {
                        const groupXPosition = x0Scale(group.title);

                        return (
                          <Group key={`title-${i}`} left={groupXPosition}>
                            {group.sizes.map((sizeData) => {
                              const barXPosition = x1Scale(sizeData.size);

                              return (
                                <React.Fragment key={`bar-${sizeData.size}`}>
                                  {sizeData.count === 0 && (
                                    <line
                                      x1={barXPosition}
                                      x2={barXPosition + x1Scale.bandwidth()}
                                      y1={yScale(0) - 2}
                                      y2={yScale(0) - 2}
                                      stroke="red"
                                      strokeWidth={2}
                                    />
                                  )}

                                  <rect
                                    x={barXPosition}
                                    y={yScale(sizeData.count)}
                                    width={x1Scale.bandwidth()}
                                    height={yScale(0) - yScale(sizeData.count)}
                                    fill={colorScale(sizeData.size)}
                                  />
                                </React.Fragment>
                              );
                            })}
                          </Group>
                        );
                      })}
                    </Group>

                    {/* X-Axis Labels */}
                    {titles.map((title, i) => (
                      <text
                        key={`label-${i}`}
                        x={x0Scale(title) + x0Scale.bandwidth() / 2 - 10}
                        y={height - margin.bottom + 30}
                        textAnchor="middle"
                        fill="var(--text-color)"
                        fontSize={10}
                      >
                        {title}
                      </text>
                    ))}
                  </svg>
                </div>
              ) : (
                <CircularProgress color="inherit" />
              )}
            </Typography>
          </CardContent>
        </Card>

        {/* Right Arrow */}
        <IconButton
          onClick={scrollRight}
          style={{
            position: "absolute",
            right: 5,
            zIndex: 2,
            backgroundColor: "rgba(0,0,0,0.1)",
            borderRadius: "50%",
            color: "var(--text-color)",
          }}
        >
          <ArrowForwardIos fontSize="small" />
        </IconButton>
      </div>
    </>
  );
}
