import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Slider from "@mui/material/Slider";
import {
  Switch,
  FormControlLabel,
  Radio,
  RadioGroup,
  FormLabel,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
  Autocomplete,
} from "@mui/material";
import axios from "axios";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Grid from "@mui/material/Grid";

//Components
import PhotoCreate from "../components/PhotoCreate";
import ButtonCreate from "../components/ButtonCreate";
import Circle from "../components/Circle";
import { HexColorPicker } from "react-colorful";
import ConmplexButton from "./ComplexButton";
import ComplexCircle from "../components/ComplexCircle";
import Preview from "../components/Preview";
import PreviewMobile from "../components/PreviewMobile";
import KeyboardReturnIcon from "@mui/icons-material/KeyboardReturn";

export default function CreationDisplay(props) {
  const [photoData, setPhotoData] = useState([]);
  const [valueXOff, setValueXOff] = useState(190);
  const [valueY, setValueY] = useState(230);
  const [valueX, setValueX] = useState(0);
  const [valueSize, setValueSize] = useState(800);
  const [brightness, setBrightness] = useState(1);
  const [contrast, setContrast] = useState(1);
  const [sharpness, setSharpness] = useState(0);
  const [selected, setSelected] = useState({});
  const [active, setActive] = useState({});
  const [color, setColor] = useState("#aabbcc");
  const [color2, setColor2] = useState("#aabbcc");
  const [previewData, setPreviewData] = useState("");
  const [previewMobile, setPreviewMobile] = useState("");

  const [checked1, setChecked1] = useState(true);
  const [checked2, setChecked2] = useState(true);
  const [checked3, setChecked3] = useState(false);

  const [vis, setVis] = useState("hidden");

  const [buttonData, setButtonData] = useState("");

  const [radio, setRadio] = useState("");

  const [batch, setBatch] = useState("");

  const [inputBatch, setInputBatch] = useState("");

  const [image, setImage] = useState("");
  const [location, setLocation] = useState("66804809828");



  const handleLocationChange = (e) => {
    setLocation(e.target.value)
  }

  const hide = (e) => {
    if (vis === "hidden") {
      setVis("visible");
    }

    setSelected({ s: e.target.id, t: e.target.className });
    setActive(e.target.id);

    console.log(selected);
  };

  const buttonPass = (a) => {
    setButtonData(a);
  };

  const handleImage = (image) => {
    setImage(image);
  };

  const changeColor = (e) => {
    let newData = photoData.map((elem) => {
      console.log(selected);
      if (elem.sku === selected.s && selected.t === "1") {
        return { ...elem, primary: e };
      } else if (elem.sku === selected.s && selected.t === "2") {
        return { ...elem, secondary: e };
      }
      return elem;
    });

    setPhotoData(newData);
  };

  const saveData = () => {
    let dataIn = photoData.map((elem) => {
      return {
        sku: elem.sku,
        primary: elem.primary,
        secondary: elem.secondary,
        primary2: elem.primary2,
        primary3: elem.primary3,
        vector: elem.vector,
        pattern: elem.pattern,
      };
    });

    dataIn.forEach((elem) => {
      axios.post("/api/button/create", elem).then((res) => {
        console.log(res);
      });
    });

    console.log(dataIn);
  };

  const handleRadioChange = (event) => {
    setRadio(event.target.value);
  };

  const handleBatchChange = (event) => {
    setBatch(event.target.value);
  };

  const handleXOffChange = (event, newValue) => {
    setValueXOff(newValue);
    // console.log("change")
    // console.log(newValue)
  };

  const handleYChange = (event, newValue) => {
    setValueY(newValue);
  };

  const handleXChange = (event, newValue) => {
    setValueX(newValue);
  };

  const handleSizeChange = (event, newValue) => {
    setValueSize(newValue);
  };

  const handleBrightnessChange = (event, newValue) => {
    setBrightness(newValue);
  };

  const handleContrastChange = (event, newValue) => {
    setContrast(newValue);
  };

  const handleSharpnessChange = (event, newValue) => {
    setSharpness(newValue);
  };

  const handleSwitchChange1 = (event) => {
    setChecked1(event.target.checked);
  };
  const handleSwitchChange2 = (event) => {
    setChecked2(event.target.checked);
  };
  const handleSwitchChange3 = (event) => {
    setChecked3(event.target.checked);
  };

  const setDefaultValues = (a, b, c, d) => {
    setValueX(c);
    setValueY(b);
    setValueSize(d);
    setValueXOff(a);
  };

  const positionSet = () => {
    console.log(props.details);
    let count = props.selectedData.length;
    let style = props.details.style;
    let cat = props.details.cat;
    if (count === 3 && style === "Golf Socks") {
      setDefaultValues(240, 200, 0, 760);
    } else if (count === 4 && style === "Golf Socks") {
      setDefaultValues(200, 270, 0, 630);
    } else if (count === 6 && style === "Golf Socks") {
      setDefaultValues(150, 300, -37, 540);
    } else if (count === 2 && style === "Crew Socks") {
      setDefaultValues(219, 101, 38, 981);
    } else if (count === 3 && style === "Ruffle Socks") {
      setDefaultValues(189, 100, -45, 976);
    } else if (count === 4 && style === "Ruffle Socks") {
      setDefaultValues(189, 100, -121, 952);
    } else if (count === 2 && style === "Light-Weight Crew Socks") {
      setDefaultValues(219, 101, 38, 981);
    } else if (count === 3 && style === "Crew Socks" && cat !== "Kids'") {
      setDefaultValues(201, 136, -42, 953);
    } else if (count === 3 && style === "Crew Socks" && cat === "Kids'") {
      setDefaultValues(201, 136, -69, 953);
    } else if (count === 4 && style === "Crew Socks") {
      setDefaultValues(205, 150, -150, 905);
    }

    //
    else if (count === 5 && style === "Crew Socks") {
      setDefaultValues(160, 230, -85, 725);
    } else if (count === 6 && style === "Crew Socks" && cat !== "Kids'") {
      setDefaultValues(160, 246, -142, 700);
    } else if (count === 6 && style === "Crew Socks" && cat === "Kids'") {
      setDefaultValues(150, 246, -124, 700);
    } else if (count === 2 && style === "Light-Weight Crew Socks") {
      setDefaultValues(-40, 310, 490, 155);
    } else if (count === 3 && style === "Light-Weight Crew Socks") {
      setDefaultValues(201, 136, -42, 953);
    } else if (count === 4 && style === "Light-Weight Crew Socks") {
      setDefaultValues(205, 150, -150, 905);
    }

    //
    else if (count === 5 && style === "Light-Weight Crew Socks") {
      setDefaultValues(160, 230, -85, 725);
    } else if (count === 6 && style === "Light-Weight Crew Socks") {
      setDefaultValues(160, 246, -142, 700);
    } else if (count === 3 && style === "Compression Socks") {
      setDefaultValues(170, 100, -55, 1000);
    } else if (count === 4 && style === "Compression Socks") {
      setDefaultValues(185, 100, -150, 1000);
    } else if (count === 6 && style === "Compression Socks") {
      setDefaultValues(135, 160, -150, 875);
    } else if (count === 2 && style === "Low-Cut Socks") {
      setDefaultValues(215, 130, 60, 900);
    } else if (count === 3 && style === "Low-Cut Socks") {
      setDefaultValues(222, 100, -105, 1000);
    } else if (count === 4 && style === "Low-Cut Socks") {
      setDefaultValues(210, 140, -130, 863);
    } else if (count === 6 && style === "Low-Cut Socks") {
      setDefaultValues(150, 242, -115, 689);
    } else if (count === 3 && style === "No-Show Socks") {
      setDefaultValues(235, 130, -62, 900);
    } else if (count === 4 && style === "No-Show Socks") {
      setDefaultValues(225, 156, -135, 850);
    } else if (count === 6 && style === "No-Show Socks") {
      setDefaultValues(156, 218, -150, 760);
    } else if (count === 2 && style === "Performance Socks") {
      setDefaultValues(215, 130, 60, 900);
    } else if (count === 3 && style === "Performance Socks") {
      setDefaultValues(230, 100, -112, 1000);
    } else if (count === 4 && style === "Performance Socks") {
      setDefaultValues(205, 114, -150, 917);
    } else if (count === 6 && style === "Performance Socks") {
      setDefaultValues(156, 206, -132, 713);
    }
    //
    else if (count === 2 && style === "Hiking Crew Socks") {
      setDefaultValues(200, 100, 0, 1000);
    } else if (count === 3 && style === "Hiking Crew Socks") {
      setDefaultValues(210, 100, -92, 1000);
    } else if (count === 4 && style === "Hiking Crew Socks") {
      setDefaultValues(200, 100, -150, 950);
    } else if (count === 2 && style === "Hiking Quarter Socks") {
      setDefaultValues(200, 100, 0, 1000);
    } else if (count === 3 && style === "Hiking Quarter Socks") {
      setDefaultValues(200, 100, -75, 975);
    } else if (count === 4 && style === "Hiking Quarter Socks") {
      setDefaultValues(190, 100, -135, 880);
    } else if (count === 3 && style === "Golf Socks") {
      setDefaultValues(190, 100, -135, 880);
    }
  };

  const getLog = () => {
    console.log(photoData);
  };

  useEffect(() => {
    // let addData = props.selectedData.map(elem => {
    //   return {...elem, primary: "#FFFFFF", secondary: "#FFFFFF"}
    // })
    setPhotoData(props.selectedData);
    // console.log(photoData)
    console.log("This: " + props.selectedData.length);
    positionSet();
  }, [props.selectedData]);

  function onDragStart(event, index) {
    event.dataTransfer.setData("index", index);
  }

  function onDragOver(event) {
    event.preventDefault();
  }

  function onDrop(event, newIndex) {
    const oldIndex = event.dataTransfer.getData("index");
    const newItems = [...photoData];
    const [removed] = newItems.splice(oldIndex, 1);
    newItems.splice(newIndex, 0, removed);
    setPhotoData(newItems);
  }

  let imageUrls = photoData.map((elem) => {
    return elem.image;
  });

  const handleAlert = () => {
    if (inputBatch.length === 0 && batch.length === 0) {
      alert("Please select a variant type and a batch ID");
      return false;
    } else {
      return true;
    }
  };

  const uploadVariantData = () => {
    let batchPost = "";
    if (inputBatch.length === 0) {
      batchPost = batch;
    } else if (inputBatch.length > 0) {
      batchPost = inputBatch;
    }

    if (inputBatch.length === 0 && batch.length === 0) {
      alert("Please select a variant type and a batch ID");
      return;
    }

    console.log(props);
    // let psku1 = "t"
    // let psku2 = "v"
    let sc1 = "";
    let sc2 = "";

    if (props.cat === "Women's") {
      sc1 = "SM";
      sc2 = "ML";
    } else if (props.cat === "Men's") {
      sc1 = "ML";
      sc2 = "XL";
    } else {
      sc1 = "OS";
      sc2 = "OS";
    }

    // if (props.size === "Size 1") {
    // psku1 = props.details.sku1
    // psku2 = props.details.sku2
    // } else {
    //   psku1 = props.details.sku2
    //   psku2 = props.details.sku1
    // }

    let selectedData2 = props.selectedData.map((elem) => {
      if (props.size === "Size 1") {
        //decode sku

        let newParentSku = elem.parentSku;

        if (elem.parentSku.length !== 4 && elem.parentSku[1] === "-") {
          let skuSplit = elem.parentSku.split("X");

          let skuObj = skuSplit
            .map((elem) => {
              let quantity = parseInt(elem).toString();
              let sku = (
                parseInt(elem.substring(elem.indexOf("-") + 1)) + 1
              ).toString();

              let parentSkuFinal = quantity + "-" + sku;

              return parentSkuFinal;
            })
            .join("X");

          newParentSku = skuObj;
        } else if (elem.parentSku.length !== 4 && elem.parentSku[1] !== "-") {
          let skuSplit = elem.parentSku.split("-");

          let skuObj = skuSplit
            .map((elem) => {
              let parentSkuFinal = parseInt(elem) + 1;

              return parentSkuFinal;
            })
            .join("-");

          newParentSku = skuObj;
        }

        //
        else if (elem.parentSku.length === 4) {
          newParentSku = (parseInt(elem.parentSku) + 1).toString();
        }

        return {
          ...elem,
          sku: (parseInt(elem.sku) + 1).toString(),
          parentSku: newParentSku,
        };
      } else {
        let newParentSku = elem.parentSku;

        if (elem.parentSku.length !== 4 && elem.parentSku[1] === "-") {
          let skuSplit = elem.parentSku.split("X");

          let skuObj = skuSplit
            .map((elem) => {
              let quantity = parseInt(elem).toString();
              let sku = (
                parseInt(elem.substring(elem.indexOf("-") + 1)) - 1
              ).toString();

              let parentSkuFinal = quantity + "-" + sku;

              return parentSkuFinal;
            })
            .join("X");

          newParentSku = skuObj;
        } else if (elem.parentSku.length !== 4 && elem.parentSku[1] !== "-") {
          let skuSplit = elem.parentSku.split("-");

          let skuObj = skuSplit
            .map((elem) => {
              let parentSkuFinal = parseInt(elem) - 1;

              return parentSkuFinal;
            })
            .join("-");

          newParentSku = skuObj;
        } else if (elem.parentSku.length === 4) {
          newParentSku = (parseInt(elem.parentSku) - 1).toString();
        }

        return {
          ...elem,
          sku: (parseInt(elem.sku) - 1).toString(),
          parentSku: newParentSku,
        };
      }
    });
    let cData1 = [];
    let cData2 = [];

    if (props.size === "Size 1") {
      cData1 = props.selectedData;
      cData2 = selectedData2;
    } else {
      cData1 = selectedData2;
      cData2 = props.selectedData;
    }

    console.log(cData1);
    console.log(cData2);

    console.log(selectedData2);
    axios
      .post("./api/variant/create", {
        parent: props.details.sku1,
        batch: batchPost,
        confirmed: false,
        date: "11/11/11",
        user: "Jacob",
        style: props.details.style,
        children: cData1,
        type: radio,
        updated_at: "",
        updated_by: "",
        target_id: props.details.targetId,
        color: props.details.color,
        sizeCode: sc1,
        price: props.details.price,
      })
      .then((res) => {
        console.log(res.data);

        axios
          .post("./api/variant/create", {
            parent: props.details.sku2,
            batch: batchPost,
            confirmed: false,
            date: "11/11/11",
            user: "Jacob",
            style: props.details.style,
            children: cData2,
            type: radio,
            updated_at: "",
            updated_by: "",
            target_id: props.details.targetId,
            color: props.details.color,
            sizeCode: sc2,
            price: props.details.price,
          })
          .then((res) => {
            console.log(res.data);
          });
      });
  };

  // let batchSelect = props.batchData.map((elem) => {
  //   return (
  //     <MenuItem value={elem}>{elem}</MenuItem>
  // )
  // })

  let batchSelect = props.batchData.map((elem) => {
    return <MenuItem value={elem}>{elem}</MenuItem>;
  });

  const dataSend = (a) => {
    setPreviewData(a);
  };

  const dataSendMobile = (a) => {
    setPreviewMobile(a);
  };

  const handlePreview = () => {
    const newWindow = window.open(); // Open a new tab
    newWindow.document.write(
      `<img src="${previewData}" alt="Preview Image" />`
    ); // Write the image HTML to the new tab
    newWindow.document.close(); // Close the document to finish loading
  };

  const handleMobile = () => {
    const newWindow = window.open(); // Open a new tab
    newWindow.document.write(
      `<img src="${previewMobile}" alt="Preview Image" />`
    ); // Write the image HTML to the new tab
    newWindow.document.close(); // Close the document to finish loading
  };

  const testUpload = async () => {
    let response = await axios.post("/api/shopql/uploadbutton", {
      imageData: buttonData,
      fileName: "test" + ".png",
    });

    console.log(response);
  };

  return (
    <Box sx={{ width: "70%", marginTop: "0vh" }}>
      <Card
        style={{
          margin: "0vw",
          padding: "5px",
          width: "93vw",
          height: "5vh",
          marginTop: "1vh",
          backgroundColor: "var(--content-color)",
          marginLeft: "0vw",
          borderRadius: "10px",
          boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
               
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "100vw",
            padding: "0 1vw",
            color: "white",
      
          }}
        >
          {/* Left Icon */}

          <KeyboardReturnIcon
            sx={{ fontSize: 30 }}
            style={{ cursor: "pointer", color: "white" }}
            onClick={() => props.resetDisplay()}
          />

          {/* Centered Content */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
              gap: "30vw", // Adjust spacing between sku1 and /
            }}
          >
            <h2
              style={{
                position: "absolute",
                left: "25%",
                transform: "translateX(-25%)",
              }}
            >
              {props.details.sku1}
            </h2>
            <h2
              style={{
                position: "absolute",
                left: "50%",
                transform: "translateX(-50%)",
              }}
            >
              /
            </h2>
            <h2
              style={{
                position: "absolute",
                left: "75%",
                transform: "translateX(-75%)",
              }}
            >
              {props.details.sku2}
            </h2>
          </div>
        </div>
      </Card>

      <div style={{display:"flex", flexDirection:"row"}}>

      <div style={{ display: "flex", flexDirection: "column" }}>

      <Card
            style={{
          
              // padding: "15px",
              width: "12.75vw",
              height: "90vh",
              marginTop: "1vw",
              marginRight:"1vw",
              backgroundColor: "var(--content-color)",
              marginLeft: "0vw",
              borderRadius: "15px",
              boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
            }}
          >

<FormControl>
      <FormLabel style={{color:"white"}} id="demo-controlled-radio-buttons-group">Choose Location</FormLabel>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="controlled-radio-buttons-group"
        value={location}
        onChange={handleLocationChange}
        style={{color:"white"}}
      >
        <FormControlLabel value="66804809828" control={<Radio />} label="Capacity" />
        <FormControlLabel value="16177561700" control={<Radio />} label="Westmont" />
      </RadioGroup>
    </FormControl>

            

          </Card>

        </div>

      <div style={{ display: "flex", flexDirection: "column" }}>
        <div >
          <Card
            style={{
          
              // padding: "15px",
              width: "15vw",
              height: "25vh",
              marginTop: "1vw",
              marginRight:"1vw",
              backgroundColor: "var(--content-color)",
              marginLeft: "0vw",
              borderRadius: "15px",
              boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                color: "white",
                padding:"15px"
              }}
            >
              <h5 style={{ textDecoration:"underline", color:"white", textAlign:"center",margin:"10px"}}>Creation Settings</h5>
              <FormControlLabel
                control={
                  <Switch
                    checked={checked1}
                    onChange={handleSwitchChange1}
                    name="controlled-switch"
                  />
                }
                label="Validate"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={checked2}
                    onChange={handleSwitchChange2}
                    name="controlled-switch"
                  />
                }
                label="Auto-Sort"
              />
              <FormControlLabel
                control={
                  <Switch
                    checked={checked3}
                    onChange={handleSwitchChange3}
                    name="controlled-switch"
                  />
                }
                label="Auto-Upload"
              />
            </div>
          </Card>

          <Grid container spacing={1}>
            <div style={{}}>
              <Card
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "15vw",
                  height: "20vh",
                  marginTop: "3vh",
                  backgroundColor: "var(--content-color)",
                  marginLeft: "0.5vw",
                  borderRadius: "15px",
                  boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
                  textAlign:"center"
                }}
              >
                <h5 style={{ textDecoration:"underline",color:"white", margin:"5px"}}>Site Preview</h5>
                <Card
                  onClick={handlePreview}
                  // value={elem.value}
                  style={{
                    cursor: "pointer",
                  }}
                  variant="outlined"
                  sx={{
                    maxWidth: "14vw",
                    minWidth: "14vw",
                    minHeight: "13vh",
                    maxHeight: "13vh",
                    margin: "6px",
                  }}
                >
                  <Preview
                    photo={image}
                    button={buttonData}
                    dataSend={dataSend}
                  />
                </Card>

                {/* <Card
                  onClick={handleMobile}
                  // value={elem.value}
                  style={{
                    cursor: "pointer",
                  }}
                  variant="outlined"
                  sx={{
                    maxWidth: "4vw",
                    minWidth: "4vw",
                    minHeight: "13vh",
                    maxHeight: "13vh",
                    margin: "6px",
                  }}
                >
                  <PreviewMobile
                    photo={image}
                    button={buttonData}
                    dataSend={dataSendMobile}
                  />
                </Card> */}
              </Card>
            </div>
          </Grid>

          <Card
            style={{
              width: "15vw",
              height: "41vh",
              marginTop: "2vh",
              backgroundColor: "var(--content-color)",
              marginLeft: "0vw",
              borderRadius: "15px",
              boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
            }}
          >
            <ComplexCircle
              photoData={photoData}
              details={props.details}
              buttonPass={buttonPass}
            />

            <ConmplexButton
              saveData={saveData}
              setPhotoData={setPhotoData}
              photoData={photoData}
              details={props.details}
              buttonPass={buttonPass}
              image={image}
            />
          </Card>
        </div>
      </div>

      <div style={{display:'flex', flexDirection:"column"}}>

      <Card
  style={{
    width: "30vw",
    height: "25vh",
    marginTop: "2vh",
    backgroundColor: "var(--content-color)",
    marginLeft: "0vw",
    borderRadius: "15px",
    boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  }}
>
  <FormControl
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%", // Ensures form items take up available space
    }}
  >
    <FormLabel
      style={{ color: "white", textAlign: "center" }}
      id="demo-controlled-radio-buttons-group"
    >
      <h5 style={{ textDecoration:"underline", margin:"0px"}}>Variant Type</h5>
    </FormLabel>
    <RadioGroup
      aria-labelledby="demo-controlled-radio-buttons-group"
      name="controlled-radio-buttons-group"
      value={radio}
      onChange={handleRadioChange}
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start", // Aligns radio buttons to the left
        textAlign: "left",
        width: "80%", // Ensures it's not too wide
      }}
    >
      <FormControlLabel
        style={{ color: "white" }}
        value="virtual"
        control={<Radio sx={{ color: "white" }} />}
        label="Virtual"
      />
      <FormControlLabel
        style={{ color: "white" }}
        value="physical"
        control={<Radio sx={{ color: "white" }} />}
        label="Physical"
      />
    </RadioGroup>

    <Box
      component="form"
      sx={{
        color: "white",
        "& > :not(style)": { m: 1, width: "25ch" },
      }}
      noValidate
      autoComplete="off"
    >
      <Autocomplete
        freeSolo
        options={props.batchData}
        value={batch}
        onChange={(event, newValue) => setBatch(newValue || "")}
        onInputChange={(event, newValue) => setBatch(newValue)}
        sx={{
          "& .MuiOutlinedInput-root": {
            color: "white",
            "& fieldset": { borderColor: "white" },
            "&:hover fieldset": { borderColor: "white" },
            "&.Mui-focused fieldset": { borderColor: "white" },
          },
          "& .MuiInputLabel-root": { color: "white" },
          "& .MuiInputLabel-root.Mui-focused": { color: "white" },
          "& .MuiAutocomplete-popupIndicator": { color: "white" },
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Select or Type Batch ID"
            variant="outlined"
            sx={{
              input: { color: "white" },
              "& .MuiOutlinedInput-root": {
                "& fieldset": { borderColor: "white" },
                "&:hover fieldset": { borderColor: "white" },
                "&.Mui-focused fieldset": { borderColor: "white" },
              },
              "& .MuiInputLabel-root": { color: "white" },
              "& .MuiInputLabel-root.Mui-focused": { color: "white" },
            }}
          />
        )}
      />
    </Box>
  </FormControl>
</Card>



        <Card
          style={{
            width: "30vw",
            height: "63vh",
            marginTop: "2vh",
            backgroundColor: "var(--content-color)",
            marginLeft: "0vw",
            borderRadius: "15px",
            boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
          }}
        >
          <div style={{display:"flex", flexDirection:"row"}}>
          <div
            className=""
            style={{  margin:"1px" }}
          >
            <ul style={{margin:"3px",}} className="list">
              {photoData.map((item, index) => (
                <div style={{}} key={item.id}>
               
                  <li
                    className="list-item"
                    draggable
                    onDragStart={(event) => onDragStart(event, index)}
                    onDragOver={onDragOver}
                    onDrop={(event) => onDrop(event, index)}
                    style={{
                      position: "relative",
                      backgroundImage: `url(${item.image})`,
                      backgroundSize: "contain",
                      backgroundPosition: "center",
                      backgroundRepeat: "no-repeat",
                      width: "7vw",
                      height: "10vh",
                      border: "1px solid black",
                      borderRadius: "5px",
                      cursor: "grab", // Change cursor to grab
                      padding: "4px",
                      margin: "3px",
                      transition: "transform 0.2s ease", // Smooth animation for hover/drag effects
                    }}
                    onMouseOver={(e) => {
                      e.currentTarget.style.boxShadow =
                        "0px 0px 10px rgba(0, 0, 0, 0.2)"; // Add a shadow on hover
                    }}
                    onMouseOut={(e) => {
                      e.currentTarget.style.boxShadow = "none"; // Remove shadow when not hovering
                    }}
                  >
                    {/* Drag handle */}
                    <span
                      style={{
                        position: "absolute",
                        left: 0, // Align to the left
                        top: 0,
                        height: "100%", // Full height of the li
                        width: "15%", // Adjust width as needed
                        display: "flex", // Flexbox to center content
                        alignItems: "center", // Vertical center
                        justifyContent: "center", // Horizontal center
                        backgroundColor: "rgba(0, 0, 0, 0.1)", // Optional background color for visibility
                        cursor: "grab", // Change cursor to grab
                        borderRight: "1px solid black", // Optional separator between icon and content
                      }}
                    >
                      <span style={{ fontSize: "16px" }}>⇅</span>{" "}
                      {/* Drag icon */}
                    </span>

                    {/* SKU displayed */}
                    <div
                      style={{
                        position: "absolute",
                        top: 50,
                        right: -5, // Align to the right side
                        // height: "100%", // Full height of the li
                        // width: "auto", // Automatically adjust width for the text
                        display: "flex",
                        alignItems: "center", // Vertically center the text
                        justifyContent: "center", // Horizontally center the text within the rotated container
                        backgroundColor: "rgba(255, 255, 255, 0.1)", // Background for readability
                        transform: "rotate(90deg)", // Rotate the text vertically
                        transformOrigin: "right top", // Adjust the rotation point
                        textAlign: "center", // Center the text
                        padding: "0px", // Padding around the text
                      }}
                    >
                      {item.sku}
                    </div>
                  </li>
                </div>
              ))}
            </ul>
          </div>

          <div
            className="container"
            style={{ marginLeft: "3vw", marginTop: "0vh" }}
          >
            <div style={{ width: 200 }}>
              <h5 style={{ textDecoration:"underline", color:"white"}}>Image Adjustments</h5>

              <Slider
                value={valueXOff}
                onChange={handleXOffChange}
                min={100}
                max={250}
                step={1}
                marks={[
                  { value: 100, label: "100" },
                  { value: 175, label: "175" },
                  { value: 250, label: "250" },
                ]}
                sx={{
                  "& .MuiSlider-mark": {
                    backgroundColor: "white", // Change mark color
                    height: 4, // Adjust mark size if needed
                    width: 4,
                    borderRadius: "50%", // Makes the mark circular
                  },
                  "& .MuiSlider-markLabel": {
                    color: "white", // Change mark label color
                    fontSize: "14px",
                  },
                }}
              />

              <label style={{ color: "white" }}>{valueXOff}</label>
            </div>
            <div style={{ width: 200 }}>
              {/* <h5 style={{margin: "0px"}}>Y Axis Offset</h5> */}
              <Slider
                value={valueY}
                onChange={handleYChange}
                min={100}
                max={300}
                step={1}
                marks={[
                  { value: 100, label: "100" },
                  { value: 200, label: "200" },
                  { value: 300, label: "300" },
                ]}
                sx={{
                  "& .MuiSlider-mark": {
                    backgroundColor: "white", // Change mark color
                    height: 4, // Adjust mark size if needed
                    width: 4,
                    borderRadius: "50%", // Makes the mark circular
                  },
                  "& .MuiSlider-markLabel": {
                    color: "white", // Change mark label color
                    fontSize: "14px",
                  },
                }}
              />

              <label style={{ color: "white" }}>{valueY}</label>
            </div>
            <div style={{ width: 200 }}>
              {/* <h5 style={{margin: "0px"}}>Relative Off Set</h5> */}
              <Slider
                value={valueX}
                onChange={handleXChange}
                min={-150}
                max={100}
                step={1}
                marks={[
                  { value: -150, label: "-150" },
                  { value: 0, label: "0" },
                  { value: 100, label: "100" },
                ]}
                sx={{
                  "& .MuiSlider-mark": {
                    backgroundColor: "white", // Change mark color
                    height: 4, // Adjust mark size if needed
                    width: 4,
                    borderRadius: "50%", // Makes the mark circular
                  },
                  "& .MuiSlider-markLabel": {
                    color: "white", // Change mark label color
                    fontSize: "14px",
                  },
                }}
              />

              <label style={{ color: "white" }}>{valueX}</label>
            </div>
            <div style={{ width: 200 }}>
              <Slider
                value={valueSize}
                onChange={handleSizeChange}
                min={400}
                max={1000}
                step={1}
                marks={[
                  { value: 400, label: "400" },
                  { value: 700, label: "700" },
                  { value: 1000, label: "1000" },
                ]}
                sx={{
                  "& .MuiSlider-mark": {
                    backgroundColor: "white", // Change mark color
                    height: 4, // Adjust mark size if needed
                    width: 4,
                    borderRadius: "50%", // Makes the mark circular
                  },
                  "& .MuiSlider-markLabel": {
                    color: "white", // Change mark label color
                    fontSize: "14px",
                  },
                }}
              />

              <label style={{ color: "white" }}>{valueSize}</label>
            </div>
          </div>
          </div>
        </Card>

       
        </div>
        <div style={{display:"flex", flexDirection:"column"}}>

        <div style={{  }}>
        <Card
  style={{
    marginTop: "2vh",
    backgroundColor: "var(--content-color)",
    marginLeft: "1vw",
    borderRadius: "15px",
    boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  }}
>
  <CardContent
    style={{
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      width: "100%", // Ensure content is centered inside
    }}
  >
    <div style={{ textAlign: "center", display: "flex", flexDirection: "column", alignItems: "center" }}>
      <div style={{ width: 300 }}>
        <h5 style={{textDecoration:"underline", margin: "0px", color: "white" }}>Brightness, Contrast, & Sharpness</h5>

        <Slider
          value={brightness}
          onChange={handleBrightnessChange}
          min={0.8}
          max={1.5}
          step={0.05}
          sx={{
            padding: 0,
            height: 4,
            "& .MuiSlider-thumb": { width: 12, height: 12 },
            "& .MuiSlider-track": { height: 4 },
            "& .MuiSlider-rail": { height: 4 },
          }}
        />

        <label style={{ color: "white" }}>{brightness}</label>
      </div>

      <div style={{ width: 300 }}>
        <Slider
          value={contrast}
          onChange={handleContrastChange}
          min={0.8}
          max={1.5}
          step={0.05}
          sx={{
            padding: 0,
            height: 4,
            "& .MuiSlider-thumb": { width: 12, height: 12 },
            "& .MuiSlider-track": { height: 4 },
            "& .MuiSlider-rail": { height: 4 },
          }}
        />

        <label style={{ color: "white" }}>{contrast}</label>
      </div>

      <div style={{ width: 300 }}>
        <Slider
          value={sharpness}
          onChange={handleSharpnessChange}
          min={0}
          max={5}
          step={0.1}
          sx={{
            padding: 0,
            height: 3,
            "& .MuiSlider-thumb": { width: 12, height: 12 },
            "& .MuiSlider-track": { height: 4 },
            "& .MuiSlider-rail": { height: 4 },
          }}
        />

        <label style={{ color: "white" }}>{sharpness}</label>
      </div>
    </div>
  </CardContent>
</Card>


          {/* <button onClick={getLog}>Get</button> */}
          {/* <button onClick={testUpload}>UPLOAD</button> */}
          <div style={{ marginLeft: "1vw", marginTop: "2vh" }}>
            <PhotoCreate
              handleAlert={handleAlert}
              selectedData={photoData}
              valueX={valueX}
              valueY={valueY}
              valueXOff={valueXOff}
              valueSize={valueSize}
              details={props.details}
              validate={checked1}
              auto={checked3}
              sort={checked2}
              buttonData={buttonData}
              uploadVariantData={uploadVariantData}
              handleImage={handleImage}
              brightness={brightness}
              contrast={contrast}
              sharpness={sharpness}
              location={location}
            />
          </div>
          {/* <button onClick={uploadVariantData}>Test POST</button> */}
        </div>

        </div>

        </div>
 
    </Box>
  );
}
