
import React, { useState, useEffect }  from "react"
import axios from "axios"
import Box from '@mui/material/Box';
import { Switch, FormControlLabel, Radio, RadioGroup, FormLabel, FormControl, InputLabel, MenuItem, Select, FormHelperText, TextField, Autocomplete, selectClasses  } from '@mui/material';










export default function CopyVariant() {
    const [data, setData] = useState([])
    const [skus, setSkus] = useState([])
    const [selected, setSelected] = useState("")
    const [prodId, setProdId] = useState()

    const [currentVariant, setCurrentVariant] = useState({})



    const getSingles = async () => {
        const response = await axios.post("/api/shopify/getallsingles")
    
        console.log(response.data)
    
    
        const variants  = response.data.products.map((elem) => elem.variants).flat()
    
        const images = response.data.products.map((elem) => elem.images).flat()
    
        const combinedData = variants.map((elem) => {
    
            let foundData = images.find((ellm) => ellm.id === elem.image_id)
    
    
            if (foundData) {
                return (
                    {
                        ...elem, src: foundData.src
                    }
                )
            } else {
                return (
                    {
                        ...elem, src : ""
                    }
                )
            }
        })
    
    
        console.log(combinedData)

        setData(combinedData)


        const skuArray = combinedData.map((elem) => elem.sku)

        setSkus(skuArray)
    }


    const matchSku = () => {
        const foundObj = data.find(elem => elem.sku === selected )
        
        if (foundObj) {
            setCurrentVariant(foundObj)
        }


        console.log(foundObj)
    }

    const handleRadioChange = (e) => {
      
            setProdId(e.target.value);
          



    }


    const handleCreate = async () => {

console.log(currentVariant)
console.log(prodId)

await uploadImage()
await createVariant(currentVariant)


    }

    let imageId 


    const uploadImage = async () => {
        
        const canvas = document.createElement('canvas');
        canvas.width = 800;
        canvas.height = 800;
        const ctx = canvas.getContext('2d');
        const img = new Image();
        
        // Set crossOrigin BEFORE setting src
        img.crossOrigin = "anonymous";
        
        await new Promise(resolve => {
            img.onload = () => {
                ctx.drawImage(img, 0, 0, 800, 800);
                console.log("fire");
                resolve();
            };
            img.src = currentVariant.src; // Ensure CORS headers are set on this image
        });
        
 
            const dataURL = canvas.toDataURL('image/png'); 
            console.log(dataURL);
     
        
       
          

        const base64Image = dataURL
        try {
          const res = await axios.post("/api/shopify/upload", {
            b64: base64Image.substring(22),
            fileName: currentVariant.sku,
            productId: Number(prodId)
          });
          if (res.status === 200) {
        imageId = res.data.image.id;
          
          }
        } catch (error) {
          console.error(error);
          throw new Error("Error uploading image");
        }
      };



      const createVariant = async (details) => {

  
        try {

          const res = await axios.post("/api/shopify/create2", {
            size: details.option2,
            color: details.option1,
            price: "17.00",
            sku: details.sku,
            weight: 2.8,
            productId: prodId,

         
          });
          if (res.status === 200) {
            await associateVariantWithImage(res.data.variant.id);
        
         
  
          }
        } catch (error) {
          console.error(error);
          throw new Error("Error creating variant");
        }
      };


      const associateVariantWithImage = async (variantId) => {
   
        
        try {
          const res = await axios.post("/api/shopify/associate", {
            variant_id: variantId,
            image_id: imageId,
          });
       
   

          
  
    
          console.log(res);
        } catch (error) {
          console.error(error);
          throw new Error("Error associating variant with image");
        }
      };









    return (
        <div style={{ marginLeft: "6vw" }}>

            <button onClick={getSingles}>GET</button>
            <button onClick={matchSku}>Match</button>


            <Box
                      component="form"
                      sx={{
                        color:"white",
                        '& > :not(style)': { m: 0, width: '25ch' },
                      }}
                      noValidate
                      autoComplete="off"
                    >

                      <Autocomplete
                            style={{color:"white"}}
                            freeSolo
                            options={skus}
                            value={selected}
                            onChange={(event, newValue) => {
                              setSelected(newValue || ""); // Sets value when selected from the list
                            }}
                            onInputChange={(event, newValue) => {
                              setSelected(newValue); // Sets value when typing
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select or Type Batch ID"
                                variant="outlined"
                                sx={{color:"white"}}
                              />
                            )}
                          />

                          </Box>


            {
                currentVariant &&

                <div style={{display:"flex", color:"white"}}>
                    <div>
                    <img style={{height:400}} src={currentVariant.src}></img>
                    <h3>{currentVariant.sku}</h3>
                    <h3>{currentVariant.product_id}</h3>
                    </div>

                    <div>

                    <RadioGroup
                      aria-labelledby="demo-controlled-radio-buttons-group"
                      name="controlled-radio-buttons-group"
                      value={prodId}
                      onChange={handleRadioChange}
                    >
                      <FormControlLabel value="14673989370226" control={<Radio />} label="Mens LW Crew" />
                      <FormControlLabel value="14674023907698" control={<Radio />} label="Mens Crew" />
                      <FormControlLabel value="14674020303218" control={<Radio />} label="Mens COMP" />
                      <FormControlLabel value="14674026234226" control={<Radio />} label="Mens Low Cut" />
                      <FormControlLabel value="14673984586098" control={<Radio />} label="Mens Performace" />
                      <FormControlLabel value="14673999135090" control={<Radio />} label="Womens NS" />
                      <FormControlLabel value="14674014798194" control={<Radio />} label="Womens COMP" />
                      <FormControlLabel value="14674017943922" control={<Radio />} label="Womens LW Crew" />
                      <FormControlLabel value="14674010505586" control={<Radio />} label="Womens Low cut" />
                      <FormControlLabel value="14674011423090" control={<Radio />} label="Kids crew" />
                    </RadioGroup>



                    <button onClick={handleCreate}>COPY</button>



                    </div>

                </div>
                

            }
  
        </div>

    )
}