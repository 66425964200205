
import React, { useEffect, useState, useRef } from 'react';

import axios from "axios"

import Papa from "papaparse"

import Button from '@mui/material/Button'
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';


import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });










export default function EOM() {
    const [view, setView] = useState(0)
    const [variantArray, setVariantArray] = useState([])
    const [catData, setCatData] = useState([])
    const [load, setLoad] = useState(false)

    const fileInputRef = useRef(null);


    const getAllSingles = async () => {
        setLoad(true)
        const response =  await axios.post("/api/shopify/getallvariants")

        console.log(response.data.products)

        const variants = response.data.products.map((elem) => {

            return (
                elem.variants
            )
        }).flat()

        console.log(variants)

        setVariantArray(variants)

        return (variants)
    }


    const getTitle = (id) => {
        let title = ""

        if (id === 6852663804004 || id === 6620534669412 || id === 6597571575908 || id === 4837716951140 || id === 6950074384484 || id === 7163184283748 || id === 4837733957732 || id === 7286790619236 || id === 7851959943268 || id === 7901240098916 ) { 
           title = "Womens Crew Socks"
          } 
          
          else if (id === 6873293586532 || id === 6930797101156 || id === 7255628546148 || id === 7217697390692 || id === 7902016798820 || id === 14655282250098) {
           title = "Women's Compression Socks"
          }
          else if (id === 6824475361380) {
            title = "Women's Golf Socks"
          }
          else if (id === 6609129275492 || id === 7255634051172 || id === 7217910743140 || id === 6609176789092 || id === 6609178230884 || id === 7863713726564 ) {
           title = "Women's Light-Weight Crew Pacas Socks"
          }
          else if ( id === 6626100314212 || id === 6816252428388 || id === 4823040131172 || id === 7217900060772 || id === 7851605065828 ) {
            title = "Women's Low Cut Socks"
          }
          else if (id === 6541486489700 || id === 7321956876388 || id === 7909757681764 || id === 6585673187428) {
            title = "Women's No Show Socks"
          }
          else if (id === 6557907746916 || id === 6558139154532) {
            title = "Women's Performance Socks"
          }
          else if (id === 6575734030436 || id === 6575736914020) {
            title = "Men's Performance Socks"
          }
          else if (id === 7105370357860 || id === 7105422360676) {
              title = "Women's Slouchy Socks"
            }
            else if (id === 14665442656626) {
              title = "Women's Ruffle Socks"
              
            }
          else if (id === 7194189430884) {
            title = "Women's Hiking"
          }
          else if (id === 7194189987940) {
           title = "Women's Hiking Qrt"
          }

         
          else if (id === 6888734425188 || id === 6888748580964 || id === 6888738160740 || id === 6888742060132 || id === 6888726921316 || id === 6888747335780) {
            title = "Pacas Throw Blanket"
          }
      
          else if (id === 2236767502436 || id === 6626100314212 || id === 6816252428388 || id === 6958959296612 || id === 7255625433188 ) {
           title = "Women's Low Cut Socks"
          }
      
          else if (id=== 6956831965284 || id === 4837718753380 || id === 4837737365604 || id === 7223568990308 || id === 7255597383780 || id === 7851670143076) {
            title = "Men's Crew"
          }
          else if (id === 6878354341988 || id === 6930798379108) {
            title = "Men's Compression Socks"
          }
      
          else if (id === 6602920394852 || id === 6602924785764 || id === 6602925637732 || id === 7897203343460) {
            title = "Men's Light-Weight Crew Pacas Socks"
          }

          else if (id === 6824474771556) {
            title = "Men's Golf Socks"
          }

          else if (id === 7194190217316) {
            title = "Men's Hiking"
          }

          else if (id === 7194190512228) {
            title = "Men's Hiking Qrt"
          }
          else if (id === 4838251888740 || id === 4838251987044 || id === 7912946368612 || id === 7254786015332 || id === 7009310113892) {
            title = "Kids' Crew Pacas Socks"
          }
      
          else if (id === 6873185353828 || id === 6862851080292 || id === 6873188270180 || id === 6873186828388 || id === 6873182437476 ) {
            title = "Pacas Summer Wrap"
          }
      
          else if (id === 6972996714596 || id === 6972996157540 || id === 6972996976740 || id === 6972996419684 || id === 6972997271652 ) {
            title = "Pacas Shawl"
          }
      
          else if (id === 4838368051300 || id === 4838370410596 ) {
            title = "Toddler Pacas Socks"
          }
          else if (id === 7000945000548 || id === 7000945590372  || id === 7000423956580) {
            title = "Pacas Scarf"
          }
      
          else if (id === 7082909958244 || id === 7082909139044 ) {
            title = "Gift Box"
          }
  

          else if (id === 4823046324324 || id === 7255624745060 || id === 7851616370788 || id === 4823047143524  ) {
              title = "Men's Low Cut Pacas Socks"
            }


            return (title)
    }



    const generateExcelFile = async (d1, d2) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');
    
        const data = d1
    
      
        // Add column headers
        const headers = Object.keys(data[0]);
        worksheet.addRow(headers);
    
        // Add data rows
        data.forEach(item => {
          const values = Object.values(item);
          worksheet.addRow(values);
        });


        const worksheet2 = workbook.addWorksheet('Sheet 2');
    
        const data2 = d2
    
      
        // Add column headers
        const headers2 = Object.keys(data2[0]);
        worksheet2.addRow(headers2);
    
        // Add data rows
        data2.forEach(item => {
          const values = Object.values(item);
          worksheet2.addRow(values);
        });
    
    
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'EOMI.xlsx');
        });
      };
 



     const handleFileUpload = async (e) => {
            let currentTime = new Date().toLocaleString()
            console.log(currentTime)
            const files = e.target.files;
            console.log(files);
            if (files) {
              console.log(files[0]);
              Papa.parse(files[0], {
                header: true,
                complete: async function(results) {
                  console.log("Finished:", results.data);
                  let formArray = results.data.map(elem => {
    
          
               
          
                    return (
                      {
                        sku: elem.SKU,
                        quantity: Number(elem.AVAILABLE)
                    
                      
                    
                      }
                    )
                    
              
                  })


                  const filteredData = formArray.filter((elem) => elem.quantity > 0)


                  const decode = filteredData.map((elem) => {
                        let skuDecodeArray = []

                        if (elem.sku[4] === "-") {
                            const bufferArray = elem.sku.split("-")

                            bufferArray.forEach((ellm) => {
                                skuDecodeArray.push({sku: ellm, quantity: elem.quantity})
                            })
                        }
                        else if (elem.sku[1] === "-" && elem.sku[0] !== "H") {
                            const bufferArray = elem.sku.split("X")
                            const bufferArray2 = bufferArray.map((elm) => {
                                    const quantity = parseInt(elm) * elem.quantity
                                    const sku1 = elm.substring(2,6)

                                return (
                                    {
                                        sku: sku1, quantity: quantity
                                    }
                                )
                            })

                            skuDecodeArray = bufferArray2
                        }

                        else if (elem.sku[12] === "-") {
                            const formsku = elem.sku.replace(/-/g,"")

                            const skuArray = formsku.match(/.{1,4}/g);


                            skuArray.forEach((ellm) => {
                                skuDecodeArray.push({sku: ellm, quantity: elem.quantity})
                            })




                        }
                        
                        else {
                            skuDecodeArray.push(elem)
                        }



                    return (
                        skuDecodeArray
                    )
                  }).flat().sort((a,b) => a.sku - b.sku )
                  console.log(filteredData)
                  console.log(decode)



                  const groupedItems = Object.values(
                    decode.reduce((acc, { sku, quantity }) => {
                        if (!acc[sku]) {
                            acc[sku] = { sku, quantity };
                        } else {
                            acc[sku].quantity += quantity;
                        }
                        return acc;
                    }, {})
                );

                console.log(groupedItems)


             const variantData = await getAllSingles()


                  const prodMatch = groupedItems.map((elem) => {

                    const foundData = variantData.find((ellm) => ellm.sku === elem.sku)

                    return (
                        {
                            sku: elem.sku,
                            quantity: elem.quantity,
                            prodId: (foundData) ? foundData.product_id : "N/A"
                        }
                    )
                  })


                  console.log(prodMatch)


                  const dataFinal = prodMatch.map((elem) => {

                    const cat = getTitle(elem.prodId)
                    return (
                        {
                            ...elem, cat: cat
                        }
                    )
                  })


                  console.log(dataFinal)


                  const predefinedOrder = [
                    "Kids' Crew Pacas Socks",
                    "Men's Compression Socks",
                    "Men's Crew",
                    "Men's Golf Socks",
                    "Men's Light-Weight Crew Pacas Socks",
                    "Men's Low Cut Pacas Socks",
                    "Men's Performance Socks",
                    "Toddler Pacas Socks",
                    "Women's Compression Socks",
                    "Womens Crew Socks",
                    "Women's Golf Socks",
                    "Women's Light-Weight Crew Pacas Socks",
                    "Women's Low Cut Socks",
                    "Women's No Show Socks",
                    "Women's Performance Socks",
                    "Women's Slouchy Socks",
                    "Women's Hiking Qrt",
                    "Women's Hiking",
                    "Men's Hiking Qrt",
                    "Men's Hiking"
                ];


                  const dataFinal2 = Object.values(
                    dataFinal.reduce((acc, { cat, quantity }) => {
                        if (!acc[cat]) {
                            acc[cat] = { cat, quantity };
                        } else {
                            acc[cat].quantity += quantity;
                        }
                        return acc;
                    }, {})
                ).sort((a, b) => predefinedOrder.indexOf(a.cat) - predefinedOrder.indexOf(b.cat));


                console.log(dataFinal2)


             


                generateExcelFile(dataFinal2, dataFinal)
                setLoad(false)
                //   setUploadData(formArray)
             
        
               
                }}
              )
            }
          
          }
    









    return (
        <div style={{ marginLeft: "6vw" }}>
        {load ?
                    <div style={{marginLeft: "41vw", marginTop: "32vh"}}>
         <CircularProgress size={150} color="inherit" />
         </div>
         :
         <Button style={{height:"10vh", width:"10vw", margin:"10vw"}} component="label" variant="contained" >
         EOM Inventory
         <VisuallyHiddenInput ref={fileInputRef} onChange={handleFileUpload} type="file" accept=".csv" />
         </Button>

    }
       
        

  
        </div>

    )
}