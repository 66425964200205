
import React, { useState } from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import LinearProgress from '@mui/material/LinearProgress';

import axios from "axios";


export default function CreateAPI(props) {

    const [position1, setPosition1] = useState(1)
    const [position2, setPosition2] = useState(2)
    const [open, setOpen] = useState(false);
    const [checkSku, setCheckSku] = useState([]);
    const [progress, setProgress] = useState(0);
    const [status, setStatus] = useState("");
    const [progOpen, setProgOpen] = useState(false);
    


    const handleClickOpen = () => {
      setOpen(true);
    };

    const increase = () => {
      if (progress === 100) {
        handleProgClose()
     
      } 
   
    }

    const handleProgOpen = () => {
      setProgOpen(true);
      setProgress(5)
      
    };

    const handleProgClose = () => {
      setProgOpen(false);
    };
  
  
    const handleClose = () => {
      setOpen(false);
    };


    //Handle download
    const handleDownload = () => {
        console.log(props.details)
        const link = document.createElement('a');
        link.download = props.details.sku1;
        link.href = props.base64Image; 
        document.body.appendChild(link); 
        link.click();
        document.body.removeChild(link); 
      };

    //API CALLS

    let imageId = "";

    const confirm = () => {
      changeImage()
      handleClose()
    }

    const settings = () => {
      if (props.validate === true) {
        validate()
        handleProgOpen()
      } else {
        sendData()
        handleProgOpen()
      }
    }


    const changeImage = () => {

      handleProgOpen()
      console.log(progress)
      setStatus("Uploading...")
      axios.post("/api/shopify/upload", {
        b64: props.base64Image.substring(22), fileName: props.details.sku1, productId: props.details.targetId
      })
      .then((res) => {
        setProgress(60)
        imageId = res.data.image.id
        // checkSku.forEach(elm => {
        //   associateVariantWithImage(elm.variantId)
        // })
        changeAssociate()

    
   
      })
      // setStatus("Finished")
      // console.log(progress)
      // setProgress(100)
    }

  const changeAssociate = async () => {
    await associateVariantWithImage(checkSku[0].variantId)
    setProgress(80)
    await associateVariantWithImage(checkSku[1].variantId)
    setProgress(100)
    setStatus("Finished")
  }

  const handleButtonDownload = (a,b) => {

    const link = document.createElement('a');
    link.download = b;
    link.href = a; 
    document.body.appendChild(link); 
    link.click();
    document.body.removeChild(link); 
  };


  const handleButtonUpload = async (a,b) => {
    let response = await axios.post("/api/shopql/uploadbutton", {
      imageData: a,
      fileName: b.toString() + ".png"
    })
  }


const validate = () => {

  setStatus("Validating...")
   

      axios.post("/api/shopify/getcurrent", {
        productId: props.details.targetId
      })
      .then((res) => {
        console.log(res.data.products[0].variants)
        let currentSku = res.data.products[0].variants.map(elem => {
          return {sku: elem.sku, variantId: elem.id}
        })

        let checkSkuBuff = currentSku.filter(ellm => ellm.sku === props.details.sku1 || ellm.sku === props.details.sku2)
        setCheckSku(checkSkuBuff)

        if (checkSkuBuff.length > 0 ) {
          handleProgClose()
          handleClickOpen()

      } else {
        setProgress(20)
        sendData()
      }


      })



    

  }

    const uploadImage = async (base64Image, sku) => {
      console.log(base64Image.length)
      try {
        const res = await axios.post("/api/shopify/upload", {
          b64: base64Image.substring(22),
          fileName: sku,
          productId: props.details.targetId
        });
        if (res.status === 200) {
          imageId = res.data.image.id;
        
        }
      } catch (error) {
        console.error(error);
        throw new Error("Error uploading image");
      }
    };
    
    const createVariant = async (details, upc) => {

      // console.log(upc)

      //barcode test
        const foundData = upc.find((elem) => elem.sku === details.sku1 )
        console.log(foundData)
        console.log(details.sku1)


      //end barcode test



      try {
        console.log(details)
        const res = await axios.post("/api/shopify/create", {
          size: details.size1,
          color: details.color,
          price: details.price,
          caPrice: details.caPrice,
          sku: (props.location === "16177561700" ) ? `WEST${details.sku1}` : details.sku1,
          weight: details.weight1,
          productId: props.details.targetId,
          barcode: (foundData ? Number(foundData.upc) : null ),
          location: Number(props.location)
       
        });
        if (res.status === 200) {
          await associateVariantWithImage(res.data.variant.id);
          await handleHarmonization(res.data.variant.inventory_item_id);
          if (props.auto) {
            setStatus("Uploading Button...")
            await handleButtonUpload(props.buttonData, res.data.variant.id)

          } else {
            handleButtonDownload(props.buttonData, res.data.variant.id)

          }
       

        }
      } catch (error) {
        console.error(error);
        throw new Error("Error creating variant");
      }
    };
    
    const associateVariantWithImage = async (variantId) => {
      setStatus("Associating...")
      
      try {
        const res = await axios.post("/api/shopify/associate", {
          variant_id: variantId,
          image_id: imageId,
        });
     
 
        // setProgress(progress + 10)
        

        console.log(progress)
        console.log(res);
      } catch (error) {
        console.error(error);
        throw new Error("Error associating variant with image");
      }
    };

    const handleHarmonization = async (iiID) => {
      const response =  await axios.post("/api/shopify/harmonization", {
        iiID: iiID
      })
    }

    //barcode

      const getUPCs = async () => {
        const response = await axios.post("/api/upc/getall")

      return(response.data)

      }


    //end
    
    const sendData = async () => {
      if (props.handleAlert()) {
        console.log(props.details)
      try {
        setStatus("Uploading...")
        await uploadImage(props.base64Image, props.details.sku1);
        setProgress(30)
        setStatus("Checking UPCs...")
        const upcs = await getUPCs()
        console.log("After UPCS")
        setProgress(40)
        setStatus("Creating First Variant...")
        console.log("Before First")
        await createVariant(props.details, upcs);
        console.log("AFter First")
        setProgress(50)
        setStatus("Creating Second Variant...")
        await createVariant({
          size1: props.details.size2,
          color: props.details.color,
          price: props.details.price,
          caPrice: props.details.caPrice,
          sku1: props.details.sku2,
          weight1: props.details.weight2,
        
        }, upcs);
        
        
        if (props.sort === true) {
        setProgress(70)
        setStatus("Auto Sorting...")
        await getCurrent()
        }
        await props.uploadVariantData()
        setStatus("Finished")
        setProgress(100)
      } catch (error) {
        console.error(error);
        alert("Error occurred");
      }
    } else {
      return
    }
    };

    const getCurrent = async () => {
        try {
          const res = await axios.post("/api/shopify/getcurrent", {
            productId: props.details.targetId
          });
          console.log(res.data.products[0]);
          console.log(res.data.products[0].variants.length);
          let total = res.data.products[0].variants.length
          let var1 = Math.floor(total / 2) + 1
          let var2 = (total + 2)
          console.log(var1)
          console.log(var2)
          setPosition1(var1)
          setPosition2(var2)
        //   let dataSort = res.data.products[0].variants.sort((a,b) => a.option1 < b.option1)

          let dataSort = res.data.products[0].variants.sort((a, b) => {
            if (a.option1 > b.option1) {
              return -1;
            } else if (a.option1 < b.option1) {
              return 1;
            } else {
              return 0;
            }
          }).map((elem, i) => {
            return (
               { id: elem.id,
                position: (i + 1)
            }
            )
          })
          console.log(dataSort)
         
          axios.post("/api/shopify/changeposition", {
            variantData: dataSort,
            productId: props.details.targetId
          }). then((res) =>  console.log(res))
        } catch (error) {
          console.error(error);
          throw new Error("Error getting current");
        }
        
      };


      const changePosition = async (a,b) => {
        console.log(a)
        console.log(b)
        try {
          const res = await axios.post("/api/shopify/changeposition", {
            id: a,
            position: b
          });
          console.log(res);
  
        } catch (error) {
          console.error(error);
          throw new Error("Error getting current");
        }
      };







    return (
        <div>
          <div style={{display:"flex",  }}>

        
            {/* <Button onClick={handleDownload} variant="contained">DOWNLOAD</Button> */}
            <Button onClick={settings} style={{marginLeft:"13vw", marginTop:"0.5vh"}} variant="contained">Create</Button>
            </div>
            {/* <Button onClick={handleProgOpen} style={{marginLeft: "10vw"}} variant="contained">Open</Button> */}
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Duplicate Variant Detected"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Change variant images to current image?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleClose}>No</Button>
                <Button onClick={confirm} autoFocus>
                  Yes
                </Button>
              </DialogActions>
            </Dialog>

            
            <Dialog
              open={progOpen}
              onClose={handleProgClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              PaperProps={{ sx: { width: "30%", height: "25%" } }}
            >
              <DialogTitle id="alert-dialog-title">
                {"Creating Variant"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    {status}
                </DialogContentText>
                <LinearProgress variant="determinate" value={progress} />
              </DialogContent>
              <Button onClick={increase}>Exit</Button>
              <DialogActions>

              </DialogActions>
            </Dialog>
            {/* <Button onClick={getCurrent} style={{marginLeft: "10vw"}} variant="contained">Get Current</Button> */}
        </div>
    )
}