
import React, { useEffect, useState } from 'react';
import axios from "axios"
import {  onAuthStateChanged, getAuth, signOut  } from 'firebase/auth';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';




export default function Alerts() {
    const [user, setUser] = useState("");
    const [load, setLoad] = useState(false);
    const [subscriptions, setSubscriptions] = useState([]);
    const [reportData, setReportData] = useState([]);
    const [hasChanged, setHasChanged] = useState(0)


    const auth = getAuth();

    useEffect(() => {
     
        onAuthStateChanged(auth, (user) => {

          if (user) {
            console.log(user.email)

           
     
            setUser(user.email)
            getData(user.email)
          } else {
            console.log("no")
          }
        });
   
      }, [hasChanged]);


const getData = async (userP) => {
    let response = await axios.post("/api/report/getreport")

    console.log(response.data)



    const subs = response.data.map((elem) => {
        console.log(elem)
        if (elem.subscribers.includes(userP)) {
            return (
             elem.id
            )
            
        }
    }).filter((ellm) => ellm !== undefined)

    setSubscriptions(subs)
    console.log(subs)

    setReportData(response.data)

    setLoad(true)


}


const subbedUsers = (id) => {
    const subs = reportData.filter((elem) => elem.id == id)
    return (
        subs.map((ellm) => {
            return (
                ellm.subscribers
            )
        })
    )
}

const subscribe = async (id) => {
    const response = await axios.post("/api/report/subscribe",{
        id: id,
        user: user
    })

    console.log(response)

    let value
    if (hasChanged === 0) {
        value = 1
    } else {
        value = 0
    }

    setHasChanged(value)
}


const unsubscribe = async (id) => {
    console.log("Fire")
    const response = await axios.post("/api/report/unsubscribe",{
        id: id,
        user: user
    })

    console.log(response)

    let value
    if (hasChanged === 0) {
        value = 1
    } else {
        value = 0
    }

    setHasChanged(value)
}


const createOne = async () => {
    const response = await axios.post("/api/report/create", {
        id: "Dlss"
    })

    console.log(response.data)
}

const userSub = (id) => {
    console.log("Fire")
    return (subscriptions.includes(id))
}


const log = () => {
    console.log(userSub("QswE"))

    console.log(subscriptions)
}


 
if (load) {


    return (
        <Box sx={{ marginLeft: "5vw", overflowY: "auto", maxHeight: "90vh", padding: 2 }}>
        <Box sx={{ 
            display: "grid", 
            gridTemplateColumns: "repeat(3, 1fr)", // 3 columns per row
            gap: 3,
            justifyItems: "center"
        }}>
            {[
                { id: "QswE", title: "Predictive / Actual - Products & Variant Sales", type: "Report", time: "9:00am Mondays" },
                { id: "FFgp", title: "Freebies", type: "Report", time: "7:30am Everyday" },
                { id: "BPjo", title: "Missing Buttons / Variant Anomalies", type: "Alert", time: "10:00am & 5:00pm Everyday" },
                { id: "SSqw", title: "Single Shop Outages", type: "Alert", time: "Every Hour" },
                { id: "Dlss", title: "Single Shop Daily", type: "Report", time: "9:30am Everyday" },
                { id: "FlAa", title: "Job Failures", type: "Report", time: "7:30am Everyday" }
            ].map((card) => (
                <Card key={card.id} variant="outlined" sx={{ 
                    color:"white",
                    backgroundColor: 'var(--content-color)',
                    boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
                    borderRadius: "15px",
                    width: "30vw", 
                    flex: "1 1 300px", 
                    display: "flex", 
                    flexDirection: "column",
                    justifyContent: "space-between", 
                    minHeight: "250px" // Ensures all cards have equal height
                }}>
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" gutterBottom>{card.title}</Typography>
                        <Typography sx={{ fontSize: 14 }} component="div">Type: {card.type}</Typography>
                        <Typography variant="body2">Time: {card.time}</Typography>
                        <Typography variant="body2">Subscribers: {subbedUsers(card.id).join(", ")}</Typography>
                    </CardContent>
                    <CardActions sx={{ justifyContent: "center" }}>
                        {userSub(card.id) ? (
                            <Button onClick={() => unsubscribe(card.id)}>Unsubscribe</Button>
                        ) : (
                            <Button onClick={() => subscribe(card.id)}>Subscribe</Button>
                        )}
                    </CardActions>
                </Card>
            ))}
        </Box>
    
        {/* Last row with 2 columns */}
        <Box sx={{ 
            display: "grid", 
            gridTemplateColumns: "repeat(2, 1fr)", // Last row has 2 columns
            gap: 2,
            justifyItems: "center",
            marginTop: 3
        }}>
            {[
                { title: "Order Customer - Populate", type: "Update", time: "2:30am Everyday" },
                { title: "Order Line Items - Populate", type: "Update", time: "2:00am Everyday" }
            ].map((card, index) => (
                <Card key={index} variant="outlined" sx={{ 
                    width: "46vw", 
                    flex: "1 1 300px", 
                    display: "flex", 
                    flexDirection: "column",
                    justifyContent: "space-between", 
                    minHeight: "10vh" ,
                    color:"white",
                    backgroundColor: 'var(--content-color)',
                    boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
                    borderRadius: "15px",
                }}>
                    <CardContent sx={{ flexGrow: 1 }}>
                        <Typography variant="h6" gutterBottom>{card.title}</Typography>
                        <Typography sx={{ fontSize: 14 }} component="div">Type: {card.type}</Typography>
                        <Typography variant="body2">Time: {card.time}</Typography>
                    </CardContent>
                </Card>
            ))}
        </Box>
    </Box>
    
    
    );
} else {
    return (
        <div></div>
    )
}
}