
import React, { useState } from 'react';



export default function ColorChanger() {
    const [primaryColor, setPrimaryColor] = useState('#ff0000')

    const handleClick = () => {
        const root = document.documentElement;
        root.style.setProperty('--primary-color', '#0837a3');
        root.style.setProperty('--secondary-color', '#2b2e36');
        root.style.setProperty('--content-color', '#0a1173');
        root.style.setProperty('--text-color', '#2b2e36');
      };

      const handleClick2 = () => {
        const root = document.documentElement;
        root.style.setProperty('--primary-color', '#fff6b5');
        root.style.setProperty('--secondary-color', '#e0b414');
        root.style.setProperty('--content-color', '#d4b82f');
        root.style.setProperty('--text-color', '#2b2e36');
      };

      const handleClick3 = () => {
        const root = document.documentElement;
        root.style.setProperty('--primary-color', '#f4b5ff');
        root.style.setProperty('--secondary-color', '#e334d1');
        root.style.setProperty('--content-color', '#a61286');
        root.style.setProperty('--text-color', '#2b2e36');
      };
      const handleClick4 = () => {
        const root = document.documentElement;
        root.style.setProperty('--primary-color', '#505152');
        root.style.setProperty('--secondary-color', '#303030');
        root.style.setProperty('--counter-color', '#f0f1f2');
        root.style.setProperty('--content-color', '#0d0c0c');
        root.style.setProperty('--text-color', '#f0f1f2');
      };
      const handleClick5 = () => {
        const root = document.documentElement;
        root.style.setProperty('--primary-color', '#d0d5db');
        root.style.setProperty('--secondary-color', '#dbdbdb');
        root.style.setProperty('--counter-color', '#303030');
        root.style.setProperty('--content-color', 'white');
        root.style.setProperty('--text-color', '#313233');
      };
    return (
        <div style={{ marginLeft: "200px" }}>
          <h1 style={{color: "var(--text-color)", margin: "10px"}}>Theme Color</h1>
          <div  style={{display: "flex"}}>
                  {/* <div style={{borderRadius: "50%", height: "50px",  width: "50px", backgroundColor: "#0837a3", margin: "10px", border: "2px solid black"}} onClick={handleClick}></div>
                  <div style={{borderRadius: "50%", height: "50px",  width: "50px", backgroundColor: "#fff6b5", margin: "10px", border: "2px solid black"}} onClick={handleClick2}></div>
                  <div style={{borderRadius: "50%", height: "50px",  width: "50px", backgroundColor: "#f4b5ff", margin: "10px", border: "2px solid black"}} onClick={handleClick3}></div> */}
                  <div style={{borderRadius: "50%", height: "50px",  width: "50px", backgroundColor: "#a3d1a8", margin: "10px", border: "2px solid black"}} onClick={handleClick4}></div>
                  <div style={{borderRadius: "50%", height: "50px",  width: "50px", backgroundColor: "#303030", margin: "10px", border: "2px solid black"}} onClick={handleClick5}></div>
          </div>
                  {/* <button onClick={handleClick2}>Change Color</button>
                  <button onClick={handleClick3}>Change Color</button>
                  <button onClick={handleClick4}>Change Color</button> */}
                  {/* <div style={{ backgroundColor: 'var(--primary-color)' }}>Hello, World!</div> */}
        </div>
    )
}