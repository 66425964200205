import React, { useState, useEffect, useRef } from "react";
import * as pdfjsLib from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.mjs";

import Papa from "papaparse";

import * as ExcelJS from "exceljs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import pacas from "../assets/pacasred.png";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
// import PDFDocument from "pdfkit";
// import blobStream from "blob-stream";

import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

export default function Invoices() {
  const [tableData, setTableData] = useState([]);
  // const [invoiceNumber, setInvoiceNumber] = useState("");
  // const [date, setDate] = useState("");
  // const [dueDate, setDueDate] = useState("");
  // const [po, setPo] = useState();
  // const [store, setStore] = useState("");
  // const [quantityTotal, setQuantityTotal] = useState();
  // const [priceTotal, setPriceTotal] = useState();

  const fileInputRef = useRef(null);

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (!file) return;

    const reader = new FileReader();
    reader.readAsArrayBuffer(file);
    reader.onload = async () => {
      const pdfData = new Uint8Array(reader.result);
      const pdf = await pdfjsLib.getDocument({ data: pdfData }).promise;

      let extractedText = "";

      let textArray = [];
      for (let i = 1; i <= pdf.numPages; i++) {
        const page = await pdf.getPage(i);
        const textContent = await page.getTextContent();
        //   console.log(textContent)

        const textFilter = textContent.items.filter((elem) => elem.str !== " ");
        //   console.log(textFilter)
        textArray.push(textFilter);
        console.log(textArray);
        extractedText +=
          textContent.items.map((item) => item.str).join(" ") + "\n";
      }

      console.log(extractedText);

      parsePDFText(textArray.flat());
    };
  };

  // const parsePDFText = (text) => {
  //     const stringArray = text.map((elem) => elem.str);

  //     // Function to find first index of an 11-digit string (UPC)
  //     const findUPC = (arr, startIndex = 0) => {
  //       const regex = /^\d{11}$/;
  //       return arr.findIndex((item, idx) => idx >= startIndex && regex.test(item));
  //     };

  //     const result = [];
  //     let index = findUPC(stringArray); // Find first UPC

  //     while (index !== -1 && index + 6 < stringArray.length) {
  //       // Create an object with keys 1-7
  //       const obj = {
  //         upc: stringArray[index],      // UPC
  //         sku: stringArray[index + 1],  // Next value
  //         des: stringArray[index + 2],
  //         quant: stringArray[index + 3],
  //         each: stringArray[index + 4],
  //         epp: stringArray[index + 5],
  //         et: stringArray[index + 6],  // Last value in the cycle
  //       };

  //       result.push(obj);
  //       index = findUPC(stringArray, index + 7); // Find the next UPC, skipping processed elements
  //     }

  //     console.log(result)
  //   };

  const parsePDFText = (text) => {
    const stringArray = text.map((elem) => elem.str);

    // Function to find first index of an 11-digit string (UPC)
    const findUPC = (arr, startIndex = 0) => {
      const regex = /^\d{11}$/;
      for (let i = startIndex; i < arr.length; i++) {
        if (regex.test(arr[i])) return i;
      }
      return -1; // Return -1 if no match found
    };

    const result = [];
    let index = 0;

    const isNumericString = (str) => {
      return /^\d+$/.test(str);
    };

    while ((index = findUPC(stringArray, index)) !== -1) {
      if (index + 6 >= stringArray.length) break; // Ensure we don't go out of bounds

      let check = isNumericString(stringArray[index + 3]);

      const obj = {
        upc: Number(stringArray[index]), // UPC
        sku: stringArray[index + 1], // Next value
        des: stringArray[index + 2],
        quant: check
          ? Number(stringArray[index + 3])
          : Number(stringArray[index + 4]),
        each: check ? stringArray[index + 4] : stringArray[index + 5],
        epp: check
          ? Number(stringArray[index + 5])
          : Number(stringArray[index + 6]),
        et: check
          ? Number(stringArray[index + 6])
          : Number(stringArray[index + 7]),
      };

      result.push(obj);
      index += 7; // Move forward to look for the next UPC
    }

    console.log(result);

    //calc totals

    const sumQuantities = (data) => {
      return data.reduce((total, item) => total + (item.quant || 0), 0);
    };

    const sumPrice = (data) => {
      return data.reduce((total, item) => total + (item.et || 0), 0);
    };

    const tableForm = result.map((elem) => {
      return [elem.upc, elem.sku, elem.quant, elem.epp, elem.et];
    });

    setTableData(tableForm);

    const findPO = (arr, startIndex = 0) => {
      for (let i = startIndex; i < arr.length; i++) {
        if (arr[i] === "PURCHASE ORDER #") return arr[i + 1];
      }
      return -1; // Return -1 if no match found
    };

    console.log(findPO(stringArray));

    //Ship to:
    const findStore = (arr, startIndex = 0) => {
      for (let i = startIndex; i < arr.length; i++) {
        if (arr[i] === "Ship to:") return arr[i + 1];
      }
      return -1; // Return -1 if no match found
    };

    console.log(findStore(stringArray));

    const findShip = (arr, startIndex = 0) => {
      for (let i = startIndex; i < arr.length; i++) {
        if (arr[i] === "TO SHIP:") return arr[i + 1];
      }
      return -1; // Return -1 if no match found
    };

    const findCancel = (arr, startIndex = 0) => {
      for (let i = startIndex; i < arr.length; i++) {
        if (arr[i] === "CANCEL:") return arr[i + 1];
      }
      return -1; // Return -1 if no match found
    };

    console.log(findShip(stringArray));

    console.log(findCancel(stringArray));

    const findAddress = (arr, startIndex = 0) => {
      let findIndex;
      for (let i = startIndex; i < arr.length; i++) {
        if (arr[i] === "Ship to:") findIndex = i;
      }

      const addressForm =
        arr[findIndex + 1] +
        " " +
        arr[findIndex + 4] +
        " " +
        arr[findIndex + 7] +
        " " +
        arr[findIndex + 9] +
        " " +
        arr[findIndex + 10];

      return addressForm;
    };

    console.log(findAddress(stringArray));

    const findTotalQuant = (arr, startIndex = 0) => {
      let findIndex;
      for (let i = startIndex; i < arr.length; i++) {
        if (arr[i] === "TOTAL") findIndex = i + 1;
      }
      return findIndex;
    };

    const findTotalPrice = (arr, startIndex = 0) => {
      let findIndex;
      for (let i = startIndex; i < arr.length; i++) {
        if (arr[i] === "TOTAL") findIndex = i + 2;
      }
      console.log(stringArray);
      console.log(findIndex);
      return findIndex;
    };
    const tq = Number(stringArray[findTotalQuant(stringArray)]);
    const tp = Number(
      stringArray[findTotalPrice(stringArray)].replace(/,/g, "")
    );

    console.log(tq);
    console.log(tp);

    console.log(sumQuantities(result));

    console.log(sumPrice(result));

    if (
      tq.toFixed(2) === sumQuantities(result).toFixed(2) &&
      tp.toFixed(2) === sumPrice(result).toFixed(2)
    ) {
      alert("VALIDATION PASSED");
    } else {
      alert("VALIDATION FAILED");
    }

    console.log("INPUT TABLE", tableForm);

    generateExcelFile(
      tableForm,
      "Scheels" + findPO(stringArray),
      findShip(stringArray),
      findCancel(stringArray),
      Number(findPO(stringArray)),
      findStore(stringArray),
      sumQuantities(result),
      sumPrice(result)
    );
  };

  //UPLOAD TEMPLATE CSV

  const handleFileUpload2 = (e) => {
    let currentTime = new Date().toLocaleString();
    console.log(currentTime);
    const files = e.target.files;
    console.log(files);
    if (files) {
      console.log(files[0]);
      Papa.parse(files[0], {
        header: true,
        complete: function (results) {
          console.log("Finished:", results.data);
          let formArray = results.data.map((elem) => {
            return {
              ...elem,
            };
          });
          console.log(formArray);

          const inputArray = formArray.map((elem) => {
            return [
              Number(elem.upc),
              elem.sku,
              Number(elem.quant),
              Number(elem.price),
              Number(elem.total),
            ];
          });

          console.log(inputArray);

          const sumQuantities = (data) => {
            return data.reduce(
              (total, item) => total + (Number(item.quant) || 0),
              0
            );
          };

          const sumPrice = (data) => {
            return data.reduce(
              (total, item) => total + (Number(item.total) || 0),
              0
            );
          };

          generateExcelFile(
            inputArray,
            "Scheels" + formArray[0].po,
            "DATE",
            "DATE",
            Number(formArray[0].po),
            formArray[0].store,
            sumQuantities(formArray),
            sumPrice(formArray)
          );

          if (fileInputRef.current) {
            fileInputRef.current.value = "";
          }
        },
      });
    }
  };

  //Start Excel

  const generateExcelFile = async (
    data,
    invoiceNumber,
    date,
    dueDate,
    po,
    store,
    quantityTotal,
    priceTotal,
    startRow = 21,
    startCol = 2
  ) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    

    //image

    const response = await fetch(pacas);
    const imageBlob = await response.blob();
    const imageArrayBuffer = await imageBlob.arrayBuffer();

    // Add image to workbook
    const imageId = workbook.addImage({
      buffer: imageArrayBuffer,
      extension: "png",
    });

    // Place image in specific cell (B2)
    worksheet.addImage(imageId, {
      tl: { col: 1.5, row: 1.9 }, // Position at B2 (zero-based index)
      ext: { width: 200, height: 50 }, // Set image dimensions
    });

    //add table

    data.forEach((rowArray, rowIndex) => {
      // Loop through each nested array (columns)

      if (rowIndex % 2 == 0) {
        rowArray.forEach((cellValue, colIndex) => {
          worksheet.getCell(startRow + rowIndex, startCol + colIndex).value =
            cellValue;
          worksheet.getCell(startRow + rowIndex, startCol + colIndex).fill = {
            type: "pattern",
            pattern: "solid",
            fgColor: { argb: "FFA7DAFA" },
          };
          worksheet.getCell(startRow + rowIndex, startCol + colIndex).border = {
            top: { style: "thin", color: { argb: "FFA7DAFA" } }, // Red top border
            left: { style: "thin", color: { argb: "FFA7DAFA" } }, // Green left border
            bottom: { style: "thin", color: { argb: "FFA7DAFA" } }, // Blue bottom border
            right: { style: "thin", color: { argb: "FFA7DAFA" } }, // Orange right border
          };
        });
      } else {
        rowArray.forEach((cellValue, colIndex) => {
          worksheet.getCell(startRow + rowIndex, startCol + colIndex).value =
            cellValue;
          worksheet.getCell(startRow + rowIndex, startCol + colIndex).border = {
            top: { style: "thin", color: { argb: "FFA7DAFA" } }, // Red top border
            left: { style: "thin", color: { argb: "FFA7DAFA" } }, // Green left border
            bottom: { style: "thin", color: { argb: "FFA7DAFA" } }, // Blue bottom border
            right: { style: "thin", color: { argb: "FFA7DAFA" } }, // Orange right border
          };
        });
      }
    });

    const offset = data.length + 1 + startRow;

    //.numFmt = '"$"#,##0.00'

    // Set specific values in specific cells

    //B
    worksheet.getCell("B4").value = "39 CHESTNUT AVENUE";
    worksheet.getCell("B5").value = "WESTMONT, ILLINOIS 60559";
    worksheet.getCell("B7").value = "SHOP #:";
    worksheet.getCell("B8").value = "VENDOR #:";
    worksheet.getCell("B9").value = "BILL TO:";
    worksheet.getCell("B10").value = "SHIP TO:";
    worksheet.getCell("B12").value = "PAYMENT INSTRUCTIONS";
    worksheet.getCell("B13").value = "JP Morgan Chase Bank, N.A.";
    worksheet.getCell("B14").value = "Routing: 071000013";
    worksheet.getCell("B15").value = "Account Number: 726737536";
    worksheet.getCell("B16").value = "Beneficiary: Pacas, Inc.";
    worksheet.getCell("B17").value = "39 Chestnut Ave";
    worksheet.getCell("B18").value = "Westmont, IL 60559";
    worksheet.getCell("B20").value = "UPC";

    //C

    worksheet.getCell("C8").value = 20534;
    worksheet.getCell("C9").value = "1707 Gold Drive South, Fargo, ND 58103";
    worksheet.getCell("C10").value = store;
    worksheet.getCell("C20").value = "STYLE NUMBER";

    //D
    worksheet.getCell("D7").value = "INVOICE #:";
    worksheet.getCell("D8").value = "DATE:";
    worksheet.getCell("D9").value = "DUE DATE:";
    worksheet.getCell("D10").value = "PO:";
    worksheet.getCell("D11").value = "MANUAL PO:";
    worksheet.getCell("D12").value = "STORE:";
    worksheet.getCell("D20").value = "QUANTITY";
    worksheet.getCell(`D${offset}`).value = "QUANTITY TOTAL";
    worksheet.getCell(`D${offset + 1}`).value = "SUBTOTAL";
    worksheet.getCell(`D${offset + 2}`).value = "SHIPPING";
    worksheet.getCell(`D${offset + 3}`).value = "INVOICE TOTAL";

    //E
    worksheet.getCell("E2").value = "INVOICE";
    worksheet.getCell("E7").value = invoiceNumber;
    worksheet.getCell("E8").value = date;
    worksheet.getCell("E9").value = dueDate;
    worksheet.getCell("E10").value = po;
    worksheet.getCell("E12").value = store;
    worksheet.getCell("E20").value = "UNIT COST";
    worksheet.getCell(`E${offset}`).value = quantityTotal;
    worksheet.getCell(`E${offset + 1}`).value = priceTotal;
    worksheet.getCell(`E${offset + 2}`).value = " - ";
    worksheet.getCell(`E${offset + 3}`).value = priceTotal;

    //F
    worksheet.getCell("F20").value = "NET COST";

    // Adjust column widths
    worksheet.columns = [
      { key: "A", width: 10 },
      { key: "B", width: 35 },
      { key: "C", width: 45 },
      { key: "D", width: 35 },
      { key: "E", width: 35 },
      { key: "F", width: 35 },
    ];

    worksheet.getRow(2).height = 40;

    //global font
    worksheet.eachRow((row) => {
      row.eachCell((cell) => {
        cell.font = { name: "Aptos Narrow", size: 12 };
      });
    });

    //B Form
    worksheet.getCell("B7").font = { bold: true, name: "Aptos Narrow" };
    worksheet.getCell("B8").font = { bold: true, name: "Aptos Narrow" };
    worksheet.getCell("B9").font = { bold: true, name: "Aptos Narrow" };
    worksheet.getCell("B10").font = { bold: true, name: "Aptos Narrow" };
    worksheet.getCell("B12").font = { bold: true, name: "Aptos Narrow" };
    worksheet.getCell("B20").font = {
      bold: true,
      name: "Aptos Narrow",
      color: { argb: "FFFFFFFF" },
    };
    worksheet.getCell("B20").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1C76AD" },
    };
    worksheet.getCell("B20").alignment = {
      horizontal: "center",
      vertical: "center",
    };

    for (let i = 21; i < offset; i++) {
      worksheet.getCell(`B${i}`).numFmt = "0";
    }

    //C Form
    worksheet.getCell("C8").alignment = { horizontal: "left" };
    worksheet.getCell("C20").font = {
      bold: true,
      name: "Aptos Narrow",
      color: { argb: "FFFFFFFF" },
    };
    worksheet.getCell("C20").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1C76AD" },
    };
    worksheet.getCell("C20").alignment = {
      horizontal: "center",
      vertical: "center",
    };

    //D Form
    worksheet.getCell("D7").font = { bold: true, name: "Aptos Narrow" };
    worksheet.getCell("D8").font = { bold: true, name: "Aptos Narrow" };
    worksheet.getCell("D9").font = { bold: true, name: "Aptos Narrow" };
    worksheet.getCell("D10").font = { bold: true, name: "Aptos Narrow" };
    worksheet.getCell("D11").font = { bold: true, name: "Aptos Narrow" };
    worksheet.getCell("D12").font = { bold: true, name: "Aptos Narrow" };
    worksheet.getCell(`D7`).alignment = { horizontal: "right" };
    worksheet.getCell(`D8`).alignment = { horizontal: "right" };
    worksheet.getCell(`D9`).alignment = { horizontal: "right" };
    worksheet.getCell(`D10`).alignment = { horizontal: "right" };
    worksheet.getCell(`D11`).alignment = { horizontal: "right" };
    worksheet.getCell(`D12`).alignment = { horizontal: "right" };

    worksheet.getCell("D20").font = {
      bold: true,
      name: "Aptos Narrow",
      color: { argb: "FFFFFFFF" },
    };
    worksheet.getCell("D20").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1C76AD" },
    };
    worksheet.getCell("D20").alignment = {
      horizontal: "center",
      vertical: "center",
    };

    worksheet.getCell(`D${offset}`).border = { top: { style: "thin" } };
    worksheet.getCell(`D${offset}`).alignment = { horizontal: "right" };
    worksheet.getCell(`D${offset}`).font = { bold: true, name: "Aptos Narrow" };
    worksheet.getCell(`D${offset + 1}`).alignment = { horizontal: "right" };
    worksheet.getCell(`D${offset + 2}`).alignment = { horizontal: "right" };
    worksheet.getCell(`D${offset + 3}`).alignment = { horizontal: "right" };
    worksheet.getCell(`D${offset + 3}`).font = {
      bold: true,
      name: "Aptos Narrow",
    };

    //E Form
    worksheet.getCell("E2").font = {
      bold: true,
      name: "Aptos Narrow",
      size: 34,
      color: { argb: "FFBF1F24" },
    };
    worksheet.getCell("E2").alignment = {
      horizontal: "center",
      vertical: "middle",
    };
    worksheet.getCell("E10").alignment = { horizontal: "left" };

    worksheet.getCell("E20").font = {
      bold: true,
      name: "Aptos Narrow",
      color: { argb: "FFFFFFFF" },
    };
    worksheet.getCell("E20").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1C76AD" },
    };
    worksheet.getCell("E20").alignment = {
      horizontal: "center",
      vertical: "center",
    };

    worksheet.getCell(`E${offset}`).border = { top: { style: "thin" } };
    worksheet.getCell(`E${offset + 2}`).alignment = { horizontal: "right" };
    worksheet.getCell(`E${offset + 1}`).numFmt = '"$"#,##0.00';
    worksheet.getCell(`E${offset + 3}`).numFmt = '"$"#,##0.00';

    for (let i = 21; i < offset; i++) {
      worksheet.getCell(`E${i}`).numFmt = '"$"#,##0.00';
    }

    //F Form
    worksheet.getCell("F20").font = {
      bold: true,
      name: "Aptos Narrow",
      color: { argb: "FFFFFFFF" },
    };
    worksheet.getCell("F20").fill = {
      type: "pattern",
      pattern: "solid",
      fgColor: { argb: "FF1C76AD" },
    };
    worksheet.getCell("F20").alignment = {
      horizontal: "center",
      vertical: "center",
    };

    for (let i = 21; i < offset; i++) {
      worksheet.getCell(`F${i}`).numFmt = '"$"#,##0.00';
    }

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `SCHEELS_INVOICE_${po}.xlsx`);
    });

    //   workbook.xlsx.writeBuffer().then((buffer) => {

    //   workbook.xlsx.load(buffer).then(() => {
    //     const worksheet = workbook.getWorksheet(1);

    //     // 2. Convert each row & cell to HTML
    //     let html = '<table style="border-collapse: collapse;">';
    //     worksheet.eachRow({ includeEmpty: true }, (row) => {
    //       html += '<tr>';
    //       row.eachCell({ includeEmpty: true }, (cell) => {
    //         // Here you'd retrieve background color, font info, etc. from cell.style
    //         // and convert to inline CSS. This is just a simple illustration:
    //         const bgColor = cell.style.fill?.fgColor?.argb ? `#${cell.style.fill.fgColor.argb.substring(2)}` : '#ffffff';
    //         html += `<td style="border:1px solid #000; background-color:${bgColor};">${cell.value || ''}</td>`;
    //       });
    //       html += '</tr>';
    //     });
    //     html += '</table>';

    //     // 3. Insert HTML into a hidden div
    //     const hiddenDiv = document.createElement('div');
    //     hiddenDiv.style.position = 'fixed';
    //     hiddenDiv.style.top = '-9999px';
    //     hiddenDiv.innerHTML = html;
    //     document.body.appendChild(hiddenDiv);

    //     // 4. Use html2canvas or dom-to-image to convert the HTML to a canvas
    //     html2canvas(hiddenDiv).then((canvas) => {
    //       // 5. Convert canvas to Data URL or Blob
    //       const imgData = canvas.toDataURL('image/png');

    //       // 6. Download or display the image
    //       const link = document.createElement('a');
    //       link.download = 'Spreadsheet.png';
    //       link.href = imgData;
    //       link.click();

    //       // Remove the hidden div after export
    //       document.body.removeChild(hiddenDiv);
    //     });
    //   });

    // });

    // workbook.xlsx.writeBuffer().then((buffer) => {
    //   const blob = new Blob([buffer], {
    //     type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    //   });

    //   const reader = new FileReader();
    //   reader.onload = function (e) {
    //     const data = new Uint8Array(e.target.result);
    //     const workbook = XLSX.read(data, { type: "array" });

    //     const sheetName = workbook.SheetNames[0];
    //     const worksheet = workbook.Sheets[sheetName];

    //     // Convert the sheet to HTML while keeping styles
    //     const htmlTable = XLSX.utils.sheet_to_html(worksheet);

    //     // Create a hidden div to render the HTML table
    //     const hiddenDiv = document.createElement("div");
    //     hiddenDiv.innerHTML = htmlTable;
    //     hiddenDiv.style.position = "absolute";
    //     hiddenDiv.style.left = "-9999px"; // Hide off-screen
    //     document.body.appendChild(hiddenDiv);

    //     // Use html2canvas to capture the styled table as an image
    //     html2canvas(hiddenDiv, { scale: 2 }).then((canvas) => {
    //       const pdf = new jsPDF("p", "mm", "a4");
    //       const imgData = canvas.toDataURL("image/png");

    //       // Adjust image size to fit the PDF
    //       const imgWidth = 210; // A4 width
    //       const imgHeight = (canvas.height * imgWidth) / canvas.width; // Maintain aspect ratio

    //       pdf.addImage(imgData, "PNG", 0, 10, imgWidth, imgHeight);
    //       pdf.save(`SCHEELS_INVOICE_${po}.pdf`);

    //       // Cleanup
    //       document.body.removeChild(hiddenDiv);
    //     });
    //   };

    //   reader.readAsArrayBuffer(blob);
    // });
  };

  const handleGenerate = () => {
    generateExcelFile(tableData);
  };

  const handleTemplate = () => {
    const headers = ["upc", "sku", "quant", "price", "total", "po", "store"];

    // Sample data (replace with actual data as needed)

    // Convert to CSV format
    let csvContent = headers.join(",") + "\n"; // Add headers

    // Create a Blob and generate a downloadable link
    const blob = new Blob([csvContent], { type: "text/csv" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "invoices_template.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ marginLeft: "7vw", display: "flex" }}>
      <Button
        style={{
          height: "15vh",
          width: "15vw",
          margin: "15vw",
          marginLeft: "15vw",
        }}
        component="label"
        variant="contained"
      >
        Generate Invoice From PDF
        <VisuallyHiddenInput
          ref={fileInputRef}
          onChange={handleFileUpload}
          type="file"
          accept=".pdf"
        />
      </Button>

      <div
  style={{
    margin: "15vw",
    marginLeft: "2vw",
    display: "flex",
    flexDirection: "column",
    alignItems: "center", // Centers both horizontally
    justifyContent: "center", // Centers vertically
    height: "20vh", // Adjust height if needed
  }}
>
  <Button
    style={{ height: "15vh", width: "15vw" }}
    component="label"
    variant="contained"
  >
    Generate Invoice From Template
    <VisuallyHiddenInput
      ref={fileInputRef}
      onChange={handleFileUpload2}
      type="file"
      accept=".csv"
    />
  </Button>
  <h6
    onClick={handleTemplate}
    style={{
      color: "white",
      textDecoration: "underline",
      cursor: "pointer",
      marginTop: "10px", // Adds spacing between button and h6
    }}
  >
    Download Template
  </h6>
</div>

    </div>
  );
}
