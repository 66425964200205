
import React, { useState, useEffect }  from "react"
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import TextField from '@mui/material/TextField';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ToggleButton, ToggleButtonGroup } from '@mui/material';

import BarChartIcon from '@mui/icons-material/BarChart';
import PieChartIcon from '@mui/icons-material/PieChart';
import TimelineIcon from '@mui/icons-material/Timeline';

import axios from "axios"

import { Pie } from "@visx/shape";
import { Group } from "@visx/group";
import { useTooltip, TooltipWithBounds } from "@visx/tooltip";
import { Bar } from "@visx/shape";
import { scaleBand, scaleLinear, scaleTime } from "@visx/scale";
import { curveMonotoneX } from "@visx/curve";
import { LinePath } from "@visx/shape";
import { AxisLeft } from "@visx/axis";





export default function AIDisplay( { changeView, query, view } ) {
    const [displayString, setDisplayString] = useState("")
    const [load, setLoad] = useState(true)
    const [loadString, setLoadString] = useState("")
    const [responseString, setResponseString] = useState("")
    const [displayState, setDisplayState] = useState(0)
    const [responseData, setResponseData] = useState([])
    const [responseCode, setResponseCode] = useState("")
    


    const [activeSlice, setActiveSlice] = useState(null);
    const [activeBar, setActiveBar] = useState(null);
    const [activePoint, setActivePoint] = useState(null);

    const [alignment, setAlignment] = useState('bar');
    
    const { showTooltip, hideTooltip, tooltipData, tooltipTop, tooltipLeft } =
      useTooltip();



    const handleDisplayString = () => {
        const queryArray = query.split("")

        queryArray.forEach((elem, i) => {
            setTimeout(() => {
                setDisplayString((prev) => prev + elem)

            }, 35 * i)
           
        })



    }

    useEffect(() => {
        handleDisplayString()
        handleLoadString()
        sendData()
    }, [query])

    const loadArray = [
        "Thinking...",
        "Gathering Data...",
        "Putting It All Together..."

    ]

    const handleLoadString = () => {

        loadArray.forEach((elem, i) => {

            setTimeout(() => {
                setLoadString(elem)

            }, 600 * i)
        })

   


    }


    const sendData = async () => {
      if (query !== "") {
      let response = await axios.post("/api/openai/create" ,
      {
        query: query
      })
      setLoad(false)

      console.log(response.data)

          if (response.data.responseType === "SQLL1" || response.data.responseType === "SQLL1" ) {

            setResponseCode(response.data.responseType)


          let data = response.data.data.shopifyqlQuery.tableData

          let dataForm = data.rowData.map((elem) => {

            let rowForm = elem.map((elm, i) => {
                  return (
                      {[data.columns[i].name]: elm}
                  )
              })

              let objForm =  Object.assign({}, ...rowForm)


              return (
                  objForm
              )


          })


          console.log(dataForm)

          setResponseData(dataForm)
          setDisplayState(1)
        }
         else if  (response.data.responseType === "OOS") {

          setResponseCode(response.data.responseType)


          const queryArray = response.data.data.split("")

          queryArray.forEach((elem, i) => {
              setTimeout(() => {
                  setResponseString((prev) => prev + elem)
  
              }, 10 * i)
             
          })

        
          setDisplayState(2)
         }

         else if  (response.data.responseType === "SINGLEL1" || response.data.responseType === "SINGLEL2") {


          console.log(response.data)

          setResponseCode(response.data.responseType)


          const queryArray = response.data.data.content.split("")

          queryArray.forEach((elem, i) => {
              setTimeout(() => {
                  setResponseString((prev) => prev + elem)
  
              }, 10 * i)
             
          })

        
          setDisplayState(2)
         }

         else {
          setResponseCode(response.data.responseType)
            changeView(2)

         }
   
    }
    }

    //MUI TABLE
    const columns = responseData.length > 0 ? Object.keys(responseData[0]) : [];


    const MuiTable = () => (

      <TableContainer component={Paper} style={{backgroundColor:"black", color:"white", maxHeight: '50vh', minHeight: "50vh", minWidth:"30vw", maxWidth:"30vw", overflow: 'auto' }}>
      <Table stickyHeader aria-label="simple table">
          <TableHead>
              <TableRow>
                  {columns.map(column => (
                      <TableCell sx={{color:"white", backgroundColor:"black",  borderColor: "white"}} key={column}>
                          <TableSortLabel
                         
                          >
                              {column}
                          </TableSortLabel>
                      </TableCell>
                  ))}
              </TableRow>
          </TableHead>
          <TableBody>
              {responseData.map((row, index) => (
                  <TableRow key={index}>
                      {columns.map(column => (
                          <TableCell key={column} 
                          sx={{
                            backgroundColor: "black", // Black background for rows
                            color: "white", // White text for rows
                            borderColor: "white" // Optional: white border for cells
                          }}>
                            {row[column]}
                            </TableCell>
                      ))}
                  </TableRow>
              ))}
          </TableBody>
      </Table>
  </TableContainer>
    )


    //Display Table

    let headers = []

    if (responseData.length > 0) {
      headers = Object.keys(responseData[0])
    }




    const DisplayTable = () => (
      <>

      <table style={{color:"white"}} >
        <thead>
          <tr>
            {headers.map((header) => (
              <th key={header}>{header}</th>
            ))}
       
          </tr>
        </thead>
        <tbody>
          {responseData.map((row, index) => (
            <tr key={index}>
              {headers.map((header) => (
                <td key={header}>{row[header]}</td>
              ))}
        
            </tr>
          ))}
        </tbody>
      </table>
      
      </>
    );



    //VISX TEST

    //Data form
    let data = [


    ]

    if (responseData.length > 0 && Object.keys(responseData[0]).length >= 2 ) {

      const dataKeys = Object.keys(responseData[0])

      // console.log(dataKeys)

      responseData.forEach((elem) => {
          const dataIndex = {label: elem[dataKeys[0]], value: Number(elem[dataKeys[1]].toString().replace(/[$,%]/g, ''))}
          console.log(dataIndex)

          if (Number(elem[dataKeys[1]].toString().replace(/[$,%]/g, '')) > 0) {
          data.push(dataIndex)
          }
      })
    }

    // console.log(data)



    // const data = [
    //   { label: "Apples", value: 40 },
    //   { label: "Bananas", value: 20 },
    //   { label: "Cherries", value: 10 },
    //   { label: "Grapes", value: 30 }
    // ];

   


    
    const width = 400;
    const height = 400;
    const radius = Math.min(width, height) / 2;

    const getColor = (index) => {
      const colors = [
        "#FF5733", "#33FF57", "#3357FF", "#FF33A1", "#33FFF1",
        "#FF5733", "#FFC300", "#DAF7A6", "#900C3F", "#581845",
        "#28B463", "#3498DB", "#9B59B6", "#F39C12", "#1ABC9C",
        "#2ECC71", "#E74C3C", "#D35400", "#7D3C98", "#2980B9",
        "#8E44AD", "#C0392B", "#16A085", "#F4D03F", "#2C3E50",
        "#27AE60", "#E67E22", "#AF7AC5", "#5DADE2", "#48C9B0",
        "#A569BD", "#F1948A", "#F7DC6F", "#7FB3D5", "#F0B27A",
        "#B03A2E", "#2E86C1", "#F5B041", "#117864", "#82E0AA",
        "#34495E", "#ABB2B9", "#DC7633", "#F9E79F", "#C70039",
        "#581845", "#900C3F", "#00FF7F", "#FF1493", "#40E0D0"
      ];
      return colors[index % colors.length];
    };


    //PIE CHART


    const PieChart = () => 

    
       (
        <div>
            <svg
              width={width}
              height={height}
              onMouseLeave={() => {
                setActiveSlice(null);
                hideTooltip();
              }}
            >
            <Group top={height / 2} left={width / 2}>
              <Pie
                data={data}
                pieValue={(d) => d.value}
                outerRadius={radius}
                padAngle={0.02}
              >
                {(pie) => {
                  return pie.arcs.map((arc, index) => {
                    const [centroidX, centroidY] = pie.path.centroid(arc);
    
                    return (
                      <g
                      key={`arc-${arc.data.label}-${index}`}
                      onMouseEnter={(event) => {
                        const { pageX, pageY } = event;
                        setActiveSlice(arc);
                        showTooltip({
                          tooltipData: arc.data.label,
                          tooltipTop: pageY - 10,
                          tooltipLeft: pageX + 10,
                        });
                      }}
                      onMouseLeave={() => {
                        console.log("leave")
                        setActiveSlice(null);
                        hideTooltip();
                      }}
                    >
                      <path d={pie.path(arc)} fill={getColor(index)} />
                    </g>
    
                    );
                  });
                }}
              </Pie>
            </Group>
          </svg>
    
          {/* Tooltip */}
          {tooltipData && (
            <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
              {tooltipData}
            </TooltipWithBounds>
          )}
        </div>
      );
    


    //Bar Chart

    const barWidth = 600;
    const barHeight = 400;

const margin = { top: 20, bottom: 5, left: 50, right: 20 }


const xMax = barWidth - margin.left - margin.right;
const yMax = barHeight - margin.top - margin.bottom;

const xScale = scaleBand({
  range: [0, xMax],
  domain: data.map((d) => d.label),
  padding: 0.2
});

const yScale = scaleLinear({
  range: [yMax, 0],
  domain: [0, Math.max(...data.map((d) => typeof d.value === 'number' ? d.value : 0))]
});


    const BarChart = () => (
     
        <div>
          <svg width={barWidth} height={barHeight}>
            <Group top={margin.top} left={margin.left}>
              {data.map((d, index) => {
                const barWidth2 = xScale.bandwidth();
                const barHeight2 = yMax - yScale(typeof d.value === 'number' ? d.value : 0);
                const barX = xScale(d.label);
                const barY = yMax - barHeight2;
    
                return (
                  <Group key={`bar-${index}`}>
                    <Bar
                      x={barX}
                      y={barY}
                      width={barWidth2}
                      height={barHeight2}
                      fill={getColor(index)}
                      onMouseEnter={(event) => {
                        const { pageX, pageY } = event;
                        setActiveBar(d);
                        showTooltip({
                          tooltipData: `${d.label}: ${d.value}`,
                          tooltipTop: pageY - 10,
                          tooltipLeft: pageX + 10
                        });
                      }}
                      onMouseLeave={() => {
                        setActiveBar(null);
                        hideTooltip();
                      }}
                    />
                  </Group>
                );
              })}
            </Group>
          </svg>
    
          {/* Tooltip */}
          {tooltipData && (
            <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
              {tooltipData}
            </TooltipWithBounds>
          )}
        </div>
      );


    //LineChart

  




    const LineChart = () => 

    
 (
        <div>
          <svg width={barWidth} height={barHeight}>
            <Group left={margin.left} top={margin.top}>
                <AxisLeft
                scale={yScale}
                left={0}
                label="Value"
                stroke="#FFF"
                tickStroke="#FFF"
                tickLabelProps={() => ({
                  fill: "#FFF",
                  fontSize: 11,
                  textAnchor: 'end',
                  dx: '-0.25em',
                  dy: '0.25em',
                })}
              />
              <LinePath
                data={data}
                x={(d) => xScale(d.label)}
                y={(d) => yScale(d.value)}
                stroke={getColor(0)}
                strokeWidth={2}
                curve={curveMonotoneX} // smooth curve
              />
    
              {data.map((d, index) => (
                <circle
                  key={`point-${index}`}
                  cx={xScale(d.label)}
                  cy={yScale(d.value)}
                  r={4}
                  fill={getColor(index)}
                  onMouseEnter={(event) => {
                    const { pageX, pageY } = event;
                    setActivePoint(d);
                    showTooltip({
                      tooltipData: `Value: ${d.value}`,
                      tooltipTop: pageY - 150,
                      tooltipLeft: pageX - 100
                    });
                  }}
                  onMouseLeave={() => {
                    setActivePoint(null);
                    hideTooltip();
                  }}
                />
              ))}
            </Group>
          </svg>
    
          {/* Tooltip */}
          {tooltipData && (
            <TooltipWithBounds top={tooltipTop} left={tooltipLeft}>
              {tooltipData}
            </TooltipWithBounds>
          )}
        </div>
      );


      //Swicth

      const handleAlignment = (event, newAlignment) => {
        if (newAlignment !== null) {
          setAlignment(newAlignment);
        }
      };
  
    


  




 
if (view === 1) {

    return (
      
        <Box
          component="form"
          noValidate
          autoComplete="off"
          style={{
            backgroundColor: "black",
            borderRadius: "15px",
            marginTop:"3vh",
            marginRight: "1vw",
            marginBottom: "0vw",
            height: "94vh",
            position: "relative", // Use relative positioning for centering the inner box
          }}
        >
          {/* Top-left section for Button and h3 */}
          <Box
            style={{
              display: "flex",
              padding: "1rem",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10, // Ensure the button is above other content
            }}
          >
            <Button onClick={() => changeView(0)}>
              <ArrowBackRoundedIcon />
            </Button>

       

              {/* Main View */}

            
            <div>
              <h3 style={{ color: "white" }}>{displayString}</h3>
            </div>
          </Box>
          <Box
            style={{
              position: "absolute",
              top: "-1rem", // Adjust the distance from the top
              right: "1rem", // Adjust the distance from the right
              zIndex: 10,
            }}
          >
            <h6 style={{ color: "white" }}>Response Code: {responseCode}</h6>
          </Box>
      
          {/* Centered h6 content */}
          {load ? (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                position: "absolute", // Absolute positioning within the parent container
                top: 0,
                left: 0,
              }}
            >
              <h6 style={{ color: "white" }}>{loadString}</h6>
            </Box>
          ) : (
            <Box
              style={{
                display: "flex",
                // justifyContent: "center",
                alignItems: "center",
                height: "100%",
                width: "100%",
                position: "absolute", // Absolute positioning within the parent container
                top: 0,
                left: 0,
              }}
            >
              {displayState === 1 ? (
              <Box style={{margin:"2vw", marginTop:"15vw", display:"flex"}}>

                <MuiTable />

                <ToggleButtonGroup
                  value={alignment}
                  exclusive
                  orientation="vertical"
                  onChange={handleAlignment}
                  aria-label="three-way switch"
                  sx={{
                    marginLeft:"3vw",
                    color:"white",
                    backgroundColor:"black",
                    height:"auto"

                  }}
                >
                  <ToggleButton sx={{color:"white"}} value="bar" >
                    <BarChartIcon sx={{ color: 'white' }} />
                  </ToggleButton>
                  <ToggleButton sx={{color:"white"}} value="line" >
                    <TimelineIcon sx={{ color: 'white' }} />
                  </ToggleButton>
                  <ToggleButton sx={{color:"white", }} value="pie" >
                    <PieChartIcon sx={{ color: 'white' }} />
                  </ToggleButton>
                
                </ToggleButtonGroup>

                <Box style={{marginLeft:"0vw"}}>
                  {alignment === "bar" && <BarChart />}
                  {alignment === "pie" && <PieChart />}
                  {alignment === "line" && <LineChart />}

               
          
                </Box>
              </Box>
              ) : displayState === 2 ? (
                <h3 style={{ color: "white", textAlign:"left", margin:"2vw" }}>{responseString}</h3>
              ) : null}
            </Box>
          )}
        </Box>
      );

    }

    else if (view === 2) {


      return (
      
        <Box
          component="form"
          noValidate
          autoComplete="off"
          style={{
            backgroundColor: "black",
            borderRadius: "15px",
            marginRight: "3vw",
            marginBottom: "2vw",
            height: "82vh",
            position: "relative", // Use relative positioning for centering the inner box
          }}
        >
          {/* Top-left section for Button and h3 */}
          <Box
            style={{
              display: "flex",
              padding: "1rem",
              position: "absolute",
              top: 0,
              left: 0,
              zIndex: 10, // Ensure the button is above other content
            }}
          >
            <Button onClick={() => changeView(0)}>
              <ArrowBackRoundedIcon />
            </Button>

       

              {/* Main View */}

            
            <div>
              <h1 style={{ color: "white" }}>Help</h1>
            </div>

            <div>
              <h6 style={{ color: "white", marginLeft:"60vw" }}>Response Code: {responseCode} </h6>
            </div>
          </Box>
          <Box
              style={{
                display: "flex",
                justifyContent: "left",
                // alignItems: "center",
                marginTop: "15vh",
                marginLeft:"2vw",
                height: "100%",
                width: "100%",
                position: "absolute", // Absolute positioning within the parent container
                top: 0,
                left: 0,
              }}
            >
         
              <div>
       
             <h4 style={{ color: "white" }}>Dataset Reference: 
                <a style={{ color: "white", marginRight:"10px" }} target="_blank" href={"https://shopify.dev/docs/api/shopifyql/datasets/orders-dataset"}> Orders Dataset</a>
                <a style={{ color: "white" }} target="_blank" href={"https://shopify.dev/docs/api/shopifyql/datasets/products-dataset"}>Product Dataset</a>
            </h4>

            <h4 style={{ color: "white", textDecoration: "underline" }}>Response Code: OOS
            </h4>
            <h5 style={{ color: "white",  }}>
                Request Out of Scope, Make sure the query is relelvaent to shopify analytics and a valid request of ShopQL
              </h5>


            <h4 style={{ color: "white", textDecoration: "underline"  }}>Response Code: SQLL1</h4>
            <h5 style={{ color: "white" }}>
              Sucessful query on the first look
            </h5>

            <h4 style={{ color: "white", textDecoration: "underline"  }}>Response Code: SQLL2</h4>
            <h5 style={{ color: "white" }}>
              Sucessful query on the Second look
            </h5>


            <h4 style={{ color: "white", textDecoration: "underline"  }}>Response Code: ERRORL2
              </h4>
              <h5 style={{ color: "white" }}>
                General Error on both looks
              </h5>
          
          

            </div>

          </Box>

           
      
       
        </Box>
      );





    }
      
      
}