import React, { useState, useEffect } from "react";
import axios, { all } from "axios";

import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import Papa from "papaparse";

//MUI
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import SingleShopCard from "../subViews/SingleShopCard";
import { Filter1 } from "@mui/icons-material";

export default function SingleShop() {
  const [cat, setCat] = useState("Women's");
  const [style, setStyle] = useState("Crew");
  const [allData, setAllData] = useState([]);
  const [filteredData, setFilterData] = useState([]);
  const [filteredData2, setFilterData2] = useState([]);
  const [stockChecked, setStockChecked] = useState(false);
  const [size1, setSize1] = useState(0);
  const [size2, setSize2] = useState(0);
  const [reserve1, setReserve1] = useState(0);
  const [reserve2, setReserve2] = useState(0);
  const [load, setLoad] = useState(true);
  const [uploadData, setUploadData] = useState([]);
  const [reserves, setReserves] = useState([]);

  const handleCat = (event) => {
    setCat(event.target.value);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  const handleStyle = (event) => {
    setStyle(event.target.value);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const handleStockSwitch = (event) => {
    setStockChecked(event.target.checked);
  };

  const getSingleVariants = async () => {
    setLoad(true);

    const response = await axios.post("/api/shopify/getsingleshop");

    const reserveData = await getReserveData()

    setReserves(reserveData)



    // console.log(response.data)

    const allVariants = response.data.products
      .map((elem) => elem.variants)
      .flat();

    const allImages = response.data.products.map((elem) => elem.images).flat();

    // console.log(allImages)

    const getTypes = (prodId) => {
      let cat = "";
      let style = "";

      if (prodId === 14674030264690) {
        cat = "Women's";
        style = "Hiking Crew";
      }

      if (prodId === 14674026234226) {
        cat = "Men's";
        style = "Low Cut";
      }

      if (prodId === 14674023907698) {
        cat = "Men's";
        style = "Crew";
      }

      if (prodId === 14674022203762) {
        cat = "Women's";
        style = "Slouchy";
      }

      if (prodId === 14674020303218) {
        cat = "Men's";
        style = "Compression";
      }

      if (prodId === 14674017943922) {
        cat = "Women's";
        style = "Light Weight Crew";
      }

      if (prodId === 14674014798194) {
        cat = "Women's";
        style = "Compression";
      }

      if (prodId === 14674011423090) {
        cat = "Kids'";
        style = "Crew";
      }

      if (prodId === 14674010505586) {
        cat = "Women's";
        style = "Low Cut";
      }

      if (prodId === 14674007064946) {
        cat = "Women's";
        style = "Performance";
      }

      if (prodId === 14674005688690) {
        cat = "Women's";
        style = "Golf Socks";
      }

      if (prodId === 14673999233394) {
        cat = "Women's";
        style = "Crew";
      }

      if (prodId === 14673999135090) {
        cat = "Women's";
        style = "No Show";
      }

      if (prodId === 14673989370226) {
        cat = "Men's";
        style = "Light Weight Crew";
      }

      if (prodId === 14673984586098) {
        cat = "Men's";
        style = "Performance";
      }

      return {
        cat: cat,
        style: style,
      };
    };



    const combinedArray = allVariants.map((elem) => {
      const foundArray = allImages.find((ellm) => ellm.id === elem.image_id);

      const foundArray2 = reserveData.find((elm) => elm.sku === elem.sku )

      const details = getTypes(elem.product_id);

      return {
        vID: elem.id,
        sku: elem.sku,
        quantity: elem.inventory_quantity,
        pID: elem.product_id,
        vId: elem.id,
        iiID: elem.inventory_item_id,
        src: foundArray ? foundArray.src : "",
        cat: details.cat,
        style: details.style,
        reserve: (foundArray2) ? foundArray2.reserve : 0
      };
    });

    console.log(combinedArray);

    setAllData(combinedArray);


    let data = [];

    if (stockChecked) {
      data = combinedArray.filter((elem) => elem.quantity > 0);
    } else {
      data = combinedArray;
    }

    const filterArray = combinedArray.filter(
      (elem) => elem.style === style && elem.cat === cat
    );

    const filt1 = filterArray.filter((elem) => Number(elem.sku) % 2 === 0);
    const filt2 = filterArray.filter((elem) => Number(elem.sku) % 2 === 1);

    setFilterData(filt1);
    setFilterData2(filt2);

    setSize1(filt1.filter((elem) => elem.quantity > 0).length);
    setSize2(filt2.filter((elem) => elem.quantity > 0).length);
    setReserve1(filt1.filter((elem) => elem.reserve > 0).length);
    setReserve2(filt2.filter((elem) => elem.reserve > 0).length);

    setLoad(false);
  };


  const getReserveData = async () => {

    const response = await axios.post("/api/reserve/getall")


    console.log(response.data)

    return (response.data)
  }

  const handleFileUpload = (e) => {
    let currentTime = new Date().toLocaleString();
    console.log(currentTime);
    const files = e.target.files;
    console.log(files);
    if (files) {
      console.log(files[0]);
      Papa.parse(files[0], {
        header: true,
        complete: function (results) {
          console.log("Finished:", results.data);
          let formArray = results.data.map((elem) => {
            return {
              sku: elem.sku,
              reserve: Number(elem.reserve),
            };
          });
          console.log(formArray);

          setUploadData(formArray);
        },
      });
    }
  };

  const handleUpdate = async () => {
    console.log("fire");
    if (uploadData.length === 0) {
      alert("Upload file")

      return
    }
    const response = await axios.post("/api/reserve/upload", uploadData);

    if (response.status === 200) {
      alert("DB Update Successful")
    } 
    else {
      alert("Error Updating DB")
    }

    console.log(response);
  };


  const handleCreationCheck = async () => {
    const response = await axios.post("/api/reserve/getall")

    const mappedArray = response.data.map((elem) => {

        const foundData = allData.find((ellm) => ellm.sku === elem.sku)

        return (
            {
                sku: elem.sku,
                created: (foundData) ? "true" : "false",
             
            }
        )
    }).filter((ellm) => ellm.created === "false")


    generateExcelFile(mappedArray, "CREATION_CHECK")
    

    

  }

  const deadCheck = () => {
    const skuArray = allData.filter(
      (elem) =>
        elem.quantity <= 0 && elem.reserve <= 0 && elem.pID !== 14674011423090
    );

    const deadSkus = skuArray.map((elem) => {
      let counter = "";

      if (Number(elem.sku) % 2 === 0) {
        counter = (Number(elem.sku) + 1).toString();
      } else {
        counter = (Number(elem.sku) - 1).toString();
      }

      const foundData = allData.find((ellm) => ellm.sku === counter);

      return {
        sku: elem.sku,
        counter: counter,
        counter_quant: foundData ? foundData.quantity : 0,
        counter_reserve: foundData ? foundData.reserve : 0,
        pID: elem.pID,
        vId: elem.id,
      };
    });

    const deadArray = deadSkus.filter(
      (elem) => elem.counter_quant <= 0 && elem.counter_reserve <= 0
    );

    return deadArray;
  };

  const balanceCheck = () => {
    const sizedVariants = allData.filter((elem) => elem.pID !== 14674011423090);

    const dataCheck = sizedVariants.map((elem) => {
      let counter = "";

      if (Number(elem.sku) % 2 === 0) {
        counter = (Number(elem.sku) + 1).toString();
      } else {
        counter = (Number(elem.sku) - 1).toString();
      }

      const foundData = sizedVariants.find((ellm) => ellm.sku === counter);

      return {
        sku: elem.sku,
        counter: counter,
        has_counter: foundData ? "true" : "false",
        pID: elem.pID,
      };
    });

    return dataCheck.filter((elem) => elem.has_counter === "false");
  };


  const handleExport = () => {
    generateExcelFile(reserves, "RESERVE_EXPORT")


  }

  const generateExcelFile = async (input, filename) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Sheet 1");

    const data = input;

    console.log(data)

    // Add column headers
    const headers = Object.keys(data[0]);
    worksheet.addRow(headers);

    // Add data rows
    data.forEach((item) => {
      const values = Object.values(item);
      worksheet.addRow(values);
    });

    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      saveAs(blob, `${filename}.xlsx`);
    });
  };

  const handleLog = () => {
    console.log(filteredData);
  };


 
  const handleDeadCheck = () => {
    generateExcelFile(deadCheck(), "DEAD_SKUS");
  };

  const handleBalanceCheck = () => {
    generateExcelFile(balanceCheck(), "UNBALANCED_SKUS");
  };

  useEffect(() => {
    let data = [];

    if (stockChecked) {
      data = allData.filter((elem) => elem.quantity > 0);
    } else {
      data = allData;
    }

    const filterArray = data.filter(
      (elem) => elem.style === style && elem.cat === cat
    );

    const filt1 = filterArray.filter((elem) => Number(elem.sku) % 2 === 0);
    const filt2 = filterArray.filter((elem) => Number(elem.sku) % 2 === 1);

    setFilterData(filt1);
    setFilterData2(filt2);

    setSize1(filt1.filter((elem) => elem.quantity > 0).length);
    setSize2(filt2.filter((elem) => elem.quantity > 0).length);
    setReserve1(filt1.filter((elem) => elem.reserve > 0).length);
    setReserve2(filt2.filter((elem) => elem.reserve > 0).length);
  }, [cat, style, stockChecked]);

  useEffect(() => {
    getSingleVariants();

  }, []);

  const singleDisplay = filteredData.map((elem) => {
    return (
      <Grid item xs={4} sm={4} md={4} key={elem.id}>
        <SingleShopCard
          src={elem.src}
          sku={elem.sku}
          pID={elem.pID}
          quantity={elem.quantity}
          reserve={elem.reserve}
          vID={elem.vID}
          iiID={elem.iiID}
          getSingleVariants={getSingleVariants}
        />
      </Grid>
    );
  });

  const singleDisplay2 = filteredData2.map((elem) => {
    return (
      <Grid item xs={4} sm={4} md={4} key={elem.id}>
        <SingleShopCard
          src={elem.src}
          sku={elem.sku}
          pID={elem.pID}
          quantity={elem.quantity}
          reserve={elem.reserve}
          vID={elem.vID}
          iiID={elem.iiID}
          getSingleVariants={getSingleVariants}
        />
      </Grid>
    );
  });

  if (load) {
    return (
            <div style={{ marginLeft: "41vw", marginTop: "32vh" }}>
                <CircularProgress color="inherit" size={150} />
              </div>
    )
  } else {
    return (
      <div style={{ marginLeft: "6vw", marginTop: "2vh" }}>
        <div style={{ display: "flex" }}>
          <Box sx={{ minWidth: 150, maxWidth: 150, marginRight: "3vw" }}>
            <FormControl fullWidth>
              <InputLabel sx={{ color: "white" }} id="demo-simple-select-label">
                Category
              </InputLabel>
              <Select
                sx={{
                  color: "white",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  ".MuiSvgIcon-root": {
                    color: "white",
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={cat}
                label="Category"
                onChange={handleCat}
              >
                <MenuItem value={"Women's"}>Women's</MenuItem>
                <MenuItem value={"Men's"}>Men's</MenuItem>
                <MenuItem value={"Kids'"}>Kids'</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <Box sx={{ minWidth: 150, maxWidth: 150 }}>
            <FormControl fullWidth>
              <InputLabel sx={{ color: "white" }} id="demo-simple-select-label">
                Style
              </InputLabel>
              <Select
                sx={{
                  color: "white",
                  ".MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  "&:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "white",
                  },
                  ".MuiSvgIcon-root": {
                    color: "white",
                  },
                }}
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={style}
                label="Style"
                onChange={handleStyle}
              >
                <MenuItem value={"Crew"}>Crew</MenuItem>
                <MenuItem value={"Light Weight Crew"}>
                  Light Weight Crew
                </MenuItem>
                <MenuItem value={"Compression"}>Compression</MenuItem>
                <MenuItem value={"Low Cut"}>Low Cut</MenuItem>
                <MenuItem value={"No Show"}>No Show</MenuItem>
                <MenuItem value={"Golf Socks"}>Golf Socks</MenuItem>
                <MenuItem value={"Performance"}>Performance</MenuItem>
                <MenuItem value={"Slouchy"}>Slouchy</MenuItem>

                <MenuItem value={"Hiking Crew"}>Hiking Crew</MenuItem>
              </Select>
            </FormControl>
          </Box>

          <FormGroup>
            <FormControlLabel
              sx={{ color: "white" }}
              label="In Stock Only"
              control={
                <Switch
                  checked={stockChecked}
                  onChange={handleStockSwitch}
                  inputProps={{ "aria-label": "controlled" }}
                  labelPlacement="end"
                />
              }
            />
          </FormGroup>

          {/* <button onClick={getSingleVariants}>GET</button>
        <button onClick={handleLog}>LOG</button> */}
          <button onClick={handleBalanceCheck}>Balance Check</button>
          <button onClick={handleDeadCheck}>Dead Check</button>
          <button onClick={handleCreationCheck}>Creation Check</button>
          <input onChange={handleFileUpload} type="file" accept=".csv"></input>
          <button onClick={handleUpdate}>Update</button>
          <button onClick={handleExport}>Export Reserves</button>
        </div>

        <div style={{ overflowY: "scroll", height: "92vh", display: "flex" }}>
          <div>
            <h5 style={{color:"white"}}>Size One Active: {size1}</h5>
            <h5 style={{color:"white"}}>Size One Reserve: {reserve1}</h5>
            

            <Grid container spacing={1}>
              {singleDisplay}
            </Grid>
          </div>

          <div>
          <h5 style={{color:"white"}}>Size Two Active: {size2}</h5>
          <h5 style={{color:"white"}}>Size Two Reserve: {reserve2}</h5>

            <Grid container spacing={1}>
              {singleDisplay2}
            </Grid>
          </div>
        </div>
      </div>
    );
  }
}
