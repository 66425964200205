
import React, { useState, useEffect }  from "react"
import Dashboard from "../subViews/Dashboard"
import PacasAI from "../subViews/PacasAI"
import AIDisplay from "../subViews/AIDisplay"









export default function Home() {
    const [view, setView] = useState(0)
    const [query, setQuery] = useState("")


    const changeView = (view) => {
        setView(view)
    }

    const handleQuery = (query) => {
        setQuery(query)
    }






    return (
        <div style={{ marginLeft: "6vw" }}>

    {view === 0 
    ?
    <>
    <Dashboard />
    <PacasAI changeView={changeView} handleQuery={handleQuery} />
    </>
    :
    <AIDisplay view={view} changeView={changeView} query={query} />
    
    
    
    
    }

  
        </div>

    )
}