import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import CssBaseline from '@mui/material/CssBaseline';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import Avatar from '@mui/material/Avatar';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import ConstructionIcon from '@mui/icons-material/Construction';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ReceiptLongIcon from '@mui/icons-material/ReceiptLong'
import AssessmentIcon from '@mui/icons-material/Assessment';
import TakeoutDiningIcon from '@mui/icons-material/TakeoutDining';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import ShoppingBagIcon from '@mui/icons-material/ShoppingBag';
import SettingsIcon from '@mui/icons-material/Settings';
import WarehouseIcon from '@mui/icons-material/Warehouse';
import SummarizeIcon from '@mui/icons-material/Summarize';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import SubtitlesIcon from '@mui/icons-material/Subtitles';
import ImageIcon from '@mui/icons-material/Image';
import NumbersIcon from '@mui/icons-material/Numbers';
import SupportAgentIcon from '@mui/icons-material/SupportAgent';
import StickyNote2Icon from '@mui/icons-material/StickyNote2';
import BuildIcon from '@mui/icons-material/Build';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import CodeIcon from '@mui/icons-material/Code';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';


import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";




import { useAuthStatus } from '../hooks/authStatus';

//FB
import { auth } from '../services/firebase';
import {  onAuthStateChanged, getAuth, signOut  } from 'firebase/auth';




import { useNavigate, useLocation } from "react-router-dom"


const drawerWidth = 75;

const settings = [ 'Logout'];

export default function PermanentDrawerLeft() {
    // const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);
    const [user, setUser] = useState("");
    const [hovered, setHovered] = useState(false);
    const [iconPosition, setIconPosition] = useState(0);
    const iconRef = useRef(null); // Reference to the icon

    useEffect(() => {
      if (hovered && iconRef.current) {
        const rect = iconRef.current.getBoundingClientRect();
        setIconPosition(rect.top); // Get exact vertical position
      }
    }, [hovered]);


    const auth = getAuth();

    const location = useLocation();

    useEffect(() => {
     
      onAuthStateChanged(auth, (user) => {
        if (user) {
          console.log(user.email)
          setUser(user.email[0].toUpperCase())
          // if (user.email === "jacob@pacas.com") {
            setTheme()
          // }

        } else {
          console.log("no")
          navigate("/login")
        }
      });
    }, []);

    const setTheme = () => {

      const root = document.documentElement;
      root.style.setProperty('--primary-color', '#505152');
      root.style.setProperty('--secondary-color', '#303030');
      root.style.setProperty('--content-color', '#0d0c0c');
      root.style.setProperty('--counter-color', '#f0f1f2');
      root.style.setProperty('--text-color', '#f0f1f2');

    }
  
    // const handleOpenNavMenu = (event) => {
    //   setAnchorElNav(event.currentTarget);
    // };
    const handleOpenUserMenu = (event) => {
      setAnchorElUser(event.currentTarget);
    };

    const handleSignOut = () => {
      signOut(auth).then(() => {
          // Sign-out successful.
          navigate("/login")
        }).catch((error) => {
          // An error happened.
        });
  }
  
    // const handleCloseNavMenu = () => {
    //   setAnchorElNav(null);
    // };
  
    const handleCloseUserMenu = () => {
      setAnchorElUser(null);
      
    };

    let navigate = useNavigate()

    let currentRoute = location.pathname
    console.log(currentRoute)
      if (location.pathname === "/bom") {currentRoute = "Boms & Translations"}
      else if (location.pathname === "/packcreator") {currentRoute = "Pack Creator"}
      else if (location.pathname === "/bulkcreation") {currentRoute = "Bulk Creation"}
      else if (location.pathname === "/") {currentRoute = "Dashboard"}
      else if (location.pathname === "/labels") {currentRoute = "Labels"}
      else if (location.pathname === "/kr") {currentRoute = "Westmont Inventory"}
      else if (location.pathname === "/adjustments") {currentRoute = "Adjustments"}
      else if (location.pathname === "/bundleimages") {currentRoute = "Bundle Images"}
      else if (location.pathname === "/upc") {currentRoute = "UPC"}
      else if (location.pathname === "/colorchanger") {currentRoute = "Settings"}
      else if (location.pathname === "/alerts") {currentRoute = "Alerts & Reports"}
      else if (location.pathname === "/customersupport") {currentRoute = "Customer Support"}
      else if (location.pathname === "/packplanner") {currentRoute = "Pack Planner"}


      if (user) {

    
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* <AppBar position="static" style={{
         background: 'transparent',
        border: "1px solid var(--text-color)",
         borderRadius: "15px",
        marginLeft: "8vw", marginTop: "10px", maxWidth: "91vw", height: "7vh"}}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>

  

          <Box style={{display:"flex"}}sx={{ flexGrow: 0 }}>
            <div style={{ width: "40vw"}}>
            <h1 style={{color: "var(--text-color)", marginTop: "0.5vw",}}>{currentRoute}</h1>
            </div>
            <div>
            <Tooltip title="Open settings">
             
              <IconButton onClick={handleOpenUserMenu} style={{marginLeft: "36vw", marginTop: "1.2vh"}}sx={{ p: 0 }}>
                <Avatar >{user}</Avatar>
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              {settings.map((setting) => (
                <MenuItem key={setting} onClick={handleSignOut}>
                  <Typography textAlign="center">{setting}</Typography>
                </MenuItem>
              ))}
            </Menu>
            </div>
          </Box>
        </Toolbar>
      </Container>
    </AppBar> */}
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
          backgroundColor: "black",
          boxShadow: 'none',
          borderWidth: 0,
          overflow: "visible", // Ensures content can expand outside
        },
      }}
      variant="permanent"
      anchor="left"
    >
        <Toolbar   style={{ marginTop: '1vh'}} />
        <List>

            <ListItem key="dashboard" disablePadding>
              <ListItemButton 
                onClick={() => navigate("/")}
               
              >
                <Tooltip title="Dashboard" placement="right">
                <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                  <DashboardIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>
          
              </ListItemButton>
            </ListItem>

{/* 
            <ListItem key="copyvariant" disablePadding>
              <ListItemButton 
                onClick={() => navigate("/copyvariant")}
               
              >
                <Tooltip title="Copy Variant" placement="right">
                <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                  <DashboardIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>
          
              </ListItemButton>
            </ListItem> */}


            <ListItem key="pc" disablePadding>
              <ListItemButton onClick={() => navigate("/packcreator")}>

              <Tooltip title="Pack Creator" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <ConstructionIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>

              </ListItemButton>
            </ListItem>



            <ListItem key="bom" disablePadding>
              <ListItemButton onClick={() => navigate("/bom")}>
              <Tooltip title="Boms & Translations" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <SubtitlesIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>

              </ListItemButton>
            </ListItem>


{/* 

            <ListItem key="bulkcreation" disablePadding>
              <ListItemButton onClick={() => navigate("/bulkcreation")}>


              <Tooltip title="Bulk Creation" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <BuildIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>

              </ListItemButton>
            </ListItem> */}


<ListItem key="bulkcreation" disablePadding>
              <ListItemButton onClick={() => navigate("/singleshop")}>


              <Tooltip title="Single Shop" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <ShoppingCartIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>

              </ListItemButton>
            </ListItem>

            <ListItem key="alerts" disablePadding>
              <ListItemButton onClick={() => navigate("/alerts")}>

              <Tooltip title="Alerts & Reports" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <SummarizeIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>

              </ListItemButton>
            </ListItem>


            <ListItem key="labels" disablePadding>
              <ListItemButton onClick={() => navigate("/labels")}>

              <Tooltip title="Capacity Labels" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                
                 <LocalShippingIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>

                </Tooltip>

              </ListItemButton>
            </ListItem>

       

            <ListItem key="packplanner" disablePadding>

              <ListItemButton onClick={() => navigate("/packplanner")}>


              <Tooltip title="Pack Planner" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <StickyNote2Icon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>

                </Tooltip>

              </ListItemButton>
            </ListItem>
     
            <ListItem key="adjustments" disablePadding>
              <ListItemButton onClick={() => navigate("/adjustments")}>

              <Tooltip title="Adjustments" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <WarehouseIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>

              </ListItemButton>
            </ListItem>



            <ListItem key="bi" disablePadding>
              <ListItemButton onClick={() => navigate("/bundleimages")}>

              <Tooltip title="Bundle Images" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <ImageIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>

              </ListItemButton>
            </ListItem>



            <ListItem key="upc" disablePadding>
              <ListItemButton onClick={() => navigate("/upc")}>

              <Tooltip title="UPC" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <NumbersIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>
               
              </ListItemButton>
            </ListItem>

            <ListItem key="customersupport" disablePadding>
              <ListItemButton onClick={() => navigate("/customersupport")}>

              <Tooltip title="Customer Support" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <SupportAgentIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>

                </Tooltip>

              </ListItemButton>
            </ListItem>


            {/* <ListItem key="eom" disablePadding>
              <ListItemButton onClick={() => navigate("/eom")}>
              <Tooltip title="EOM Reports" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <AssessmentIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>
\
              </ListItemButton>
            </ListItem> */}


            {/* <ListItem key="invoices" disablePadding>
              <ListItemButton onClick={() => navigate("/invoices")}>
              <Tooltip title="Invoices" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <AttachMoneyIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>
\
              </ListItemButton>
            </ListItem> */}


            {/* <ListItem key="blocksku" disablePadding>
              <ListItemButton onClick={() => navigate("/blocksku")}>
              <Tooltip title="Block Sku" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <SettingsIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>

              </ListItemButton>
            </ListItem> */}

<ListItem
          key="scripts"
          disablePadding
          onMouseEnter={() => setHovered(true)}
          onMouseLeave={() => setHovered(false)}
          sx={{ position: "relative" }} // Needed to position the absolute child
        >
          <ListItemButton onClick={() => navigate("/")} sx={{ overflow: "hidden" }}>
            {/* Main Icon */}
            {/* <Tooltip title="Block Sku" placement="right"> */}
              <ListItemIcon
                ref={iconRef} // Attach ref to track position
                sx={{
                  minWidth: "30px",
                  transition: "transform 0.3s ease",
                  position: "relative", // Allows positioning relative to the button
                }}
              >
                <CodeIcon sx={{ fontSize: 25, color: "white" }} />
              </ListItemIcon>
            {/* </Tooltip> */}
          </ListItemButton>

          {/* Expanding Icons Container - Dynamically Positioned */}
          {hovered && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                position: "fixed", // Allow expansion beyond drawer
                left: drawerWidth - 10, // Position outside the drawer
                top: iconPosition - 10, // Dynamically match SettingsIcon's position
                backgroundColor: "black",
                borderRadius: "8px",
                padding: "15px",
                height: "50px", // Match height of icon
                width: hovered ? "150px" : "0px", // Expand on hover
                overflow: "hidden",
                transition: "width 0.3s ease-in-out",
                whiteSpace: "nowrap",
                zIndex: 1300, // Ensure it's above other elements
              }}
            >
                <ListItemButton onClick={() => navigate("/eom")}>
              <Tooltip title="EOM Reports" placement="top">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <AssessmentIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>
\
              </ListItemButton>
              <ListItemButton onClick={() => navigate("/invoices")}>
              <Tooltip title="Invoices" placement="top">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <AttachMoneyIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>
\
              </ListItemButton>
              <ListItemButton onClick={() => navigate("/blocksku")}>
              <Tooltip title="Block Sku" placement="top">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <DeleteIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>

              </ListItemButton>

              <ListItemButton onClick={() => navigate("/shortscript")}>
              <Tooltip title="Short Script" placement="top">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <DeleteIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>

              </ListItemButton>
            </Box>
          )}
        </ListItem>



            <ListItem key="settings" disablePadding>
              <ListItemButton onClick={() => navigate("/colorchanger")}>
              <Tooltip title="Settings" placement="right">
              <ListItemIcon
                    sx={{
                      marginBottom: "1vh",
                      '&:hover svg': {
                        transform: 'scale(1.5)', // Adjust the scale to your preference
                        transition: 'transform 0.3s ease', // Smooth animation
                      },
                    }}
                  >
                 <SettingsIcon sx={{ fontSize: 25, color: "white", bgcolor: "black", borderRadius: 1.5 }} /> 
                </ListItemIcon>
                </Tooltip>
\
              </ListItemButton>
            </ListItem>
   
        </List>

      </Drawer>
      {/* <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
       
      </Box> */}
    </Box>
  );

} 

else {
  return (
<></>
 
  )
}


}