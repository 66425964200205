import React, { useEffect, useState } from 'react';
import PackCreator from './PackCreator';
import Switch from '@mui/material/Switch';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

import Button from '@mui/material/Button';
import { Typography } from '@mui/material';


export default function Loading() {
const [view, setView] = useState(0);
const [skuChecked, setSkuChecked] = useState(true);


const handleSkuSwitch = (event) => {
    setSkuChecked(event.target.checked);
  };


const changeView = () => {
    setView(0)
}
const changeViewPrimary = () => {
    setView(1)
}

const changeViewSecondary = () => {
    setView(2)
}

if (view === 0) {
    return (
        <div>
        {/* <div style={{marginLeft: "30vw", color: "white", display: "flex", marginTop: "15vh"}}>
            <div style={{}}>
            <h1>Create Primary</h1>
            </div>
           
           <div style={{marginLeft: "13vw"}}>
           <h1>Create Secondary</h1>
           </div>
           

        </div > */}
        <div style={{marginLeft: "25vw", marginTop:"30vh"}}>
        <Button style={{backgroundColor: "white", height: "22vh", width:"20vw"}} onClick={changeViewPrimary}>
            <Typography>Create a Pack of Singles</Typography>
        </Button>
        <Button style={{backgroundColor: "white", height: "22vh", width:"20vw", marginLeft: "15vw"}} onClick={changeViewSecondary}>
           <Typography>Create a Pack of Packs</Typography> 
            </Button>
        </div>
        <FormGroup style={{marginLeft: "47vw"}}>
        <FormControlLabel
             sx={{  color: "white" }}
            label="New SKU Style"
             control={<Switch
                checked={skuChecked}
                onChange={handleSkuSwitch}
                inputProps={{ 'aria-label': 'controlled' }}
                labelPlacement="end"
                />}
            />
            </FormGroup>
        </div>
    )
} else if (view !== 0) {
    return (
        <div>
            <PackCreator changeView={changeView} view={view} skuChecked={skuChecked} />
        </div>
    )
}
}