import React, { useState, useEffect, useRef }  from "react"
import { styled } from '@mui/material/styles';
import Button from '@mui/material/Button';
import axios from "axios"

import Papa from "papaparse"

import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';



const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'absolute',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1,
  });





export default function PackPlanner() {
     const [fileUploadData, setFileUploadData] = useState([])
     const [variantData, setVariantData] = useState([])
     const [dataAll, setDataAll] = useState([])

         const fileInputRef = useRef(null);



         const handleFileUpload =  (e) => {
                let currentTime = new Date().toLocaleString()
                console.log(currentTime)
                const files = e.target.files;
                console.log(files);
                if (files) {
                  console.log(files[0]);
                  Papa.parse(files[0], {
                    header: true,
                    complete: function(results) {
                      console.log("Finished:", results.data);
                      let formArray = results.data.map(elem => {
        
              
                   //Add validation
              
                        return (
                          {
                          sku: elem.sku,
                          quantity: elem.quantity
                        
                          
                        
                          }
                        )
                        
                  
                      })
                      console.log(formArray)
        
                    //   setUploadData(formArray)
                    setFileUploadData(formArray)

                    getAllSingles(formArray)
    
        
                      if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                      }
            
                   
                    }}
                  )
                }
              
              }


    const getAllSingles = async (fData) => {
        const response = await axios.post("/api/shopify/getallsingles")


        console.log(response.data)


        const imageArray = response.data.products.flatMap((elem) => {
            return elem.images
        })

        console.log(imageArray)

        const variantArray = response.data.products.flatMap((elem) => {
            return elem.variants
        })


        console.log(variantArray)


        const formArray = variantArray.map((elem) => {
            const foundObject = imageArray.find((image) => image.id === elem.image_id  )

            return (
                {
                    id: elem.id,
                    prod_id: elem.product_id,
                    sku: elem.sku,
                    src: foundObject ? foundObject.src : ""
                }
            )

        })


        console.log(formArray)


        setVariantData(formArray)


        matchData(fData, formArray)




    }



    const matchData = (fData, vData) => {
        const formattedData = fData.map((elem) => {

            const foundData = vData.find((v) => v.sku === elem.sku)

            let style = ""
            let cat = ""

            if (foundData) {
                style = catById(foundData.prod_id).style
                cat = catById(foundData.prod_id).cat
            }

            return (
                {
                    sku: elem.sku,
                    quantity: elem.quantity,
                    cat: cat,
                    style: style,
                    vID: foundData ? foundData.id : "",
                    pID: foundData ? foundData.prod_id : "",
                    src: foundData ? foundData.src : ""

                }
            )
        })


        console.log(formattedData)

        setDataAll(formattedData)

    }


    const catById = (id) => {

        let style = ""
        let cat = ""


        if (id === 6852663804004 || id === 6620534669412 || id === 6597571575908 || id === 4837716951140 || id === 6950074384484 || id === 7163184283748 || id === 4837733957732 || id === 7286790619236 || id === 7851959943268 || id === 7901240098916 ) { 
            style = "Crew Socks"
            cat = "Women's"
          } 
          
          else if (id === 6873293586532 || id === 6930797101156 || id === 7255628546148 || id === 7217697390692 || id === 7902016798820) {
            style = "Compression Socks"
            cat = "Women's"
          }
          else if (id === 6824475361380) {
            style = "Golf Socks"
            cat = "Women's"
          }
          else if (id === 6609129275492 || id === 7255634051172 || id === 7217910743140 || id === 6609176789092 || id === 6609178230884 || id === 7863713726564 ) {
            style = "Light-Weight Crew Socks"
            cat = "Women's"
          }
          else if ( id === 6626100314212 || id === 6816252428388 || id === 4823040131172 || id === 7217900060772 || id === 7851605065828 ) {
            style = "Low-Cut Socks"
            cat = "Women's"
          }
          else if (id === 6541486489700 || id === 7321956876388 || id === 7909757681764 || id === 6585673187428) {
            style = "No-Show Socks"
            cat = "Women's"
          }
          else if (id === 6557907746916 || id === 6558139154532) {
            style = "Performance Socks"
            cat = "Women's"
          }
          else if (id === 6575734030436 || id === 6575736914020) {
            style = "Performance Socks"
            cat = "Men's"
          }
          else if (id === 7105370357860 || id === 7105422360676) {
              style = "Slouchy Socks"
              cat = "Women's"
            }
            else if (id === 14665442656626) {
              style = "Ruffle Socks"
              cat = "Women's"
            }
          else if (id === 7194189430884) {
            style = "Hiking Crew Socks"
            cat = "Women's"
          }
          else if (id === 7194189987940) {
            style = "Hiking Quarter Socks"
            cat = "Women's"
          }

         
       
      
          else if (id === 2236767502436 || id === 6626100314212 || id === 6816252428388 || id === 6958959296612 || id === 7255625433188 ) {
            style = "Low-Cut Socks"
            cat = "Women's"
          }
      
          else if (id=== 6956831965284 || id === 4837718753380 || id === 4837737365604 || id === 7223568990308 || id === 7255597383780 || id === 7851670143076) {
            style = "Crew Socks"
            cat = "Men's"
          }
          else if (id === 6878354341988 || id === 6930798379108) {
            style = "Compression Socks"
            cat = "Men's"
          }
      
          else if (id === 6602920394852 || id === 6602924785764 || id === 6602925637732 || id === 7897203343460) {
            style = "Light-Weight Crew Socks"
            cat = "Men's"
          }

          else if (id === 6824474771556) {
            style = "Golf Socks"
            cat = "Men's"
          }

          else if (id === 7194190217316) {
            style = "Hiking Crew Socks"
            cat = "Men's"
          }

          else if (id === 7194190512228) {
            style = "Hiking Quarter Socks"
            cat = "Men's"
          }
          else if (id === 4838251888740 || id === 4838251987044 || id === 7912946368612) {
            style = "Crew Socks"
            cat = "Kids'"
          }
      
      
      
        
      
          else if (id === 4838368051300 || id === 4838370410596 ) {
            style = "Toddler Pacas Socks"
          }
     
  

          else if (id === 4823046324324 || id === 7255624745060 || id === 7851616370788 || id === 4823047143524  ) {
              style = "Low-Cut Socks"
              cat = "Men's"
            }

            return (
                {
                    style: style,
                    cat: cat
                }
            )



    }

    const Table = () => {
    const headers = ["Found","Sku", "Quantity", "Category", "Style", "vID", "pID", "src"]

    return (
        <table className="styled-table">
            <thead>
                <tr>
                    {
                        headers.map((elem, i) => {
                            return (
                                <th key={i}>{elem}</th>
                            )
                        })
                    }
                </tr>
            </thead>
            <tbody>
                {dataAll.map((elem, i) => {
                    return (
                        <tr key={i}>
                            <td>
                                {
                                    (elem.src !== "") ?
                                    <CheckCircleOutlineIcon color="success" />
                                    :
                                    <ErrorOutlineIcon style={{color: "#c72306"}} />
                                }
                            </td>
                            <td>
                                {elem.sku}
                            </td>
                            <td>
                                {elem.quantity}
                            </td>
                            <td>
                                {elem.cat}
                            </td>
                            <td>
                                {elem.style}
                            </td>
                            <td>
                                {elem.vID}
                            </td>
                            <td>
                                {elem.pID}
                            </td>
                            <td>
                                {(elem.src !== "") && <a href={elem.src} target="_blank">image</a>}
                            </td>
                        
                        </tr>
                    )
                })}

            </tbody>
        </table>
    )
}
   
  






    return (
        <div style={{ marginLeft: "14vw" }}>


        
{dataAll.length === 0 ?

<div style={{marginLeft:"20vw", marginTop:"20vh"}}>
        
        <Button style={{height:"10vh", width:"10vw", marginRight:"15vw"}} component="label" variant="contained" >
                Upload Inventory List
            <VisuallyHiddenInput ref={fileInputRef} onChange={handleFileUpload} type="file" accept=".csv" />
        </Button>

        <Button  style={{height:"10vh", width:"10vw"}} component="label" variant="contained" >
                Select Inventory List

        </Button>
   

  

        </div>
 :

  <div style={{margin:"0vw"}}>
 

        <Button>Plan</Button>
         <Table />
 
         </div>

}


  
        </div>

    )
}