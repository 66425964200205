
import React, { useState, useEffect }  from "react"
import axios from "axios"

//MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import Button from "@mui/material/Button";



export default function SingleShopCard(props) {



  const handleActive = async () => {
    if (props.reserve !== 0) {

      alert("Quantity Mismatch")

      return
    }

    console.log(props.iiID)
    const response = await axios.post("/api/shopify/getlevelssingles", {
      levelId: props.iiID
    })

 

 

    if (response.data.inventory_levels.length === 0) {
      alert("Level Error")
    } 

    else {
      const shopLevel = response.data.inventory_levels[0].available

      console.log(shopLevel)

      const update = await axios.post("/api/reserve/updateone",
        {
          sku: props.sku,
          reserve: shopLevel
        }
      )
      console.log(update)


      const zeroShop = await axios.post("/api/shopify/setinventorysingles", {
        iiID: props.iiID,
        quantity: 0
      })

      console.log(zeroShop)


    alert("success")
    props.getSingleVariants()



    }



  }


  const handleReserve = async () => {
    if (props.quantity !== 0) {

      alert("Quantity Mismatch")

      return
    }
    const response = await axios.post("/api/reserve/getone",
      {
        sku: props.sku
      }
    )


    const reserveQuant = response.data[0].reserve

    console.log(reserveQuant)


    const addShop = await axios.post("/api/shopify/setinventorysingles", {
      iiID: props.iiID,
      quantity: reserveQuant
    })

    console.log(addShop)


    const update = await axios.post("/api/reserve/updateone",
      {
        sku: props.sku,
        reserve: 0
      }
    )


    console.log(update)

    alert("success")

    props.getSingleVariants()



  }




    return (
        <Card
        raised={true}
        sx={{
          maxWidth: "15vw",
          minHeight: "58vh",
          maxHeight: "58vh",
          bgcolor: "#ededf2",
          backgroundColor: "var(--content-color)",
          borderRadius: "15px",
          boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
        }}
      >
        <Typography sx={{color:"white"}} gutterBottom variant="h6" component="div">
         {props.sku}
        </Typography>
        {props.src ? (
          <CardMedia
            sx={{ height: "20vw" }}
            image={props.src}
            title={props.sku}
   
          />
        ) : (
          <Skeleton variant="rectangular" height={300} />
        )}
  
        <CardContent>
        <Typography sx={{color:"white"}} variant="body2" color="text.secondary">
            Reserve Quantity: {props.reserve}
     
          </Typography>

          <Typography sx={{color:"white"}} variant="body2" color="text.secondary">
            iiID: {props.iiID}
     
          </Typography>


          <Typography sx={{color:"white"}} variant="body2" color="text.secondary">
            Active Quantity: {props.quantity}
          </Typography>

        {(props.quantity <= 0 && props.reserve <= 0 )

          ?
          <h3 style={{color:"white"}}>No inventory</h3>
          :

          (props.reserve > 0)

            ?
             
            <Button onClick={handleReserve}>Add Reserve Quantity to Active</Button>

            :

            <Button onClick={handleActive}>Add Active Quantity to Reserve</Button>
        
        }

        

        </CardContent>
   
      </Card>

    )
}