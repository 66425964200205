
import React, { useState, useEffect }  from "react"
import Dashboard from "../subViews/Dashboard"
import axios from "axios"
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import Papa from "papaparse"





export default function BlockSku() {


      const generateExcelFile = async (input) => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Sheet 1');
      
    
        const data = input
    
      
        // Add column headers
        const headers = Object.keys(data[0]);
        worksheet.addRow(headers);
    
        // Add data rows
        data.forEach(item => {
          const values = Object.values(item);
          worksheet.addRow(values);
        });
    
    
        workbook.xlsx.writeBuffer().then((buffer) => {
          const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
          saveAs(blob, 'BlockSkuList.xlsx');
        });
      };


    const handleExport = async () => {
        const response = await axios.post("/api/shopify/getsingleshop")

        const variants = response.data.products.map((elem) => elem.variants).flat()


        const formData = variants.map((elem) => {

            return (
                {
                    Data: elem.sku
                }
            )
        })

        console.log(formData)

        generateExcelFile(formData)

    }
    





    return (
        <div style={{ marginLeft: "6vw" }}>

            <button onClick={handleExport} >Generate File</button>

  
        </div>

    )
}