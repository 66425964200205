import React, { useEffect, useState, forwardRef } from "react";
// import axios from "axios"

//MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";
import CancelIcon from "@mui/icons-material/Cancel";

export default function SelectedSingleDisplay(props) {
  const [skuQuant, setSkuQuant] = useState("1");

  const handleChangeQuantity = (e) => {
    setSkuQuant(e.target.value);
    props.changeQuantity(props.sku, e.target.value);
  };

  const handleDeselect = () => {
    props.deselectHandle(props.sku);
  };

  return (
    <Card
      raised={true}
      sx={{
        maxWidth: "25vw",
        minHeight: "41vh",
        maxHeight: "41vh",
        bgcolor: "#ededf2",
        backgroundColor: "var(--content-color)",
        borderRadius: "15px",
        boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
        marginBottom: "10px",
      }}
    >
      <Typography gutterBottom variant="h6" component="div">
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            color:"white"
          }}
        >
          <div>{props.sku}</div>
          <div>
            <CancelIcon
              onClick={handleDeselect}
              style={{ cursor: "pointer" }}
            />
          </div>
        </div>
      </Typography>
      {props.image ? (
        <CardMedia
          sx={{
            height: "26vh",
            minHeight: "26vh",
            maxWidth: "16vw",
            minWidth: "16vw",
            backgroundColor:"white"
          }}
          image={props.image}
          title={props.sku}
        />
      ) : (
        <Skeleton variant="rectangular" height={300} />
      )}

      <CardContent style={{display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",}}>
        <Typography variant="body2" color="white">
          {props.color.length > 25
            ? `${props.color.substring(0, 25)}...`
            : props.color}
        </Typography>
        <Typography variant="body2" color="white">
          {props.size.length > 20
            ? `${props.size.substring(0, 20)}...`
            : props.size}
        </Typography>

        <input
          value={skuQuant}
          onChange={handleChangeQuantity}
          type="number"
          placeholder="Quantity"
          min="1"
          style={{borderRadius:"7px", }}
        />
      </CardContent>
    </Card>
  );
}
