import { useState } from "react";

import { HexColorPicker } from "react-colorful";

import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';

import Papa from "papaparse"

import ti from "../assets/3TC.png"

import axios from "axios"
import QRCode from 'qrcode';




function App() {
  const [items, setItems] = useState([
    { id: 1, name: "Apple" },
    { id: 2, name: "Banana" },
    { id: 3, name: "Cherry" },
  ]);
  const [selected, setSelected] = useState("");
  const [color, setColor] = useState("#aabbcc");
  const [color2, setColor2] = useState("#aabbcc");
  const [id, setId] = useState("");
  const [idData, setIdData] = useState([]);

  const [inboundData, setInboundData] = useState([])
  const [reductionData, setReductionData] = useState([])
  const [skuData, setSkuData] = useState([])

  const [po, setPo] = useState("");


  const [vis, setVis] = useState("hidden");

  const hide = (e) => {
 
    if (vis === "hidden") {
      setVis("visible")
      setSelected(e.target.id)
    } else {
      setVis("hidden")
    }
  }



  const changeColor = (e) => {

    if (selected === "1") {
      setColor(e)
    } 
    if (selected === "2") {
      setColor2(e)
    }
  }

  function onDragStart(event, index) {
    event.dataTransfer.setData("index", index);
  }

  function onDragOver(event) {
    event.preventDefault();
  }

  function onDrop(event, newIndex) {
    const oldIndex = event.dataTransfer.getData("index");
    const newItems = [...items];
    const [removed] = newItems.splice(oldIndex, 1);
    newItems.splice(newIndex, 0, removed);
    setItems(newItems);
  }

  let dataSet = [{
    ship: [],
    set:""
  }]

  let dataSet2 = [{
    ship: [{
      title: "test"
    }],
    set:""
  }]

  const onTest = () => {
    if (dataSet2[0].ship.length > 0) {
      dataSet2[0].set = dataSet2[0].ship[0].title
    }

    console.log(dataSet2)
  }
  //
  const imageArray = [
    { name: 'Image 1', base64: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=" },
    { name: 'Image 2', base64: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII=" },
    // ...more image objects
  ];


  let testImage = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAApgAAAKYB3X3/OAAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAANCSURBVEiJtZZPbBtFFMZ/M7ubXdtdb1xSFyeilBapySVU8h8OoFaooFSqiihIVIpQBKci6KEg9Q6H9kovIHoCIVQJJCKE1ENFjnAgcaSGC6rEnxBwA04Tx43t2FnvDAfjkNibxgHxnWb2e/u992bee7tCa00YFsffekFY+nUzFtjW0LrvjRXrCDIAaPLlW0nHL0SsZtVoaF98mLrx3pdhOqLtYPHChahZcYYO7KvPFxvRl5XPp1sN3adWiD1ZAqD6XYK1b/dvE5IWryTt2udLFedwc1+9kLp+vbbpoDh+6TklxBeAi9TL0taeWpdmZzQDry0AcO+jQ12RyohqqoYoo8RDwJrU+qXkjWtfi8Xxt58BdQuwQs9qC/afLwCw8tnQbqYAPsgxE1S6F3EAIXux2oQFKm0ihMsOF71dHYx+f3NND68ghCu1YIoePPQN1pGRABkJ6Bus96CutRZMydTl+TvuiRW1m3n0eDl0vRPcEysqdXn+jsQPsrHMquGeXEaY4Yk4wxWcY5V/9scqOMOVUFthatyTy8QyqwZ+kDURKoMWxNKr2EeqVKcTNOajqKoBgOE28U4tdQl5p5bwCw7BWquaZSzAPlwjlithJtp3pTImSqQRrb2Z8PHGigD4RZuNX6JYj6wj7O4TFLbCO/Mn/m8R+h6rYSUb3ekokRY6f/YukArN979jcW+V/S8g0eT/N3VN3kTqWbQ428m9/8k0P/1aIhF36PccEl6EhOcAUCrXKZXXWS3XKd2vc/TRBG9O5ELC17MmWubD2nKhUKZa26Ba2+D3P+4/MNCFwg59oWVeYhkzgN/JDR8deKBoD7Y+ljEjGZ0sosXVTvbc6RHirr2reNy1OXd6pJsQ+gqjk8VWFYmHrwBzW/n+uMPFiRwHB2I7ih8ciHFxIkd/3Omk5tCDV1t+2nNu5sxxpDFNx+huNhVT3/zMDz8usXC3ddaHBj1GHj/As08fwTS7Kt1HBTmyN29vdwAw+/wbwLVOJ3uAD1wi/dUH7Qei66PfyuRj4Ik9is+hglfbkbfR3cnZm7chlUWLdwmprtCohX4HUtlOcQjLYCu+fzGJH2QRKvP3UNz8bWk1qMxjGTOMThZ3kvgLI5AzFfo379UAAAAASUVORK5CYII="

  const generateExcelFile = async (input) => {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
  


    // const data = input.map(({ children, ...rest }) => rest);

    const data = input

  
    // Add column headers
    const headers = Object.keys(data[0]);
    worksheet.addRow(headers);

    // Add data rows
    data.forEach(item => {
      const values = Object.values(item);
      worksheet.addRow(values);
    });


    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, 'test.xlsx');
    });
  };


  const generateCSVFile = (input) => {
    const csvRows = [];
  
    // Get the headers from the first object
    const headers = Object.keys(input[0]);
    csvRows.push(headers.join(",")); // Convert headers array to CSV format
  
    // Convert each data row into CSV format
    input.forEach(item => {
      const values = Object.values(item).map(value => 
        typeof value === "string" && value.includes(",") ? `"${value}"` : value
      ); // Handle commas inside values
      csvRows.push(values.join(","));
    });
  
    // Create CSV Blob
    const csvString = csvRows.join("\n");
    const blob = new Blob([csvString], { type: "text/csv" });
  
    // Trigger download
    const link = document.createElement("a");
    link.href = URL.createObjectURL(blob);
    link.download = "test.csv";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleFileUpload = (e) => {
    let currentTime = new Date().toLocaleString()
    console.log(currentTime)
    const files = e.target.files;
    console.log(files);
    if (files) {
      console.log(files[0]);
      Papa.parse(files[0], {
        header: true,
        complete: function(results) {
          console.log("Finished:", results.data);
          let formArray = results.data.map(elem => {

            if (elem.d) {
              return (
                {
                  a: elem.a,
                  b: elem.b,
                  c: elem.c,
                  d: elem.d
                   
                }
            )

            } else {
            return (
                {
                  a: elem.a,
                  b: elem.b,
                  c: elem.c
                   
                }
            )
              }
          })
          console.log(formArray)
          setInboundData(formArray)
        }}
      )
    }

}



const handleFileUpload4 = (e) => {
  let currentTime = new Date().toLocaleString()
  console.log(currentTime)
  const files = e.target.files;
  console.log(files);
  if (files) {
    console.log(files[0]);
    Papa.parse(files[0], {
      header: true,
      complete: async function(results) {
        console.log("Finished:", results.data);
        let formArray = results.data.map(elem => {


            return (
              {
                sku: elem.sku,
             
                 
              }
          )

         
        })
        console.log(formArray)

        const lengthFilter = formArray.filter((elem) => elem.sku.length === 4)


        const variantData = await getAllSingles()


        const prodMatch = lengthFilter.map((elem) => {

          const foundData = variantData.find((ellm) => ellm.sku === elem.sku)

          return (
              {
                  sku: elem.sku,
                  prodId: (foundData) ? foundData.product_id : "N/A"
              }
          )
        })


        const dataFinal = prodMatch.map((elem) => {

          const cat = getTitle(elem.prodId)
          return (
              {
                  ...elem, cat: cat
              }
          )
        })


        generateExcelFile(dataFinal)




        // setInboundData(formArray)
      }}
    )




  }

}


const getAllSingles = async () => {

  const response =  await axios.post("/api/shopify/getallvariants")

  console.log(response.data.products)

  const variants = response.data.products.map((elem) => {

      return (
          elem.variants
      )
  }).flat()

  console.log(variants)


  return (variants)
}



const getTitle = (id) => {
  let title = ""

  if (id === 6852663804004 || id === 6620534669412 || id === 6597571575908 || id === 4837716951140 || id === 6950074384484 || id === 7163184283748 || id === 4837733957732 || id === 7286790619236 || id === 7851959943268 || id === 7901240098916 ) { 
     title = "Womens Crew Socks"
    } 
    
    else if (id === 6873293586532 || id === 6930797101156 || id === 7255628546148 || id === 7217697390692 || id === 7902016798820 || id === 14655282250098) {
     title = "Women's Compression Socks"
    }
    else if (id === 6824475361380) {
      title = "Women's Golf Socks"
    }
    else if (id === 6609129275492 || id === 7255634051172 || id === 7217910743140 || id === 6609176789092 || id === 6609178230884 || id === 7863713726564 ) {
     title = "Women's Light-Weight Crew Pacas Socks"
    }
    else if ( id === 6626100314212 || id === 6816252428388 || id === 4823040131172 || id === 7217900060772 || id === 7851605065828 ) {
      title = "Women's Low Cut Socks"
    }
    else if (id === 6541486489700 || id === 7321956876388 || id === 7909757681764 || id === 6585673187428) {
      title = "Women's No Show Socks"
    }
    else if (id === 6557907746916 || id === 6558139154532) {
      title = "Women's Performance Socks"
    }
    else if (id === 6575734030436 || id === 6575736914020) {
      title = "Men's Performance Socks"
    }
    else if (id === 7105370357860 || id === 7105422360676) {
        title = "Women's Slouchy Socks"
      }
      else if (id === 14665442656626) {
        title = "Women's Ruffle Socks"
        
      }
    else if (id === 7194189430884) {
      title = "Women's Hiking"
    }
    else if (id === 7194189987940) {
     title = "Women's Hiking Qrt"
    }

   
    else if (id === 6888734425188 || id === 6888748580964 || id === 6888738160740 || id === 6888742060132 || id === 6888726921316 || id === 6888747335780) {
      title = "Pacas Throw Blanket"
    }

    else if (id === 2236767502436 || id === 6626100314212 || id === 6816252428388 || id === 6958959296612 || id === 7255625433188 ) {
     title = "Women's Low Cut Socks"
    }

    else if (id=== 6956831965284 || id === 4837718753380 || id === 4837737365604 || id === 7223568990308 || id === 7255597383780 || id === 7851670143076) {
      title = "Men's Crew"
    }
    else if (id === 6878354341988 || id === 6930798379108) {
      title = "Men's Compression Socks"
    }

    else if (id === 6602920394852 || id === 6602924785764 || id === 6602925637732 || id === 7897203343460) {
      title = "Men's Light-Weight Crew Pacas Socks"
    }

    else if (id === 6824474771556) {
      title = "Men's Golf Socks"
    }

    else if (id === 7194190217316) {
      title = "Men's Hiking"
    }

    else if (id === 7194190512228) {
      title = "Men's Hiking Qrt"
    }
    else if (id === 4838251888740 || id === 4838251987044 || id === 7912946368612 || id === 7254786015332 || id === 7009310113892) {
      title = "Kids' Crew Pacas Socks"
    }

    else if (id === 6873185353828 || id === 6862851080292 || id === 6873188270180 || id === 6873186828388 || id === 6873182437476 ) {
      title = "Pacas Summer Wrap"
    }

    else if (id === 6972996714596 || id === 6972996157540 || id === 6972996976740 || id === 6972996419684 || id === 6972997271652 ) {
      title = "Pacas Shawl"
    }

    else if (id === 4838368051300 || id === 4838370410596 ) {
      title = "Toddler Pacas Socks"
    }
    else if (id === 7000945000548 || id === 7000945590372  || id === 7000423956580) {
      title = "Pacas Scarf"
    }

    else if (id === 7082909958244 || id === 7082909139044 ) {
      title = "Gift Box"
    }


    else if (id === 4823046324324 || id === 7255624745060 || id === 7851616370788 || id === 4823047143524  ) {
        title = "Men's Low Cut Pacas Socks"
      }


      return (title)
}



const handleFileUpload3 = (e) => {
  let currentTime = new Date().toLocaleString()
  console.log(currentTime)
  const files = e.target.files;
  console.log(files);
  if (files) {
    console.log(files[0]);
    Papa.parse(files[0], {
      header: true,
      complete: function(results) {
        console.log("Finished:", results.data);
        let formArray = results.data.map(elem => {

       
          //   return (
          //     {
          //       name: elem.Name,
          //       email: elem.Email,
          //       id: elem.Id,
          //       notes: elem.Notes
             
                 
          //     }
          // )


          return (
            {
              sku: elem.sku,
          
           
               
            }
        )

        
            
        })
        console.log(formArray)

        setSkuData(formArray)

        // const filteredData1 = formArray.filter((elem) => elem.notes !== "" && elem.notes)

        // console.log(filteredData1)

        // console.log(filteredData1[0].notes)

        // const filteredData2 = filteredData1.filter((elem) => elem.notes.match(/Return for refund started/g) && elem.notes.match(/Refund issued/g) )
        // generateExcelFile(filteredData2)
      }}
    )
  }

}

const genNumber = () => {
  let numArray = []
  for (let i = 1000; i < 10000; i++) {
    numArray.push({sku: i})
  }

  console.log(numArray)

  console.log(skuData)

  const formNumArray = numArray.map((elem) => {

    const found = skuData.find((f) => f.sku == elem.sku )

    return (
      {
        sku:elem.sku,
        found: found ? "true" : ""
      }
    )
  })


  console.log(formNumArray)

  generateExcelFile(formNumArray)


}

// const format = () => {
//   let formData = inboundData.map(elem => {
//     let obj = 
//   })
// }

let testArray = [{
  a: 1018, b: 1012, c: 1014, d: 1016
}]

let sortedArray = inboundData.map(elem => {
  let values = Object.values(elem).sort((a,b) => a - b)

  return({
    a: values[0],
    b: values[1],
    c: values[2],
    d: values[3]
  }
    // elem
  )
})



let permArray = []

const perm = () => {
  console.log(sortedArray)
  sortedArray.forEach(elem => {
    let p1 = "1-" + elem.a.toString() + ", " + "1-" + elem.b.toString()
    let p2 = "1-" + elem.a.toString() + ", " + "1-" + elem.c.toString()
    let p3 = "1-" + elem.a.toString() + ", " + "1-" + elem.d.toString()
    let p4 = "1-" + elem.b.toString() + ", " + "1-" + elem.c.toString()
    let p5 = "1-" + elem.b.toString() + ", " + "1-" + elem.d.toString()
    let p6 = "1-" + elem.c.toString() + ", " + "1-" + elem.d.toString()

    permArray.push(p1, p2, p3, p4, p5, p6)
  })

  console.log(permArray)
}

const permCount = () => {
  const countMap = {};
  
  // Count the occurrences of each string
  permArray.forEach(str => {
    if (countMap[str]) {
      countMap[str]++;
    } else {
      countMap[str] = 1;
    }
  });
  
  // Convert countMap to an array of objects in the required format
  const result = Object.entries(countMap).map(([string, amount]) => ({ [string]: amount }));

  let convertedArray = result.map((obj) => {
    const keys = Object.keys(obj);
    const sku = keys[0]; // Assuming there's only one key in each object
    const count = obj[sku];
    return { sku, count };
  });
  
  console.log(convertedArray)

  generateExcelFile(convertedArray)
}

function combineValues() {

  let outputData = inboundData.map(elem => {

 

  const countMap = {};
  Object.values(elem).forEach((value) => {
    console.log(value)
    console.log(countMap)
    if (countMap[value]) {
      countMap[value]++;
    } else {
      countMap[value] = 1;
    }
  });

  // Convert countMap into an array of objects
  const result = Object.entries(countMap).map(([sku, count]) => ({
    sku: parseInt(sku),
    count,
  }));

  console.log(result)

  let sort = result.sort((a,b) => a.sku - b.sku)

  console.log(sort)

  let final = sort.map(elem => {
    return (
      (elem.count.toString() + "-" + elem.sku.toString())
    )
  }).join(", ")

  return (
    {...elem, combined: final}
  )

})

console.log(outputData)

generateExcelFile(outputData)

  
}


 //{sku: 1010, quant: 1}


const getDhl = () => {
  axios.post("/api/dhl/getone", {
    trackNumber: "4047890284644"
  })
  .then((res) => {
    console.log(res)
  })
}

const getProd = () => {
  axios.post("/api/shopify/getoneproduct", {
    id: id
  }).then((res) => {
    console.log(res.data.products[0].variants)
    let data = res.data.products[0].variants

    let dataMap = data.map((elem) => {
      return (
        {id: elem.id, sku: elem.sku}
      )

    })
    console.log(dataMap)
    setIdData(dataMap)
  })
}

const handleIdChange = (e) => {
  setId(e.target.value)
}


const ex = () => {
  generateExcelFile(idData)
}

const getFull = () => {
  axios.post("/api/shopify/getfulfillment", {
    orderId: "4787066273892"
  })
  .then((res) => {
    console.log(res.data)
  })
}

//REDUCTION TESTING
const handleFileUpload2 = (e) => {
  let currentTime = new Date().toLocaleString()
  console.log(currentTime)
  const files = e.target.files;
  console.log(files);
  if (files) {
    console.log(files[0]);
    Papa.parse(files[0], {
      header: true,
      complete: function(results) {
        console.log("Finished:", results.data);
        let formArray = results.data.map(elem => {
          setPo(elem.PONumber)

          return (
            {
              sku: elem.SKU,
              quantity: elem.Quantity
            }
          )

    
        })
        console.log(formArray)
        setReductionData(formArray)
      }}
    )
  }

}



const splitRed = () => {
  let test = reductionData.map(elem => {
    let split = elem.sku.split("X")

    split = split.map(elm => {
      return (
        {
          sku: elm,
          quantity: elem.quantity
        }
      )
    })
    
    return (
      {
        split
      }
    )
  })

  let buffRed = []
  test.forEach(elem => {
    elem.split.forEach(elem => {
      buffRed.push(elem)
    })
  })
  console.log(test)

  console.log(buffRed)

  let buff2 = buffRed.map(elem => {
    let mult = parseInt(elem.sku)

    let quant = parseInt(elem.quantity) * mult
    let skuFin = elem.sku.substring(2,6)


    return(
      {
        sku: skuFin,
        quantity: quant
      }
    )
  })

  console.log(buff2)
  generateExcelFile2(buff2)

}


const generateExcelFile2 = async (input) => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet('Sheet 1');



  // const data = input.map(({ children, ...rest }) => rest);

  const data = input


  // Add column headers
  const headers = Object.keys(data[0]);
  worksheet.addRow(headers);

  // Add data rows
  data.forEach(item => {
    const values = Object.values(item);
    worksheet.addRow(values);
  });


  workbook.xlsx.writeBuffer().then((buffer) => {
    const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(blob, 'reductionData_' + po + '.xlsx');
  });
};

const [inputText, setInputText] = useState('');
const [qrCodeUrl, setQrCodeUrl] = useState('');

const resizeImage = (base64Str, targetWidth, targetHeight) => {
  return new Promise((resolve, reject) => {
      let img = new Image();

      // Log for debugging
      console.log("Starting image load");

      // Set up the image with the provided base64 string
      img.src = base64Str;

      img.onload = () => {
          console.log("Image loaded, resizing now"); // Log on image load

          let canvas = document.createElement('canvas');
          canvas.width = targetWidth;
          canvas.height = targetHeight;
          console.log("created");

          let ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0, targetWidth, targetHeight);

          console.log("draw");
          resolve(canvas.toDataURL());
      };

      img.onerror = reject;
  });
}

const qrCodeOptions = {
  scale: 200, // height in pixels
  margin: 1.5,  // margin from the edge (optional)
  // You can add more styling options here
};

const generateQRCode = async () => {
  try {
      // Generate a larger QR code
      let url = await QRCode.toDataURL(inputText, qrCodeOptions);

      // Resize the image to the original dimensions (e.g., 800x800)
      // This step depends on how you want to implement the resizing
      // It can be done using canvas in the browser or on the server-side
      resizeImage(url, 800, 800) .then(resizedImage => {
        setQrCodeUrl(resizedImage);
    }); // Replace with actual resize function

    
  } catch (error) {
      console.error(error);
  }
};



const downloadQRCode = () => {
  const link = document.createElement('a');
  link.href = qrCodeUrl;
  // Set the download name for the QR code image
  link.download = 'qrcode.png';
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};


const handleSku = () => {
  let arrayBuffer = inboundData.map((elem) => {
    let skuArray = []

    if (elem.d) {
      skuArray = [elem.a, elem.b, elem.c, elem.d]
    } else {
      skuArray = [elem.a, elem.b, elem.c]
    }

   let skuString =  skuArray.sort((a,b) => parseInt(a) - parseInt(b)).join("-")

    return (
      {sku: skuString}
    )
  })

  console.log(arrayBuffer)


  generateExcelFile(arrayBuffer)




}
//6806498017650
//6806498607474

const getOneOrder = () => {
  axios.post("/api/shopify/getoneorder", {
    orderId:  "6806498607474"
  }).then((res) => {
    console.log(res)
  })
}

//"4954407010404"
const handleFileUploadUPC = (e) => {
  let currentTime = new Date().toLocaleString()
  console.log(currentTime)
  const files = e.target.files;
  console.log(files);
  if (files) {
    console.log(files[0]);
    Papa.parse(files[0], {
      header: true,
      complete: function(results) {
        console.log("Finished:", results.data);
        let formArray = results.data.map(elem => {
          return (
            {
              sku: elem.sku,
              upc: elem.upc
            }
          )
     
        })
        console.log(formArray)
        setInboundData(formArray)
      }}
    )
  }

}

const addUPC = () => {

axios.post("/api/shopify/getonecrew", {
  productId: "7163184283748"
}).then((res) => {

console.log(res.data.products[0].variants)

let response = res.data.products[0].variants

let dataIds = inboundData.map((elem) => {
    let foundIds = response.find((elm) => elm.sku === elem.sku)
    if (foundIds) {
    return (
      {
        sku: elem.sku,
        upc: elem.upc,
        id: foundIds.id
      }
    )
    }

})

console.log(dataIds)

dataIds.forEach((ellm, i) => {
  setTimeout(() => {
      axios.post("/api/shopify/barcode" , {
    variantId: ellm.id.toString(),
    upc: parseInt(ellm.upc)
  }).then((res) => {
    console.log(res.data)
  })
    
  }, i * 500);
})





})


  // axios.post("/api/shopify/barcode" , {
  //   variantId: "39514311098468",
  //   upc: 810111213523
  // }).then((res) => {
  //   console.log(res.data)
  // })
}


const handleO3 = async () => {
  try {

    axios.post("/api/shopify/getonecrew", {
      productId: "7105370357860"
    }).then((res) => { 
      console.log(res.data.products)

      let variants = res.data.products[0].variants

      variants.forEach((elem, i ) => {
        setTimeout(() => {

       axios.post("/api/shopify/o3", {
          variantId: elem.id.toString(),
          sku: elem.sku

        }).then((res) => console.log(res))

          
        }, i * 300);
      })


      
      })

    // let response =  await axios.post("/api/shopify/o3", {
    //   variantId: "39418207109220",
    //   sku: "2332"

    // })

    // console.log(response)


  }
  catch {

  }
}


const getTest = () => {
  axios.post("/api/shopify/getoneorder", {
    orderId: "4988427501668"
  }).then((res) => {
    console.log(res.data)
  })
}

const getFiles = async () => {
  const response = await axios.post(("/api/shopql/getfiles"))

  console.log(response.data)

  const filteredNew = response.data
  .filter((elem) => elem.status === "ACTIVE" && elem.productType !== "Single Pack") 
  .flatMap((elem) => elem.variants.edges.map((edge) => edge.node)) 
  .filter((node) => node.inventoryQuantity > 0)

console.log(filteredNew);


const idArray = filteredNew.map((elem) => {
  return (
    elem.id.slice(29, elem.id.length)
  )
}).filter((ellm) => ellm !== 41206405759076 && ellm !== 44497315561572)


console.log(idArray)


const testArray = idArray.slice(0, 500);
let failArray = [];

for (const elem of idArray) {
  const url = `https://www.pacas.com/cdn/shop/files/${elem}.png`;
  
  try {
    const response = await axios.head(url);
    if (response.status !== 200) {
      failArray.push(elem);
    }
  } catch (error) {
    // If there is an error (e.g., 404), add the element to failArray
    failArray.push(elem);
  }
}

console.log("Failed elements:", failArray);




 






  // const response2 = await axios.post("/api/shopql/getVariants", {
  //   productIds: idArray
  // })


  // console.log(response2)
  // const response = await axios.post("/api/shopql/checkbutton", {
  //   url: "https://www.pacas.com/cdn/shop/files/40077339656292.png"
  // })

  // console.log(response.data)
}


const testSlack = async () => {

  const response = await axios.post("/api/slack/events",
    {

    }
  )

  console.log(response)
}



  


  return (
    <div className="container" style={{marginLeft: "20vw"}}>
      {/* <h1>Draggable List</h1>
      <button onClick={onTest}>TEST</button>
       <HexColorPicker style={{visibility: vis}} color={color} onChange={changeColor} />
       <div id="1" onClick={hide} style={{backgroundColor: color, color: {color},  width: "20vw", height: "10vh"}}></div>
       <div id="2" onClick={hide} style={{backgroundColor: color2, color: {color},  width: "20vw", height: "10vh"}}></div>
      <ul className="list">
        {items.map((item, index) => (
          <li
            key={item.id}
            className="list-item"
            draggable
            onDragStart={(event) => onDragStart(event, index)}
            onDragOver={onDragOver}
            onDrop={(event) => onDrop(event, index)}
          >
            {item.name}
          </li>
        ))}
      </ul>
      <button onClick={generateExcelFile}>Download Excel</button> */}
      {/* <br></br> */}
      <input
        type="file"
        accept=".csv,.xlsx,.xls"
        onChange={handleFileUpload4}
        />
        <button onClick={handleSku}>Handle SKU</button>


        <input
        type="file"
        accept=".csv,.xlsx,.xls"
        onChange={handleFileUpload3}
        />
        <button onClick={genNumber}>asdasda</button>
        {/* <button onClick={combineValues}>click</button>
        <button onClick={perm}>perm</button>
        <button onClick={permCount}>count</button>


        <button onClick={getDhl}>GET DHL</button> */}
        {/* <input onChange={handleIdChange}></input>
        <button onClick={getProd}>GET</button>
        <button onClick={ex}>EXPORT</button>
        <button onClick={getFull}>GF</button> */}

        <input
        type="file"
        accept=".csv,.xlsx,.xls"
        onChange={handleFileUploadUPC}
        />
     
        {/* <button onClick={splitRed}>SPLIT</button> */}

          <input 
                type="text" 
                value={inputText} 
                onChange={(e) => setInputText(e.target.value)} 
            />
            <button onClick={generateQRCode}>Gen</button>
            <button onClick={downloadQRCode}>Download</button>
            
            <button onClick={addUPC}>ADD</button>
            {qrCodeUrl && (
                <>
                    <img src={qrCodeUrl} alt="QR Code" />

                </>
            )}

            <button onClick={handleO3}>HAndle O3</button>

            <button onClick={getTest}>GETTEST</button>

            <button onClick={getFiles}>Get Files</button>


            <button onClick={getOneOrder}>GET ONE</button>


            <button onClick={testSlack}>TEST SLACK</button>

    </div>
  );
}

export default App;