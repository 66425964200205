//DEPS
import React, { useEffect, useState } from 'react';

//COMPONENTS
import PackModal from '../subViews/PackModal';
import Creation from '../subViews/Creation';
import masterProduct from '../assets/productPack.json'





  export default function ModalData(props) {

    const [details, setDetails] = useState({})

    //skuQuant determination
    const sumSkuQuant = (arr) => {
        let sum = 0;
      
        for (let i = 0; i < arr.length; i++) {
          sum += parseInt(arr[i].skuQuant);
        }

        console.log(sum)
        console.log(arr)
      
        return sum;
      };
      const totalSkuQuant = sumSkuQuant(props.selectedData);

    const createNumberArray = (length) => {
        const buffArray = [];
      
        for (let i = 1; i <= length; i++) {
          buffArray.push(i);
        }
      
        return buffArray;
      };

    const buffArray = createNumberArray(totalSkuQuant)
    const modalDataNums = [];
    const modalDataLength = props.selectedData.length;


    for (let i = 0; i < modalDataLength; i++) {
    const num = parseInt(props.selectedData[i].skuQuant);
    modalDataNums.push(num);
    }

    let startIndex = 0;
    for (let i = 0; i < modalDataLength; i++) {
    const endIndex = startIndex + modalDataNums[i];
    buffArray.fill(props.selectedData[i], startIndex, endIndex);
    startIndex = endIndex;
    console.log(props.selectedData[i])
    }

    // console.log(buffArray)
    // console.log(modalDataNums)
    // console.log(modalDataLength)
    // console.log(props.style)

    let weight1 = ""
    let weight2 = ""

    let mod = buffArray.length

    if (props.style === "Crew Socks" && props.cat === "Women's") {  
      weight1 = (mod * 2.5).toFixed(2)
      weight2 = (mod * 2.74).toFixed(2)
    }
    if (props.style === "Low-Cut Socks" && props.cat === "Women's") {  
      weight1 = (mod * 2.5).toFixed(2)
      weight2 = (mod * 2.74).toFixed(2)
    }
    if (props.style === "Crew Socks" && props.cat === "Men's") {  
      weight1 = (mod * 2.78).toFixed(2)
      weight2 = (mod * 2.88).toFixed(2)
    }
    if (props.style === "No-Show Socks" && props.cat === "Women's") {  
      weight1 = (mod * 1.6).toFixed(2)
      weight2 = (mod * 1.7).toFixed(2)
    }
    if (props.style === "Light-Weight Crew Socks" && props.cat === "Women's") {  
      weight1 = (mod * 2.3).toFixed(2)
      weight2 = (mod * 2.4).toFixed(2)
    }
    if (props.style === "Light-Weight Crew Socks" && props.cat === "Men's") {  
      weight1 = (mod* 2.4).toFixed(2)
      weight2 = (mod* 2.7).toFixed(2)
    }
    if (props.style === "Performance Socks" && props.cat === "Women's") {  
      weight1 = (mod * 1.3).toFixed(2)
      weight2 = (mod * 1.4).toFixed(2)
    }

    if (props.style === "Performance Socks" && props.cat === "Men's") {  
      weight1 = (mod * 1.4).toFixed(2)
      weight2 = (mod * 1.5).toFixed(2)
    }
    if (props.style === "Compression Socks" && props.cat === "Women's") {  
      weight1 = (mod * 3.1).toFixed(2)
      weight2 = (mod * 3.3).toFixed(2)
    }
    if (props.style === "Compression Socks" && props.cat === "Men's") {  
      weight1 = (mod * 3.1).toFixed(2)
      weight2 = (mod* 3.6).toFixed(2)
    }
    if (props.style === "Slouchy Socks" && props.cat === "Women's") {  
      weight1 = (mod* 2.78).toFixed(2)
      weight2 = (mod * 2.88).toFixed(2)
    }
//
    if (props.style === "Hiking Crew Socks" && props.cat === "Men's") {  
      weight1 = (mod * 2.32).toFixed(2)
      weight2 = (mod * 2.45).toFixed(2)
    }

    if (props.style === "Hiking Crew Socks" && props.cat === "Women's") {  
      weight1 = (mod * 2.15).toFixed(2)
      weight2 = (mod * 2.36).toFixed(2)
    }
    if (props.style === "Hiking Quarter Socks" && props.cat === "Women's") {  
      weight1 = (mod * 1.77).toFixed(2)
      weight2 = (mod * 1.93).toFixed(2)
    }

    if (props.style === "Hiking Quarter Socks" && props.cat === "Men's") {  
      weight1 = (mod * 2.1).toFixed(2)
      weight2 = (mod * 2.28).toFixed(2)
    }

    if (props.style === "Golf Socks" && props.cat === "Men's") {  
      weight1 = (mod * 2.1).toFixed(2)
      weight2 = (mod * 2.28).toFixed(2)
    }

    if (props.style === "Golf Socks" && props.cat === "Women's") {  
      weight1 = (mod * 2.1).toFixed(2)
      weight2 = (mod * 2.28).toFixed(2)
    }


    if (props.style === "Ruffle Socks" && props.cat === "Women's") {  
      weight1 = (mod * 1.1).toFixed(2)
      weight2 = (mod * 1.2).toFixed(2)
    }



    let price = 0
    let caPrice = ""

    if (props.style === "Crew Socks" && mod === 2) {price = 36 }
    if (props.style === "Crew Socks" && mod === 3) {price = 54}
    if (props.style === "Crew Socks" && mod === 4) {price = 66
    caPrice = 80}
    if (props.style === "Crew Socks" && mod === 5) {price = 90}
    if (props.style === "Crew Socks" && mod === 6) {price = 94
    caPrice = 120}

    if (props.style === "Performance Socks" && mod === 3) {price = 38}
    if (props.style === "Performance Socks" && mod === 4) {price = 44}
    if (props.style === "Performance Socks" && mod === 6) {price = 60}

    if (props.style === "Low-Cut Socks" && mod === 2) {price = 28}
    if (props.style === "Low-Cut Socks" && mod === 3) {price = 42}
    if (props.style === "Low-Cut Socks" && mod === 4) {price = 52
    caPrice = 64}
    if (props.style === "Low-Cut Socks" && mod === 6) {price = 74
    caPrice = 96}

    if (props.style === "No-Show Socks" && mod === 3) {price = 38
    caPrice = 39}
    if (props.style === "No-Show Socks" && mod === 4) {price = 46
    caPrice = 52}
    if (props.style === "No-Show Socks" && mod === 6) {price = 66
    caPrice = 78}
    if (props.style === "No-Show Socks" && mod === 8) {price = 80}

    if (props.style === "Light-Weight Crew Socks" && mod === 2) {price = 26}
    if (props.style === "Light-Weight Crew Socks" && mod === 3) {price = 54}
    if (props.style === "Light-Weight Crew Socks" && mod === 4) {price = 66
    caPrice = 76}
    if (props.style === "Light-Weight Crew Socks" && mod === 6) {price = 94
    caPrice = 114}

    if (props.style === "Compression Socks" && mod === 2) {price = 39}
    if (props.style === "Compression Socks" && mod === 3) {price = 60}
    if (props.style === "Compression Socks" && mod === 4) {price = 78
    caPrice = 84}
    if (props.style === "Compression Socks" && mod === 6) {price = 110
    caPrice = 126}

    if (props.style === "Slouchy Socks" && mod === 2) {price = 52}

    if (props.style === "Hiking Crew Socks" && mod === 2) {price = 39}
    if (props.style === "Hiking Crew Socks" && mod === 3) {price = 57}
    if (props.style === "Hiking Crew Socks" && mod === 4) {price = 70}

    if (props.style === "Hiking Quarter Socks" && mod === 2) {price = 36}
    if (props.style === "Hiking Quarter Socks" && mod === 3) {price = 52}
    if (props.style === "Hiking Quarter Socks" && mod === 4) {price = 66}

    if (props.style === "Golf Socks" && mod === 3) {price = 38}
    if (props.style === "Golf Socks" && mod === 4) {price = 50}
    if (props.style === "Golf Socks" && mod === 6) {price = 75}

    if (props.style === "Ruffle Socks" && mod === 3) {price = 32}
    if (props.style === "Ruffle Socks" && mod === 4) {price = 40}
    

console.log(props.selectedData)

let selectedDataForm = props.selectedData.sort((a,b) => parseInt(a.sku) - parseInt(b.sku))
console.log(selectedDataForm)

let skuArray = selectedDataForm.flatMap((elem) => {
  let bufferArray = []

  for (let i = 0; i < elem.skuQuant; i++ ) {
    bufferArray.push(elem.sku)
  }

  return bufferArray;

})

console.log(skuArray)

//SORTING CB
const skuSort = (a,b) => {
    return ( a.substring(2,6)-b.substring(2,6))
}

let skuEncodeS1 = ""
let skuEncodeS2 = ""
console.log(props.view)

//RUNTIME ALL ERROR PATCH TEST

let sizePatch = ""

if (props.size === "All") {
  sizePatch = "Size 1"
} else {
  sizePatch = props.size
}

//


//MOD CONDIT
    if (sizePatch === "Size 1" && props.skuChecked === true && props.view === 1 ) {
     

   let  skuEncode1T = skuArray.map(elem => {
        return (
            (elem)
        )
    })

   let skuEncode2T = skuArray.map(elem => {
        return (
            ((parseInt(elem) + 1).toString())
        )
    })

    let skuEncode1 = [...skuEncode1T]
    let skuEncode2 = [...skuEncode2T]

    let skuSpliceStart = [...skuEncode1].splice(0,3).join("")
    let skuSpliceEnd = [...skuEncode1].splice(3).join("")
    console.log(skuSpliceEnd)
    console.log(skuEncode1)

    let skuSpliceStart2 = [...skuEncode2].splice(0,3).join("")
    let skuSpliceEnd2 = [...skuEncode2].splice(3).join("")

    if (skuArray.length > 5) {
    skuEncodeS2 = [skuSpliceStart2,skuSpliceEnd2].join("-")
    skuEncodeS1 = [skuSpliceStart,skuSpliceEnd].join("-")
    } else {

      console.log(skuEncode1T)
      skuEncodeS2 = skuEncode2T.join("-")
      skuEncodeS1 = skuEncode1T.join("-")
    }

  }
  else if (props.size === "Size 2" && props.skuChecked === true && props.view === 1) {

//BUG
      let  skuEncode1T = skuArray.map(elem => {
      return (
          (elem)
      )
      })

      let skuEncode2T = skuArray.map(elem => {
      return (
          ((parseInt(elem) - 1).toString())
      )
      })

      let skuEncode1 = [...skuEncode1T]
      let skuEncode2 = [...skuEncode2T]

      let skuSpliceStart = [...skuEncode1].splice(0,3).join("")
      let skuSpliceEnd = [...skuEncode1].splice(3).join("")
      console.log(skuSpliceEnd)
      console.log(skuEncode1)

      let skuSpliceStart2 = [...skuEncode2].splice(0,3).join("")
      let skuSpliceEnd2 = [...skuEncode2].splice(3).join("")

      if (skuArray.length > 5) {
      skuEncodeS1 = [skuSpliceStart2,skuSpliceEnd2].join("-")
      skuEncodeS2 = [skuSpliceStart,skuSpliceEnd].join("-")
      } else {
      console.log("HERE")
      console.log(skuEncode1T)
      skuEncodeS1 = skuEncode2T.join("-")
      skuEncodeS2 = skuEncode1T.join("-")
      }
    
  }

  else if ( props.skuChecked === false) {
    console.log("HERE")

    if (props.view === 1) {


      if (props.size === "Size 1") {

 

      

    skuEncodeS1 = props.selectedData.map(elem => {

      
       return (
           (elem.skuQuant + "-" + elem.sku)
       )
   }).join("X")
  

    skuEncodeS2 = props.selectedData.map(elem => {
       return (
           (elem.skuQuant + "-" + (parseInt(elem.sku) + 1).toString())
       )
   }).join("X")
  }
    else if (props.size === "Size 2") {
      skuEncodeS1 = props.selectedData.map(elem => {

      
        return (
          (elem.skuQuant + "-" + (parseInt(elem.sku) - 1).toString())
        )
    }).join("X")
   
 
     skuEncodeS2 = props.selectedData.map(elem => {
        return (


            (elem.skuQuant + "-" + elem.sku)
        )
    }).join("X")
    }
   ////
  } 
}

  
  
  else {





    console.log("test")

    console.log(skuArray)

    console.log(props.selectedData)

    //Single

    if (selectedDataForm.length > 0) {


    let quantDeterm = selectedDataForm.map((elem) => {
      return (
        elem.parentSku
      )
    })

    let quantSet = new Set(quantDeterm)


    console.log(quantSet.size)

    }
    //END SINGLE

    //TODO: add in the parent sku the number of times of parentQaunt 

    let parentSkuListBuffer = props.selectedData.map(elem => {
      return (
        {psku: elem.parentSku, quant: elem.parentQuant}
      )

    } );

    const uniquePskuObjects = parentSkuListBuffer.reduce((acc, current) => {
      if (!acc[current.psku]) { 
        acc[current.psku] = current
      }
      return acc;
    }, {});

    let parentSkuList2 = Object.values(uniquePskuObjects)
   

    let parentSkuList = []

    parentSkuList2.forEach((elem) => {
      for (let i = 0; i < elem.quant; i++) {
        parentSkuList.push(elem.psku)
      }
    })





    // parentSkuList = [...new Set(parentSkuList)];


    const decodeOld = (array) => {
      let arrayMap = array.map((elem) => {
        if (elem[1] === "-") {
        let skuSplit = elem.split("X")
          let arrayMap2 = skuSplit.map((elem) => {

            return (
              {
                quantity: parseInt(elem),
                sku: elem.substring((elem.indexOf("-") + 1)),
                type:"x"
              }
            )
          })


        return (
          arrayMap2
        )
        } else {
         let skuSplit =  elem.split("-")
          let arrayMap2 = skuSplit.map((elem) => {
            return (
              {
                quantity: 1,
                sku: elem,
                type:"n"
              }
            )
          })

          return (
            arrayMap2
          )
        }


      })

      console.log(arrayMap)

      let quantSize

      let quantDeterm = []



      if (selectedDataForm.length > 0) {

        console.log("@x")
        console.log(selectedDataForm)


         quantDeterm = selectedDataForm.map((elem) => {
          return (
            elem.parentSku
          )
        })
    
        let quantSet = new Set(quantDeterm)
    
        quantSize = quantSet.size

        console.log(arrayMap.length)
        
    
        }

        let skuFormSize1 = ""
      let skuFormSize2 = ""

      if (arrayMap.length !== 1) {



      let formSet = arrayMap.map((elem) => {

          let skuBuffer = []
          elem.forEach((elm) => {
          for (let i = 0; i < elm.quantity; i++ ) {
            skuBuffer.push(elm.sku)
          }
        })
        
        return (
          parseInt(skuBuffer.join(""))
        )
      }).sort((a,b) => a - b)

      let formSet2 = arrayMap.map((elem) => {

          let skuBuffer = []
          elem.forEach((elm) => {
          for (let i = 0; i < elm.quantity; i++ ) {
            if (props.size === "Size 1") {
            skuBuffer.push(parseInt(elm.sku) + 1)
            }
            else if ( props.size === "Size 2") {
              skuBuffer.push(parseInt(elm.sku) - 1)
            }
          }
        })

        
        return (
          skuBuffer.join("")
        )
      }).sort((a,b) => a - b)

 

      if (props.size === "Size 1" && formSet.length <= 5) {
         skuFormSize1 = formSet.join("-")
         skuFormSize2 = formSet2.join("-")

      }
      
      
      else if (props.size !== "Size 1" && formSet.length <= 5){
        skuFormSize1 = formSet2.join("-")
         skuFormSize2 = formSet.join("-")
      }

      else if (props.size === "Size 1" && formSet.length > 5){
        let fbufferStart = formSet.slice(0,3).join("")
        let fbufferEnd = formSet.slice(3).join("")
        let fbuffer = [fbufferStart, fbufferEnd]

        let fbufferStart2 = formSet2.slice(0,3).join("")
        let fbufferEnd2 = formSet2.slice(3).join("")
        let fbuffer2 = [fbufferStart2, fbufferEnd2]
        
        skuFormSize1 = fbuffer2.join("-")
         skuFormSize2 = fbuffer.join("-")
      }

      else if (props.size !== "Size 1" && formSet.length > 5){
        let fbufferStart = formSet.slice(0,3).join("")
        let fbufferEnd = formSet.slice(3).join("")
        let fbuffer = [fbufferStart, fbufferEnd]

        let fbufferStart2 = formSet2.slice(0,3).join("")
        let fbufferEnd2 = formSet2.slice(3).join("")
        let fbuffer2 = [fbufferStart2, fbufferEnd2]
        
        skuFormSize1 = fbuffer.join("-")
         skuFormSize2 = fbuffer2.join("-")
      }

    }

      else {

        console.log("ELSE")


        let formSet = arrayMap.map((elem) => {

          let skuBuffer = []
          elem.forEach((elm) => {
          for (let i = 0; i < elm.quantity; i++ ) {

            if (elm.type === "x") {
              skuBuffer.push(elm.quantity + "-" + elm.sku)

            } else {
              skuBuffer.push(elm.sku)
            }

            
          }
        })
        
      
        if (skuBuffer[0][1] === "-") {
          return (
            skuBuffer.join("X")
          )


      } else {

        return (
          skuBuffer.join("-")
        )

      }
      }).sort((a,b) => a - b)


      console.log(formSet)

      let formSet2 = arrayMap.map((elem) => {

          let skuBuffer = []
          elem.forEach((elm) => {
          for (let i = 0; i < elm.quantity; i++ ) {
            if (props.size === "Size 1" && elm.type === "x") {
            skuBuffer.push(elm.quantity + "-" + (parseInt(elm.sku) + 1))
            }
            else if ( props.size === "Size 2" && elm.type === "x") {
              skuBuffer.push( elm.quantity + "-" + (parseInt(elm.sku) - 1))
            }

            else if ( props.size === "Size 1" && elm.type === "n") {
              skuBuffer.push((parseInt(elm.sku) + 1))
            }

            else if ( props.size === "Size 2" && elm.type === "n") {
              skuBuffer.push((parseInt(elm.sku) - 1))
            }
          }
        })


      if (skuBuffer[0][1] === "-") {
          return (
            skuBuffer.join("X")
          )


      } else {

        return (
          skuBuffer.join("-")
        )

      }

        
  
      }).sort((a,b) => a - b)


      console.log(formSet2)



      if (props.size === "Size 1" && formSet.length <= 5) {
         skuFormSize1 = formSet.join("-")
         skuFormSize2 = formSet2.join("-")

      }
      
      
      else if (props.size !== "Size 1" && formSet.length <= 5){
        skuFormSize1 = formSet2.join("-")
         skuFormSize2 = formSet.join("-")
      }












      }





      return {size1: skuFormSize1, size2: skuFormSize2}

     

    }

    console.log(decodeOld(["1111-1111-1111", "1111-1111-1111"]))



    skuEncodeS1 = decodeOld(parentSkuList).size1
    skuEncodeS2 = decodeOld(parentSkuList).size2



    const decodeNew = (array) => {



    }


  }


     



    //NEW Size ONE



    //Start
//     let bufferArray = []

//     props.selectedData.forEach(elem => {
//       if (!bufferArray.includes(elem.parentSku) && elem.skuQuant == 1) {
//         bufferArray.push(elem.parentSku)
//       }
//       if (!bufferArray.includes(elem.parentSku) && elem.skuQuant == 2) {
//         bufferArray.push(elem.parentSku)
//         bufferArray.push(elem.parentSku)
//       } else {
//         console.log("SKU DETERM FAIL")
//       }
//     })

// if (bufferArray.length > 0 && bufferArray[0] === bufferArray[1]) {
//   skuEncodeS1 = bufferArray[0].toString() + "Y2"

// } else {

//   //change
//   let qual = ""
//   if (props.cat === "Men's") {
//     qual = "M"
//   } else {
//     qual = "W"
//   }

  

//   let buffArray = bufferArray.join("X").split("X")
//   let b2 = buffArray.map(elem => {
//     return (
//       //change
//       "" + (parseInt(elem.substring(2,7))).toString()
//     )
//   }).join("")

//   skuEncodeS1 = "6P" + qual + "-" + b2
// }

// if (bufferArray.length > 0 && bufferArray[0] !== bufferArray[1]) {
//   let qual = ""
//   if (props.cat === "Men's") {
//     qual = "M"
//   } else {
//     qual = "W"
//   }
//   let buffArray = bufferArray.join("X").split("X")
//   let b2 = buffArray.map(elem => {
//     return (
//       //change
//       "" + (parseInt(elem.substring(2,7)) + 1).toString()
//     )
//   }).join("")

//   skuEncodeS2 = "6P" + qual + "-" + b2
// } else if (bufferArray.length > 0 && bufferArray[0] === bufferArray[1]) {

//   let buffArray = bufferArray[0].split("X")
//   let b2 = buffArray.map(elem => {
//     return (
//       "1-" + (parseInt(elem.substring(2,7)) + 1).toString()
//     )
//   }).join("X")

//   skuEncodeS2 = b2 + "Y2"

// }
    
 



//   }
//  }


    let color = props.selectedData.map(elem => {
      return(elem.color)
    }).join(" & ")

    if (props.selectedData.length === 1) {
      color = color  + " " + "SolidAll"
    }



    let size1 = "" 
    let size2 = "" 
    if (props.selectedData.length > 0) {

      if (props.size === "Size 1") {

    if (props.selectedData[0].size === "Small / Medium (US: 5 - 8)") {
      size1 = props.selectedData[0].size
      size2 = "Medium / Large (US: 8.5 - 11.5+)"
    } 
    else if (props.selectedData[0].size === "Medium / Large (US: 8.5 - 11.5+)") {
      size1 = props.selectedData[0].size
      size2 = "Small / Medium (US: 5 - 8)"
    }
    else if (props.selectedData[0].size === "Medium / Large (US: 7 - 10)") {
      size1 = props.selectedData[0].size
      size2 = "Large / Extra-Large (US: 10.5 - 14+)"
    } 
    else if (props.selectedData[0].size === "Large / Extra-Large (US: 10.5 - 14+)") {
      size1 = props.selectedData[0].size
      size2 = "Medium / Large (US: 7 - 10)"
    } 
    }
    else if (props.size === "Size 2") {
      if (props.selectedData[0].size === "Small / Medium (US: 5 - 8)") {
        size2 = props.selectedData[0].size
        size1 = "Medium / Large (US: 8.5 - 11.5+)"
      } 
      else if (props.selectedData[0].size === "Medium / Large (US: 8.5 - 11.5+)") {
        size2 = props.selectedData[0].size
        size1 = "Small / Medium (US: 5 - 8)"
      }
      else if (props.selectedData[0].size === "Medium / Large (US: 7 - 10)") {
        size2 = props.selectedData[0].size
        size1 = "Large / Extra-Large (US: 10.5 - 14+)"
      } 
      else if (props.selectedData[0].size === "Large / Extra-Large (US: 10.5 - 14+)") {
        size2 = props.selectedData[0].size
        size1 = "Medium / Large (US: 7 - 10)"
      } 
    }
  }

  console.log(buffArray.length)

  let testProd =   masterProduct.filter(elem => {
    if (elem.quantity == buffArray.length && elem.style === props.style && elem.category === props.cat) {
      return elem.product_id
    }
  })
  let tProd = ""
if (testProd.length > 0 ) {
  console.log(testProd[0].product_id)
  tProd = testProd[0].product_id
}
  

    let detailsObj = {
      sku1: skuEncodeS1,
      sku2: skuEncodeS2,
      weight1: weight1.toString(),
      weight2: weight2.toString(),
      price: price,
      color: color,
      style: props.style,
      size1: size1,
      size2: size2,
      targetId: tProd,
      caPrice: caPrice,
      cat: props.cat

    }

    console.log(detailsObj)

    // setDetails(detailsObj)

    return (
        // <PackModal selectedData={buffArray} details={detailsObj} cat={props.cat} style={props.style} size={props.size} />
        <Creation selectedData={buffArray} details={detailsObj} cat={props.cat} style={props.style} size={props.size} resetDisplay={props.resetDisplay} display={props.display}  />
    )

  }