import React, { useEffect, useState, forwardRef } from "react";
// import axios from "axios"

//MUI
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Skeleton from "@mui/material/Skeleton";
import TextField from "@mui/material/TextField";

const PackSingleDisplay = (props, ref) => {
  const [skuQuant, setSkuQuant] = useState("1");

  const handleClick = (e) => {
    console.log("Click");
    console.log(e.target.style.backgroundColor);
    if (
      e.target.style.backgroundColor === "rgb(237, 237, 242)" ||
      e.target.style.backgroundColor === "rgb(237, 237, 243)"
    ) {
      //#EDEDF3
      e.target.style.backgroundColor = "aqua";
      console.log(props.children);
      // let selectObj = props.children.map(elem => {
      //   return (
      //     {
      //       sku: elem.sku,
      //       color: elem.color,
      //       image: elem.image,
      //       skuQuant: skuQuant,
      //       size: elem.size
      //     }
      //   )
      // })
      let selectObj = [];
      let skuMap = {};
      props.children.forEach((elem) => {
        if (!skuMap[elem.sku]) {
          skuMap[elem.sku] = 1;
          selectObj.push({
            sku: elem.sku,
            color: elem.color,
            image: elem.image,
            parentQuant: skuQuant,
            skuQuant: "1",
            size: elem.size,
            parentSku: props.sku,
          });
        } else {
          skuMap[elem.sku]++;
          selectObj.forEach((obj) => {
            if (obj.sku === elem.sku) {
              obj.skuQuant = (parseInt(obj.skuQuant) + 1).toString();
              console.log(obj.skuQuant);
            }
          });
          //
        }
      });
      selectObj.forEach((obj) => {
        obj.skuQuant = (parseInt(obj.skuQuant) * parseInt(skuQuant)).toString();
      });
      console.log(selectObj);
      props.selectHandle(selectObj);
    } else {
      e.target.style.backgroundColor = props.css;

      props.deselectHandle(props.sku);
    }
  };

  const handleQuant = (e) => {
    let quant = e.target.value;
    setSkuQuant(quant);
  };
  return (
    <Card
      raised={true}
      sx={{
        maxWidth: "25vw",
        minHeight: "58vh",
        maxHeight: "58vh",
        bgcolor: "#ededf2",
        backgroundColor: "var(--content-color)",
        borderRadius: "15px",
        boxShadow: "-2px 3px 3px rgba(0, 0, 0, 0.25)",
      }}
    >
      <Typography sx={{color:"white"}} gutterBottom variant="h6" component="div">
        {props.sku}
      </Typography>
      {props.image ? (
        <CardMedia
          sx={{ height: "20vw" }}
          image={props.image}
          title={props.sku}
          onClick={handleClick}
          style={{
            backgroundColor: props.css,
          }}
        />
      ) : (
        <Skeleton variant="rectangular" height={300} />
      )}

      <CardContent>
        <Typography sx={{color:"white"}} variant="body2" color="text.secondary">
          {props.color.length > 25
            ? `${props.color.substring(0, 25)}...`
            : props.color}
        </Typography>
        <Typography sx={{color:"white"}} variant="body2" color="text.secondary">
          {props.size.length > 20
            ? `${props.size.substring(0, 20)}...`
            : props.size}
        </Typography>
        <Typography sx={{color:"white"}} variant="body2" color="text.secondary">
          {props.style}
        </Typography>
        <Typography sx={{color:"white"}} variant="body2" color="text.secondary">
          ABV: {props.abv}
        </Typography>
        <Typography sx={{color:"white"}} variant="body2" color="text.secondary">
          APV: {props.apv}
        </Typography>
        <Typography sx={{color:"white"}} variant="body2" color="text.secondary">
          Stock: {props.stock}
        </Typography>
        {/* <TextField
                id="outlined-number"
                label="Quantity"
                type="number"
                onChange={handleQuant}
                InputLabelProps={{
                  shrink: true,
                }}
              /> */}
        {/* <input onChange={handleQuant} type="number" placeholder='Quantity' min="1" /> */}
      </CardContent>
      {/* <CardActions>
              <Button size="small">Share</Button>
              <Button size="small">Learn More</Button>
            </CardActions> */}
    </Card>
  );
};

export default PackSingleDisplay;
