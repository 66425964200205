
import React, { useState, useEffect }  from "react"
import Papa from "papaparse"

import * as ExcelJS from "exceljs";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";










export default function Translation() {
    const [input, setInput] = useState([])


       const generateExcelFile = async (d1) => {
            const workbook = new ExcelJS.Workbook();
            const worksheet = workbook.addWorksheet('Sheet 1');
        
            const data = d1
        
          
            // Add column headers
            const headers = Object.keys(data[0]);
            worksheet.addRow(headers);
        
            // Add data rows
            data.forEach(item => {
              const values = Object.values(item);
              worksheet.addRow(values);
            });
    
    
   
        
        
            workbook.xlsx.writeBuffer().then((buffer) => {
              const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
              saveAs(blob, 'EOMI.xlsx');
            });
          };



       const handleFileUpload = (e) => {
            let currentTime = new Date().toLocaleString()
            console.log(currentTime)
            const files = e.target.files;
            console.log(files);
            if (files) {
              console.log(files[0]);
              Papa.parse(files[0], {
                header: true,
                complete: function(results) {
                  console.log("Finished:", results.data);
                  let formArray = results.data.map(elem => {
    
          
               
          
                    return (
                      {
                        sku: elem.sku,
                    
                      
                    
                      }
                    )
                    
              
                  })
                  console.log(formArray)

                  setInput(formArray)

                  handleTran(formArray)
    
        
               
                }}
              )
            }
          
          }

const handleTran = (data) => {




const formData = data.map((elem) => {


    const splitArray = elem.sku.split("-")

    const countMap = splitArray.reduce((acc, ellm) => {
        acc[ellm] = (acc[ellm] || 0) + 1;
        return acc;
    }, {});
    
    const childrenArray = Object.entries(countMap).map(([sku, quant]) => ({
        sku,
        quant
    }));


    const cArray2 = childrenArray.map((ellmm) => {

        return (
            {
                psku: elem.sku, ...ellmm
            }
        )
    })

   

    const arr =  [{ psku: elem.sku, sku:elem.sku, quant: 1}]

    arr.push(cArray2)


    return (
        arr.flat()
    )


})

console.log(formData)


   const formChildren = formData.map((elm, i) => {

        const arr2 = elm.map((ellm, i) => {
            let CTC = i === 0 ? "N" : "E";

            return {
                "AccountingId": "NPE",
                "RetailerCode": "WEB",
                "UPC": ellm.psku,
                "SKU": ellm.sku,
                "ConverToCase": CTC,
                "CasePack": parseInt(ellm.quant)
            };



        })

        return (arr2)
        
      
    })



console.log(formChildren.flat())

generateExcelFile(formChildren.flat())


}









    return (
        <div style={{ marginLeft: "6vw" }}>

            <input type='file' accept=".csv" onChange={handleFileUpload}></input>


  
        </div>

    )
}